/*! For license information please see app.js.LICENSE.txt */
!(function (e) {
    var t = {};
    function n(r) {
        if (t[r]) return t[r].exports;
        var o = (t[r] = { i: r, l: !1, exports: {} });
        return e[r].call(o.exports, o, o.exports, n), (o.l = !0), o.exports;
    }
    (n.m = e),
        (n.c = t),
        (n.d = function (e, t, r) {
            n.o(e, t) ||
                Object.defineProperty(e, t, { enumerable: !0, get: r });
        }),
        (n.r = function (e) {
            'undefined' != typeof Symbol &&
                Symbol.toStringTag &&
                Object.defineProperty(e, Symbol.toStringTag, {
                    value: 'Module',
                }),
                Object.defineProperty(e, '__esModule', { value: !0 });
        }),
        (n.t = function (e, t) {
            if ((1 & t && (e = n(e)), 8 & t)) return e;
            if (4 & t && 'object' == typeof e && e && e.__esModule) return e;
            var r = Object.create(null);
            if (
                (n.r(r),
                Object.defineProperty(r, 'default', {
                    enumerable: !0,
                    value: e,
                }),
                2 & t && 'string' != typeof e)
            )
                for (var o in e)
                    n.d(
                        r,
                        o,
                        function (t) {
                            return e[t];
                        }.bind(null, o)
                    );
            return r;
        }),
        (n.n = function (e) {
            var t =
                e && e.__esModule
                    ? function () {
                          return e.default;
                      }
                    : function () {
                          return e;
                      };
            return n.d(t, 'a', t), t;
        }),
        (n.o = function (e, t) {
            return Object.prototype.hasOwnProperty.call(e, t);
        }),
        (n.p = '/'),
        n((n.s = 2));
})([
    function (e, t, n) {
        var r, o;
        !(function (i) {
            if (
                (void 0 ===
                    (o =
                        'function' == typeof (r = i)
                            ? r.call(t, n, t, e)
                            : r) || (e.exports = o),
                !0,
                (e.exports = i()),
                !!0)
            ) {
                var a = window.Cookies,
                    s = (window.Cookies = i());
                s.noConflict = function () {
                    return (window.Cookies = a), s;
                };
            }
        })(function () {
            function e() {
                for (var e = 0, t = {}; e < arguments.length; e++) {
                    var n = arguments[e];
                    for (var r in n) t[r] = n[r];
                }
                return t;
            }
            function t(e) {
                return e.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
            }
            return (function n(r) {
                function o() {}
                function i(t, n, i) {
                    if ('undefined' != typeof document) {
                        'number' ==
                            typeof (i = e({ path: '/' }, o.defaults, i))
                                .expires &&
                            (i.expires = new Date(
                                1 * new Date() + 864e5 * i.expires
                            )),
                            (i.expires = i.expires
                                ? i.expires.toUTCString()
                                : '');
                        try {
                            var a = JSON.stringify(n);
                            /^[\{\[]/.test(a) && (n = a);
                        } catch (e) {}
                        (n = r.write
                            ? r.write(n, t)
                            : encodeURIComponent(String(n)).replace(
                                  /%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g,
                                  decodeURIComponent
                              )),
                            (t = encodeURIComponent(String(t))
                                .replace(
                                    /%(23|24|26|2B|5E|60|7C)/g,
                                    decodeURIComponent
                                )
                                .replace(/[\(\)]/g, escape));
                        var s = '';
                        for (var u in i)
                            i[u] &&
                                ((s += '; ' + u),
                                !0 !== i[u] && (s += '=' + i[u].split(';')[0]));
                        return (document.cookie = t + '=' + n + s);
                    }
                }
                function a(e, n) {
                    if ('undefined' != typeof document) {
                        for (
                            var o = {},
                                i = document.cookie
                                    ? document.cookie.split('; ')
                                    : [],
                                a = 0;
                            a < i.length;
                            a++
                        ) {
                            var s = i[a].split('='),
                                u = s.slice(1).join('=');
                            n || '"' !== u.charAt(0) || (u = u.slice(1, -1));
                            try {
                                var c = t(s[0]);
                                if (((u = (r.read || r)(u, c) || t(u)), n))
                                    try {
                                        u = JSON.parse(u);
                                    } catch (e) {}
                                if (((o[c] = u), e === c)) break;
                            } catch (e) {}
                        }
                        return e ? o[e] : o;
                    }
                }
                return (
                    (o.set = i),
                    (o.get = function (e) {
                        return a(e, !1);
                    }),
                    (o.getJSON = function (e) {
                        return a(e, !0);
                    }),
                    (o.remove = function (t, n) {
                        i(t, '', e(n, { expires: -1 }));
                    }),
                    (o.defaults = {}),
                    (o.withConverter = n),
                    o
                );
            })(function () {});
        });
    },
    function (e, t, n) {
        var r;
        !(function (t, n) {
            'use strict';
            'object' == typeof e.exports
                ? (e.exports = t.document
                      ? n(t, !0)
                      : function (e) {
                            if (!e.document)
                                throw new Error(
                                    'jQuery requires a window with a document'
                                );
                            return n(e);
                        })
                : n(t);
        })('undefined' != typeof window ? window : this, function (n, o) {
            'use strict';
            var i = [],
                a = Object.getPrototypeOf,
                s = i.slice,
                u = i.flat
                    ? function (e) {
                          return i.flat.call(e);
                      }
                    : function (e) {
                          return i.concat.apply([], e);
                      },
                c = i.push,
                l = i.indexOf,
                f = {},
                p = f.toString,
                d = f.hasOwnProperty,
                h = d.toString,
                g = h.call(Object),
                v = {},
                y = function (e) {
                    return (
                        'function' == typeof e &&
                        'number' != typeof e.nodeType &&
                        'function' != typeof e.item
                    );
                },
                m = function (e) {
                    return null != e && e === e.window;
                },
                x = n.document,
                b = { type: !0, src: !0, nonce: !0, noModule: !0 };
            function w(e, t, n) {
                var r,
                    o,
                    i = (n = n || x).createElement('script');
                if (((i.text = e), t))
                    for (r in b)
                        (o = t[r] || (t.getAttribute && t.getAttribute(r))) &&
                            i.setAttribute(r, o);
                n.head.appendChild(i).parentNode.removeChild(i);
            }
            function C(e) {
                return null == e
                    ? e + ''
                    : 'object' == typeof e || 'function' == typeof e
                    ? f[p.call(e)] || 'object'
                    : typeof e;
            }
            var T = function (e, t) {
                return new T.fn.init(e, t);
            };
            function k(e) {
                var t = !!e && 'length' in e && e.length,
                    n = C(e);
                return (
                    !y(e) &&
                    !m(e) &&
                    ('array' === n ||
                        0 === t ||
                        ('number' == typeof t && t > 0 && t - 1 in e))
                );
            }
            (T.fn = T.prototype =
                {
                    jquery: '3.6.1',
                    constructor: T,
                    length: 0,
                    toArray: function () {
                        return s.call(this);
                    },
                    get: function (e) {
                        return null == e
                            ? s.call(this)
                            : e < 0
                            ? this[e + this.length]
                            : this[e];
                    },
                    pushStack: function (e) {
                        var t = T.merge(this.constructor(), e);
                        return (t.prevObject = this), t;
                    },
                    each: function (e) {
                        return T.each(this, e);
                    },
                    map: function (e) {
                        return this.pushStack(
                            T.map(this, function (t, n) {
                                return e.call(t, n, t);
                            })
                        );
                    },
                    slice: function () {
                        return this.pushStack(s.apply(this, arguments));
                    },
                    first: function () {
                        return this.eq(0);
                    },
                    last: function () {
                        return this.eq(-1);
                    },
                    even: function () {
                        return this.pushStack(
                            T.grep(this, function (e, t) {
                                return (t + 1) % 2;
                            })
                        );
                    },
                    odd: function () {
                        return this.pushStack(
                            T.grep(this, function (e, t) {
                                return t % 2;
                            })
                        );
                    },
                    eq: function (e) {
                        var t = this.length,
                            n = +e + (e < 0 ? t : 0);
                        return this.pushStack(n >= 0 && n < t ? [this[n]] : []);
                    },
                    end: function () {
                        return this.prevObject || this.constructor();
                    },
                    push: c,
                    sort: i.sort,
                    splice: i.splice,
                }),
                (T.extend = T.fn.extend =
                    function () {
                        var e,
                            t,
                            n,
                            r,
                            o,
                            i,
                            a = arguments[0] || {},
                            s = 1,
                            u = arguments.length,
                            c = !1;
                        for (
                            'boolean' == typeof a &&
                                ((c = a), (a = arguments[s] || {}), s++),
                                'object' == typeof a || y(a) || (a = {}),
                                s === u && ((a = this), s--);
                            s < u;
                            s++
                        )
                            if (null != (e = arguments[s]))
                                for (t in e)
                                    (r = e[t]),
                                        '__proto__' !== t &&
                                            a !== r &&
                                            (c &&
                                            r &&
                                            (T.isPlainObject(r) ||
                                                (o = Array.isArray(r)))
                                                ? ((n = a[t]),
                                                  (i =
                                                      o && !Array.isArray(n)
                                                          ? []
                                                          : o ||
                                                            T.isPlainObject(n)
                                                          ? n
                                                          : {}),
                                                  (o = !1),
                                                  (a[t] = T.extend(c, i, r)))
                                                : void 0 !== r && (a[t] = r));
                        return a;
                    }),
                T.extend({
                    expando:
                        'jQuery' + ('3.6.1' + Math.random()).replace(/\D/g, ''),
                    isReady: !0,
                    error: function (e) {
                        throw new Error(e);
                    },
                    noop: function () {},
                    isPlainObject: function (e) {
                        var t, n;
                        return (
                            !(!e || '[object Object]' !== p.call(e)) &&
                            (!(t = a(e)) ||
                                ('function' ==
                                    typeof (n =
                                        d.call(t, 'constructor') &&
                                        t.constructor) &&
                                    h.call(n) === g))
                        );
                    },
                    isEmptyObject: function (e) {
                        var t;
                        for (t in e) return !1;
                        return !0;
                    },
                    globalEval: function (e, t, n) {
                        w(e, { nonce: t && t.nonce }, n);
                    },
                    each: function (e, t) {
                        var n,
                            r = 0;
                        if (k(e))
                            for (
                                n = e.length;
                                r < n && !1 !== t.call(e[r], r, e[r]);
                                r++
                            );
                        else
                            for (r in e)
                                if (!1 === t.call(e[r], r, e[r])) break;
                        return e;
                    },
                    makeArray: function (e, t) {
                        var n = t || [];
                        return (
                            null != e &&
                                (k(Object(e))
                                    ? T.merge(n, 'string' == typeof e ? [e] : e)
                                    : c.call(n, e)),
                            n
                        );
                    },
                    inArray: function (e, t, n) {
                        return null == t ? -1 : l.call(t, e, n);
                    },
                    merge: function (e, t) {
                        for (var n = +t.length, r = 0, o = e.length; r < n; r++)
                            e[o++] = t[r];
                        return (e.length = o), e;
                    },
                    grep: function (e, t, n) {
                        for (
                            var r = [], o = 0, i = e.length, a = !n;
                            o < i;
                            o++
                        )
                            !t(e[o], o) !== a && r.push(e[o]);
                        return r;
                    },
                    map: function (e, t, n) {
                        var r,
                            o,
                            i = 0,
                            a = [];
                        if (k(e))
                            for (r = e.length; i < r; i++)
                                null != (o = t(e[i], i, n)) && a.push(o);
                        else
                            for (i in e)
                                null != (o = t(e[i], i, n)) && a.push(o);
                        return u(a);
                    },
                    guid: 1,
                    support: v,
                }),
                'function' == typeof Symbol &&
                    (T.fn[Symbol.iterator] = i[Symbol.iterator]),
                T.each(
                    'Boolean Number String Function Array Date RegExp Object Error Symbol'.split(
                        ' '
                    ),
                    function (e, t) {
                        f['[object ' + t + ']'] = t.toLowerCase();
                    }
                );
            var S = (function (e) {
                var t,
                    n,
                    r,
                    o,
                    i,
                    a,
                    s,
                    u,
                    c,
                    l,
                    f,
                    p,
                    d,
                    h,
                    g,
                    v,
                    y,
                    m,
                    x,
                    b = 'sizzle' + 1 * new Date(),
                    w = e.document,
                    C = 0,
                    T = 0,
                    k = ue(),
                    S = ue(),
                    A = ue(),
                    E = ue(),
                    N = function (e, t) {
                        return e === t && (f = !0), 0;
                    },
                    j = {}.hasOwnProperty,
                    D = [],
                    L = D.pop,
                    q = D.push,
                    O = D.push,
                    H = D.slice,
                    P = function (e, t) {
                        for (var n = 0, r = e.length; n < r; n++)
                            if (e[n] === t) return n;
                        return -1;
                    },
                    M =
                        'checked|selected|async|autofocus|autoplay|controls|defer|disabled|hidden|ismap|loop|multiple|open|readonly|required|scoped',
                    R = '[\\x20\\t\\r\\n\\f]',
                    B =
                        '(?:\\\\[\\da-fA-F]{1,6}' +
                        R +
                        '?|\\\\[^\\r\\n\\f]|[\\w-]|[^\0-\\x7f])+',
                    I =
                        '\\[' +
                        R +
                        '*(' +
                        B +
                        ')(?:' +
                        R +
                        '*([*^$|!~]?=)' +
                        R +
                        '*(?:\'((?:\\\\.|[^\\\\\'])*)\'|"((?:\\\\.|[^\\\\"])*)"|(' +
                        B +
                        '))|)' +
                        R +
                        '*\\]',
                    F =
                        ':(' +
                        B +
                        ')(?:\\(((\'((?:\\\\.|[^\\\\\'])*)\'|"((?:\\\\.|[^\\\\"])*)")|((?:\\\\.|[^\\\\()[\\]]|' +
                        I +
                        ')*)|.*)\\)|)',
                    W = new RegExp(R + '+', 'g'),
                    $ = new RegExp(
                        '^' + R + '+|((?:^|[^\\\\])(?:\\\\.)*)' + R + '+$',
                        'g'
                    ),
                    _ = new RegExp('^' + R + '*,' + R + '*'),
                    z = new RegExp('^' + R + '*([>+~]|' + R + ')' + R + '*'),
                    U = new RegExp(R + '|>'),
                    X = new RegExp(F),
                    V = new RegExp('^' + B + '$'),
                    G = {
                        ID: new RegExp('^#(' + B + ')'),
                        CLASS: new RegExp('^\\.(' + B + ')'),
                        TAG: new RegExp('^(' + B + '|[*])'),
                        ATTR: new RegExp('^' + I),
                        PSEUDO: new RegExp('^' + F),
                        CHILD: new RegExp(
                            '^:(only|first|last|nth|nth-last)-(child|of-type)(?:\\(' +
                                R +
                                '*(even|odd|(([+-]|)(\\d*)n|)' +
                                R +
                                '*(?:([+-]|)' +
                                R +
                                '*(\\d+)|))' +
                                R +
                                '*\\)|)',
                            'i'
                        ),
                        bool: new RegExp('^(?:' + M + ')$', 'i'),
                        needsContext: new RegExp(
                            '^' +
                                R +
                                '*[>+~]|:(even|odd|eq|gt|lt|nth|first|last)(?:\\(' +
                                R +
                                '*((?:-\\d)?\\d*)' +
                                R +
                                '*\\)|)(?=[^-]|$)',
                            'i'
                        ),
                    },
                    J = /HTML$/i,
                    Y = /^(?:input|select|textarea|button)$/i,
                    Q = /^h\d$/i,
                    K = /^[^{]+\{\s*\[native \w/,
                    Z = /^(?:#([\w-]+)|(\w+)|\.([\w-]+))$/,
                    ee = /[+~]/,
                    te = new RegExp(
                        '\\\\[\\da-fA-F]{1,6}' + R + '?|\\\\([^\\r\\n\\f])',
                        'g'
                    ),
                    ne = function (e, t) {
                        var n = '0x' + e.slice(1) - 65536;
                        return (
                            t ||
                            (n < 0
                                ? String.fromCharCode(n + 65536)
                                : String.fromCharCode(
                                      (n >> 10) | 55296,
                                      (1023 & n) | 56320
                                  ))
                        );
                    },
                    re = /([\0-\x1f\x7f]|^-?\d)|^-$|[^\0-\x1f\x7f-\uFFFF\w-]/g,
                    oe = function (e, t) {
                        return t
                            ? '\0' === e
                                ? '�'
                                : e.slice(0, -1) +
                                  '\\' +
                                  e.charCodeAt(e.length - 1).toString(16) +
                                  ' '
                            : '\\' + e;
                    },
                    ie = function () {
                        p();
                    },
                    ae = be(
                        function (e) {
                            return (
                                !0 === e.disabled &&
                                'fieldset' === e.nodeName.toLowerCase()
                            );
                        },
                        { dir: 'parentNode', next: 'legend' }
                    );
                try {
                    O.apply((D = H.call(w.childNodes)), w.childNodes),
                        D[w.childNodes.length].nodeType;
                } catch (e) {
                    O = {
                        apply: D.length
                            ? function (e, t) {
                                  q.apply(e, H.call(t));
                              }
                            : function (e, t) {
                                  for (
                                      var n = e.length, r = 0;
                                      (e[n++] = t[r++]);

                                  );
                                  e.length = n - 1;
                              },
                    };
                }
                function se(e, t, r, o) {
                    var i,
                        s,
                        c,
                        l,
                        f,
                        h,
                        y,
                        m = t && t.ownerDocument,
                        w = t ? t.nodeType : 9;
                    if (
                        ((r = r || []),
                        'string' != typeof e ||
                            !e ||
                            (1 !== w && 9 !== w && 11 !== w))
                    )
                        return r;
                    if (!o && (p(t), (t = t || d), g)) {
                        if (11 !== w && (f = Z.exec(e)))
                            if ((i = f[1])) {
                                if (9 === w) {
                                    if (!(c = t.getElementById(i))) return r;
                                    if (c.id === i) return r.push(c), r;
                                } else if (
                                    m &&
                                    (c = m.getElementById(i)) &&
                                    x(t, c) &&
                                    c.id === i
                                )
                                    return r.push(c), r;
                            } else {
                                if (f[2])
                                    return (
                                        O.apply(r, t.getElementsByTagName(e)), r
                                    );
                                if (
                                    (i = f[3]) &&
                                    n.getElementsByClassName &&
                                    t.getElementsByClassName
                                )
                                    return (
                                        O.apply(r, t.getElementsByClassName(i)),
                                        r
                                    );
                            }
                        if (
                            n.qsa &&
                            !E[e + ' '] &&
                            (!v || !v.test(e)) &&
                            (1 !== w || 'object' !== t.nodeName.toLowerCase())
                        ) {
                            if (
                                ((y = e),
                                (m = t),
                                1 === w && (U.test(e) || z.test(e)))
                            ) {
                                for (
                                    ((m =
                                        (ee.test(e) && ye(t.parentNode)) ||
                                        t) === t &&
                                        n.scope) ||
                                        ((l = t.getAttribute('id'))
                                            ? (l = l.replace(re, oe))
                                            : t.setAttribute('id', (l = b))),
                                        s = (h = a(e)).length;
                                    s--;

                                )
                                    h[s] =
                                        (l ? '#' + l : ':scope') +
                                        ' ' +
                                        xe(h[s]);
                                y = h.join(',');
                            }
                            try {
                                return O.apply(r, m.querySelectorAll(y)), r;
                            } catch (t) {
                                E(e, !0);
                            } finally {
                                l === b && t.removeAttribute('id');
                            }
                        }
                    }
                    return u(e.replace($, '$1'), t, r, o);
                }
                function ue() {
                    var e = [];
                    return function t(n, o) {
                        return (
                            e.push(n + ' ') > r.cacheLength &&
                                delete t[e.shift()],
                            (t[n + ' '] = o)
                        );
                    };
                }
                function ce(e) {
                    return (e[b] = !0), e;
                }
                function le(e) {
                    var t = d.createElement('fieldset');
                    try {
                        return !!e(t);
                    } catch (e) {
                        return !1;
                    } finally {
                        t.parentNode && t.parentNode.removeChild(t), (t = null);
                    }
                }
                function fe(e, t) {
                    for (var n = e.split('|'), o = n.length; o--; )
                        r.attrHandle[n[o]] = t;
                }
                function pe(e, t) {
                    var n = t && e,
                        r =
                            n &&
                            1 === e.nodeType &&
                            1 === t.nodeType &&
                            e.sourceIndex - t.sourceIndex;
                    if (r) return r;
                    if (n) for (; (n = n.nextSibling); ) if (n === t) return -1;
                    return e ? 1 : -1;
                }
                function de(e) {
                    return function (t) {
                        return (
                            'input' === t.nodeName.toLowerCase() && t.type === e
                        );
                    };
                }
                function he(e) {
                    return function (t) {
                        var n = t.nodeName.toLowerCase();
                        return (
                            ('input' === n || 'button' === n) && t.type === e
                        );
                    };
                }
                function ge(e) {
                    return function (t) {
                        return 'form' in t
                            ? t.parentNode && !1 === t.disabled
                                ? 'label' in t
                                    ? 'label' in t.parentNode
                                        ? t.parentNode.disabled === e
                                        : t.disabled === e
                                    : t.isDisabled === e ||
                                      (t.isDisabled !== !e && ae(t) === e)
                                : t.disabled === e
                            : 'label' in t && t.disabled === e;
                    };
                }
                function ve(e) {
                    return ce(function (t) {
                        return (
                            (t = +t),
                            ce(function (n, r) {
                                for (
                                    var o, i = e([], n.length, t), a = i.length;
                                    a--;

                                )
                                    n[(o = i[a])] && (n[o] = !(r[o] = n[o]));
                            })
                        );
                    });
                }
                function ye(e) {
                    return e && void 0 !== e.getElementsByTagName && e;
                }
                for (t in ((n = se.support = {}),
                (i = se.isXML =
                    function (e) {
                        var t = e && e.namespaceURI,
                            n = e && (e.ownerDocument || e).documentElement;
                        return !J.test(t || (n && n.nodeName) || 'HTML');
                    }),
                (p = se.setDocument =
                    function (e) {
                        var t,
                            o,
                            a = e ? e.ownerDocument || e : w;
                        return a != d && 9 === a.nodeType && a.documentElement
                            ? ((h = (d = a).documentElement),
                              (g = !i(d)),
                              w != d &&
                                  (o = d.defaultView) &&
                                  o.top !== o &&
                                  (o.addEventListener
                                      ? o.addEventListener('unload', ie, !1)
                                      : o.attachEvent &&
                                        o.attachEvent('onunload', ie)),
                              (n.scope = le(function (e) {
                                  return (
                                      h
                                          .appendChild(e)
                                          .appendChild(d.createElement('div')),
                                      void 0 !== e.querySelectorAll &&
                                          !e.querySelectorAll(
                                              ':scope fieldset div'
                                          ).length
                                  );
                              })),
                              (n.attributes = le(function (e) {
                                  return (
                                      (e.className = 'i'),
                                      !e.getAttribute('className')
                                  );
                              })),
                              (n.getElementsByTagName = le(function (e) {
                                  return (
                                      e.appendChild(d.createComment('')),
                                      !e.getElementsByTagName('*').length
                                  );
                              })),
                              (n.getElementsByClassName = K.test(
                                  d.getElementsByClassName
                              )),
                              (n.getById = le(function (e) {
                                  return (
                                      (h.appendChild(e).id = b),
                                      !d.getElementsByName ||
                                          !d.getElementsByName(b).length
                                  );
                              })),
                              n.getById
                                  ? ((r.filter.ID = function (e) {
                                        var t = e.replace(te, ne);
                                        return function (e) {
                                            return e.getAttribute('id') === t;
                                        };
                                    }),
                                    (r.find.ID = function (e, t) {
                                        if (void 0 !== t.getElementById && g) {
                                            var n = t.getElementById(e);
                                            return n ? [n] : [];
                                        }
                                    }))
                                  : ((r.filter.ID = function (e) {
                                        var t = e.replace(te, ne);
                                        return function (e) {
                                            var n =
                                                void 0 !== e.getAttributeNode &&
                                                e.getAttributeNode('id');
                                            return n && n.value === t;
                                        };
                                    }),
                                    (r.find.ID = function (e, t) {
                                        if (void 0 !== t.getElementById && g) {
                                            var n,
                                                r,
                                                o,
                                                i = t.getElementById(e);
                                            if (i) {
                                                if (
                                                    (n =
                                                        i.getAttributeNode(
                                                            'id'
                                                        )) &&
                                                    n.value === e
                                                )
                                                    return [i];
                                                for (
                                                    o = t.getElementsByName(e),
                                                        r = 0;
                                                    (i = o[r++]);

                                                )
                                                    if (
                                                        (n =
                                                            i.getAttributeNode(
                                                                'id'
                                                            )) &&
                                                        n.value === e
                                                    )
                                                        return [i];
                                            }
                                            return [];
                                        }
                                    })),
                              (r.find.TAG = n.getElementsByTagName
                                  ? function (e, t) {
                                        return void 0 !== t.getElementsByTagName
                                            ? t.getElementsByTagName(e)
                                            : n.qsa
                                            ? t.querySelectorAll(e)
                                            : void 0;
                                    }
                                  : function (e, t) {
                                        var n,
                                            r = [],
                                            o = 0,
                                            i = t.getElementsByTagName(e);
                                        if ('*' === e) {
                                            for (; (n = i[o++]); )
                                                1 === n.nodeType && r.push(n);
                                            return r;
                                        }
                                        return i;
                                    }),
                              (r.find.CLASS =
                                  n.getElementsByClassName &&
                                  function (e, t) {
                                      if (
                                          void 0 !== t.getElementsByClassName &&
                                          g
                                      )
                                          return t.getElementsByClassName(e);
                                  }),
                              (y = []),
                              (v = []),
                              (n.qsa = K.test(d.querySelectorAll)) &&
                                  (le(function (e) {
                                      var t;
                                      (h.appendChild(e).innerHTML =
                                          "<a id='" +
                                          b +
                                          "'></a><select id='" +
                                          b +
                                          "-\r\\' msallowcapture=''><option selected=''></option></select>"),
                                          e.querySelectorAll(
                                              "[msallowcapture^='']"
                                          ).length &&
                                              v.push(
                                                  '[*^$]=' + R + '*(?:\'\'|"")'
                                              ),
                                          e.querySelectorAll('[selected]')
                                              .length ||
                                              v.push(
                                                  '\\[' +
                                                      R +
                                                      '*(?:value|' +
                                                      M +
                                                      ')'
                                              ),
                                          e.querySelectorAll('[id~=' + b + '-]')
                                              .length || v.push('~='),
                                          (t =
                                              d.createElement(
                                                  'input'
                                              )).setAttribute('name', ''),
                                          e.appendChild(t),
                                          e.querySelectorAll("[name='']")
                                              .length ||
                                              v.push(
                                                  '\\[' +
                                                      R +
                                                      '*name' +
                                                      R +
                                                      '*=' +
                                                      R +
                                                      '*(?:\'\'|"")'
                                              ),
                                          e.querySelectorAll(':checked')
                                              .length || v.push(':checked'),
                                          e.querySelectorAll('a#' + b + '+*')
                                              .length || v.push('.#.+[+~]'),
                                          e.querySelectorAll('\\\f'),
                                          v.push('[\\r\\n\\f]');
                                  }),
                                  le(function (e) {
                                      e.innerHTML =
                                          "<a href='' disabled='disabled'></a><select disabled='disabled'><option/></select>";
                                      var t = d.createElement('input');
                                      t.setAttribute('type', 'hidden'),
                                          e
                                              .appendChild(t)
                                              .setAttribute('name', 'D'),
                                          e.querySelectorAll('[name=d]')
                                              .length &&
                                              v.push(
                                                  'name' + R + '*[*^$|!~]?='
                                              ),
                                          2 !==
                                              e.querySelectorAll(':enabled')
                                                  .length &&
                                              v.push(':enabled', ':disabled'),
                                          (h.appendChild(e).disabled = !0),
                                          2 !==
                                              e.querySelectorAll(':disabled')
                                                  .length &&
                                              v.push(':enabled', ':disabled'),
                                          e.querySelectorAll('*,:x'),
                                          v.push(',.*:');
                                  })),
                              (n.matchesSelector = K.test(
                                  (m =
                                      h.matches ||
                                      h.webkitMatchesSelector ||
                                      h.mozMatchesSelector ||
                                      h.oMatchesSelector ||
                                      h.msMatchesSelector)
                              )) &&
                                  le(function (e) {
                                      (n.disconnectedMatch = m.call(e, '*')),
                                          m.call(e, "[s!='']:x"),
                                          y.push('!=', F);
                                  }),
                              (v = v.length && new RegExp(v.join('|'))),
                              (y = y.length && new RegExp(y.join('|'))),
                              (t = K.test(h.compareDocumentPosition)),
                              (x =
                                  t || K.test(h.contains)
                                      ? function (e, t) {
                                            var n =
                                                    9 === e.nodeType
                                                        ? e.documentElement
                                                        : e,
                                                r = t && t.parentNode;
                                            return (
                                                e === r ||
                                                !(
                                                    !r ||
                                                    1 !== r.nodeType ||
                                                    !(n.contains
                                                        ? n.contains(r)
                                                        : e.compareDocumentPosition &&
                                                          16 &
                                                              e.compareDocumentPosition(
                                                                  r
                                                              ))
                                                )
                                            );
                                        }
                                      : function (e, t) {
                                            if (t)
                                                for (; (t = t.parentNode); )
                                                    if (t === e) return !0;
                                            return !1;
                                        }),
                              (N = t
                                  ? function (e, t) {
                                        if (e === t) return (f = !0), 0;
                                        var r =
                                            !e.compareDocumentPosition -
                                            !t.compareDocumentPosition;
                                        return (
                                            r ||
                                            (1 &
                                                (r =
                                                    (e.ownerDocument || e) ==
                                                    (t.ownerDocument || t)
                                                        ? e.compareDocumentPosition(
                                                              t
                                                          )
                                                        : 1) ||
                                            (!n.sortDetached &&
                                                t.compareDocumentPosition(e) ===
                                                    r)
                                                ? e == d ||
                                                  (e.ownerDocument == w &&
                                                      x(w, e))
                                                    ? -1
                                                    : t == d ||
                                                      (t.ownerDocument == w &&
                                                          x(w, t))
                                                    ? 1
                                                    : l
                                                    ? P(l, e) - P(l, t)
                                                    : 0
                                                : 4 & r
                                                ? -1
                                                : 1)
                                        );
                                    }
                                  : function (e, t) {
                                        if (e === t) return (f = !0), 0;
                                        var n,
                                            r = 0,
                                            o = e.parentNode,
                                            i = t.parentNode,
                                            a = [e],
                                            s = [t];
                                        if (!o || !i)
                                            return e == d
                                                ? -1
                                                : t == d
                                                ? 1
                                                : o
                                                ? -1
                                                : i
                                                ? 1
                                                : l
                                                ? P(l, e) - P(l, t)
                                                : 0;
                                        if (o === i) return pe(e, t);
                                        for (n = e; (n = n.parentNode); )
                                            a.unshift(n);
                                        for (n = t; (n = n.parentNode); )
                                            s.unshift(n);
                                        for (; a[r] === s[r]; ) r++;
                                        return r
                                            ? pe(a[r], s[r])
                                            : a[r] == w
                                            ? -1
                                            : s[r] == w
                                            ? 1
                                            : 0;
                                    }),
                              d)
                            : d;
                    }),
                (se.matches = function (e, t) {
                    return se(e, null, null, t);
                }),
                (se.matchesSelector = function (e, t) {
                    if (
                        (p(e),
                        n.matchesSelector &&
                            g &&
                            !E[t + ' '] &&
                            (!y || !y.test(t)) &&
                            (!v || !v.test(t)))
                    )
                        try {
                            var r = m.call(e, t);
                            if (
                                r ||
                                n.disconnectedMatch ||
                                (e.document && 11 !== e.document.nodeType)
                            )
                                return r;
                        } catch (e) {
                            E(t, !0);
                        }
                    return se(t, d, null, [e]).length > 0;
                }),
                (se.contains = function (e, t) {
                    return (e.ownerDocument || e) != d && p(e), x(e, t);
                }),
                (se.attr = function (e, t) {
                    (e.ownerDocument || e) != d && p(e);
                    var o = r.attrHandle[t.toLowerCase()],
                        i =
                            o && j.call(r.attrHandle, t.toLowerCase())
                                ? o(e, t, !g)
                                : void 0;
                    return void 0 !== i
                        ? i
                        : n.attributes || !g
                        ? e.getAttribute(t)
                        : (i = e.getAttributeNode(t)) && i.specified
                        ? i.value
                        : null;
                }),
                (se.escape = function (e) {
                    return (e + '').replace(re, oe);
                }),
                (se.error = function (e) {
                    throw new Error(
                        'Syntax error, unrecognized expression: ' + e
                    );
                }),
                (se.uniqueSort = function (e) {
                    var t,
                        r = [],
                        o = 0,
                        i = 0;
                    if (
                        ((f = !n.detectDuplicates),
                        (l = !n.sortStable && e.slice(0)),
                        e.sort(N),
                        f)
                    ) {
                        for (; (t = e[i++]); ) t === e[i] && (o = r.push(i));
                        for (; o--; ) e.splice(r[o], 1);
                    }
                    return (l = null), e;
                }),
                (o = se.getText =
                    function (e) {
                        var t,
                            n = '',
                            r = 0,
                            i = e.nodeType;
                        if (i) {
                            if (1 === i || 9 === i || 11 === i) {
                                if ('string' == typeof e.textContent)
                                    return e.textContent;
                                for (e = e.firstChild; e; e = e.nextSibling)
                                    n += o(e);
                            } else if (3 === i || 4 === i) return e.nodeValue;
                        } else for (; (t = e[r++]); ) n += o(t);
                        return n;
                    }),
                ((r = se.selectors =
                    {
                        cacheLength: 50,
                        createPseudo: ce,
                        match: G,
                        attrHandle: {},
                        find: {},
                        relative: {
                            '>': { dir: 'parentNode', first: !0 },
                            ' ': { dir: 'parentNode' },
                            '+': { dir: 'previousSibling', first: !0 },
                            '~': { dir: 'previousSibling' },
                        },
                        preFilter: {
                            ATTR: function (e) {
                                return (
                                    (e[1] = e[1].replace(te, ne)),
                                    (e[3] = (
                                        e[3] ||
                                        e[4] ||
                                        e[5] ||
                                        ''
                                    ).replace(te, ne)),
                                    '~=' === e[2] && (e[3] = ' ' + e[3] + ' '),
                                    e.slice(0, 4)
                                );
                            },
                            CHILD: function (e) {
                                return (
                                    (e[1] = e[1].toLowerCase()),
                                    'nth' === e[1].slice(0, 3)
                                        ? (e[3] || se.error(e[0]),
                                          (e[4] = +(e[4]
                                              ? e[5] + (e[6] || 1)
                                              : 2 *
                                                ('even' === e[3] ||
                                                    'odd' === e[3]))),
                                          (e[5] = +(
                                              e[7] + e[8] || 'odd' === e[3]
                                          )))
                                        : e[3] && se.error(e[0]),
                                    e
                                );
                            },
                            PSEUDO: function (e) {
                                var t,
                                    n = !e[6] && e[2];
                                return G.CHILD.test(e[0])
                                    ? null
                                    : (e[3]
                                          ? (e[2] = e[4] || e[5] || '')
                                          : n &&
                                            X.test(n) &&
                                            (t = a(n, !0)) &&
                                            (t =
                                                n.indexOf(')', n.length - t) -
                                                n.length) &&
                                            ((e[0] = e[0].slice(0, t)),
                                            (e[2] = n.slice(0, t))),
                                      e.slice(0, 3));
                            },
                        },
                        filter: {
                            TAG: function (e) {
                                var t = e.replace(te, ne).toLowerCase();
                                return '*' === e
                                    ? function () {
                                          return !0;
                                      }
                                    : function (e) {
                                          return (
                                              e.nodeName &&
                                              e.nodeName.toLowerCase() === t
                                          );
                                      };
                            },
                            CLASS: function (e) {
                                var t = k[e + ' '];
                                return (
                                    t ||
                                    ((t = new RegExp(
                                        '(^|' + R + ')' + e + '(' + R + '|$)'
                                    )) &&
                                        k(e, function (e) {
                                            return t.test(
                                                ('string' ==
                                                    typeof e.className &&
                                                    e.className) ||
                                                    (void 0 !==
                                                        e.getAttribute &&
                                                        e.getAttribute(
                                                            'class'
                                                        )) ||
                                                    ''
                                            );
                                        }))
                                );
                            },
                            ATTR: function (e, t, n) {
                                return function (r) {
                                    var o = se.attr(r, e);
                                    return null == o
                                        ? '!=' === t
                                        : !t ||
                                              ((o += ''),
                                              '=' === t
                                                  ? o === n
                                                  : '!=' === t
                                                  ? o !== n
                                                  : '^=' === t
                                                  ? n && 0 === o.indexOf(n)
                                                  : '*=' === t
                                                  ? n && o.indexOf(n) > -1
                                                  : '$=' === t
                                                  ? n &&
                                                    o.slice(-n.length) === n
                                                  : '~=' === t
                                                  ? (
                                                        ' ' +
                                                        o.replace(W, ' ') +
                                                        ' '
                                                    ).indexOf(n) > -1
                                                  : '|=' === t &&
                                                    (o === n ||
                                                        o.slice(
                                                            0,
                                                            n.length + 1
                                                        ) ===
                                                            n + '-'));
                                };
                            },
                            CHILD: function (e, t, n, r, o) {
                                var i = 'nth' !== e.slice(0, 3),
                                    a = 'last' !== e.slice(-4),
                                    s = 'of-type' === t;
                                return 1 === r && 0 === o
                                    ? function (e) {
                                          return !!e.parentNode;
                                      }
                                    : function (t, n, u) {
                                          var c,
                                              l,
                                              f,
                                              p,
                                              d,
                                              h,
                                              g =
                                                  i !== a
                                                      ? 'nextSibling'
                                                      : 'previousSibling',
                                              v = t.parentNode,
                                              y = s && t.nodeName.toLowerCase(),
                                              m = !u && !s,
                                              x = !1;
                                          if (v) {
                                              if (i) {
                                                  for (; g; ) {
                                                      for (p = t; (p = p[g]); )
                                                          if (
                                                              s
                                                                  ? p.nodeName.toLowerCase() ===
                                                                    y
                                                                  : 1 ===
                                                                    p.nodeType
                                                          )
                                                              return !1;
                                                      h = g =
                                                          'only' === e &&
                                                          !h &&
                                                          'nextSibling';
                                                  }
                                                  return !0;
                                              }
                                              if (
                                                  ((h = [
                                                      a
                                                          ? v.firstChild
                                                          : v.lastChild,
                                                  ]),
                                                  a && m)
                                              ) {
                                                  for (
                                                      x =
                                                          (d =
                                                              (c =
                                                                  (l =
                                                                      (f =
                                                                          (p =
                                                                              v)[
                                                                              b
                                                                          ] ||
                                                                          (p[
                                                                              b
                                                                          ] =
                                                                              {}))[
                                                                          p
                                                                              .uniqueID
                                                                      ] ||
                                                                      (f[
                                                                          p.uniqueID
                                                                      ] = {}))[
                                                                      e
                                                                  ] ||
                                                                  [])[0] ===
                                                                  C && c[1]) &&
                                                          c[2],
                                                          p =
                                                              d &&
                                                              v.childNodes[d];
                                                      (p =
                                                          (++d && p && p[g]) ||
                                                          (x = d = 0) ||
                                                          h.pop());

                                                  )
                                                      if (
                                                          1 === p.nodeType &&
                                                          ++x &&
                                                          p === t
                                                      ) {
                                                          l[e] = [C, d, x];
                                                          break;
                                                      }
                                              } else if (
                                                  (m &&
                                                      (x = d =
                                                          (c =
                                                              (l =
                                                                  (f =
                                                                      (p = t)[
                                                                          b
                                                                      ] ||
                                                                      (p[b] =
                                                                          {}))[
                                                                      p.uniqueID
                                                                  ] ||
                                                                  (f[
                                                                      p.uniqueID
                                                                  ] = {}))[e] ||
                                                              [])[0] === C &&
                                                          c[1]),
                                                  !1 === x)
                                              )
                                                  for (
                                                      ;
                                                      (p =
                                                          (++d && p && p[g]) ||
                                                          (x = d = 0) ||
                                                          h.pop()) &&
                                                      ((s
                                                          ? p.nodeName.toLowerCase() !==
                                                            y
                                                          : 1 !== p.nodeType) ||
                                                          !++x ||
                                                          (m &&
                                                              ((l =
                                                                  (f =
                                                                      p[b] ||
                                                                      (p[b] =
                                                                          {}))[
                                                                      p.uniqueID
                                                                  ] ||
                                                                  (f[
                                                                      p.uniqueID
                                                                  ] = {}))[e] =
                                                                  [C, x]),
                                                          p !== t));

                                                  );
                                              return (
                                                  (x -= o) === r ||
                                                  (x % r == 0 && x / r >= 0)
                                              );
                                          }
                                      };
                            },
                            PSEUDO: function (e, t) {
                                var n,
                                    o =
                                        r.pseudos[e] ||
                                        r.setFilters[e.toLowerCase()] ||
                                        se.error('unsupported pseudo: ' + e);
                                return o[b]
                                    ? o(t)
                                    : o.length > 1
                                    ? ((n = [e, e, '', t]),
                                      r.setFilters.hasOwnProperty(
                                          e.toLowerCase()
                                      )
                                          ? ce(function (e, n) {
                                                for (
                                                    var r,
                                                        i = o(e, t),
                                                        a = i.length;
                                                    a--;

                                                )
                                                    e[(r = P(e, i[a]))] = !(n[
                                                        r
                                                    ] = i[a]);
                                            })
                                          : function (e) {
                                                return o(e, 0, n);
                                            })
                                    : o;
                            },
                        },
                        pseudos: {
                            not: ce(function (e) {
                                var t = [],
                                    n = [],
                                    r = s(e.replace($, '$1'));
                                return r[b]
                                    ? ce(function (e, t, n, o) {
                                          for (
                                              var i,
                                                  a = r(e, null, o, []),
                                                  s = e.length;
                                              s--;

                                          )
                                              (i = a[s]) &&
                                                  (e[s] = !(t[s] = i));
                                      })
                                    : function (e, o, i) {
                                          return (
                                              (t[0] = e),
                                              r(t, null, i, n),
                                              (t[0] = null),
                                              !n.pop()
                                          );
                                      };
                            }),
                            has: ce(function (e) {
                                return function (t) {
                                    return se(e, t).length > 0;
                                };
                            }),
                            contains: ce(function (e) {
                                return (
                                    (e = e.replace(te, ne)),
                                    function (t) {
                                        return (
                                            (t.textContent || o(t)).indexOf(e) >
                                            -1
                                        );
                                    }
                                );
                            }),
                            lang: ce(function (e) {
                                return (
                                    V.test(e || '') ||
                                        se.error('unsupported lang: ' + e),
                                    (e = e.replace(te, ne).toLowerCase()),
                                    function (t) {
                                        var n;
                                        do {
                                            if (
                                                (n = g
                                                    ? t.lang
                                                    : t.getAttribute(
                                                          'xml:lang'
                                                      ) ||
                                                      t.getAttribute('lang'))
                                            )
                                                return (
                                                    (n = n.toLowerCase()) ===
                                                        e ||
                                                    0 === n.indexOf(e + '-')
                                                );
                                        } while (
                                            (t = t.parentNode) &&
                                            1 === t.nodeType
                                        );
                                        return !1;
                                    }
                                );
                            }),
                            target: function (t) {
                                var n = e.location && e.location.hash;
                                return n && n.slice(1) === t.id;
                            },
                            root: function (e) {
                                return e === h;
                            },
                            focus: function (e) {
                                return (
                                    e === d.activeElement &&
                                    (!d.hasFocus || d.hasFocus()) &&
                                    !!(e.type || e.href || ~e.tabIndex)
                                );
                            },
                            enabled: ge(!1),
                            disabled: ge(!0),
                            checked: function (e) {
                                var t = e.nodeName.toLowerCase();
                                return (
                                    ('input' === t && !!e.checked) ||
                                    ('option' === t && !!e.selected)
                                );
                            },
                            selected: function (e) {
                                return (
                                    e.parentNode && e.parentNode.selectedIndex,
                                    !0 === e.selected
                                );
                            },
                            empty: function (e) {
                                for (e = e.firstChild; e; e = e.nextSibling)
                                    if (e.nodeType < 6) return !1;
                                return !0;
                            },
                            parent: function (e) {
                                return !r.pseudos.empty(e);
                            },
                            header: function (e) {
                                return Q.test(e.nodeName);
                            },
                            input: function (e) {
                                return Y.test(e.nodeName);
                            },
                            button: function (e) {
                                var t = e.nodeName.toLowerCase();
                                return (
                                    ('input' === t && 'button' === e.type) ||
                                    'button' === t
                                );
                            },
                            text: function (e) {
                                var t;
                                return (
                                    'input' === e.nodeName.toLowerCase() &&
                                    'text' === e.type &&
                                    (null == (t = e.getAttribute('type')) ||
                                        'text' === t.toLowerCase())
                                );
                            },
                            first: ve(function () {
                                return [0];
                            }),
                            last: ve(function (e, t) {
                                return [t - 1];
                            }),
                            eq: ve(function (e, t, n) {
                                return [n < 0 ? n + t : n];
                            }),
                            even: ve(function (e, t) {
                                for (var n = 0; n < t; n += 2) e.push(n);
                                return e;
                            }),
                            odd: ve(function (e, t) {
                                for (var n = 1; n < t; n += 2) e.push(n);
                                return e;
                            }),
                            lt: ve(function (e, t, n) {
                                for (
                                    var r = n < 0 ? n + t : n > t ? t : n;
                                    --r >= 0;

                                )
                                    e.push(r);
                                return e;
                            }),
                            gt: ve(function (e, t, n) {
                                for (var r = n < 0 ? n + t : n; ++r < t; )
                                    e.push(r);
                                return e;
                            }),
                        },
                    }).pseudos.nth = r.pseudos.eq),
                { radio: !0, checkbox: !0, file: !0, password: !0, image: !0 }))
                    r.pseudos[t] = de(t);
                for (t in { submit: !0, reset: !0 }) r.pseudos[t] = he(t);
                function me() {}
                function xe(e) {
                    for (var t = 0, n = e.length, r = ''; t < n; t++)
                        r += e[t].value;
                    return r;
                }
                function be(e, t, n) {
                    var r = t.dir,
                        o = t.next,
                        i = o || r,
                        a = n && 'parentNode' === i,
                        s = T++;
                    return t.first
                        ? function (t, n, o) {
                              for (; (t = t[r]); )
                                  if (1 === t.nodeType || a) return e(t, n, o);
                              return !1;
                          }
                        : function (t, n, u) {
                              var c,
                                  l,
                                  f,
                                  p = [C, s];
                              if (u) {
                                  for (; (t = t[r]); )
                                      if ((1 === t.nodeType || a) && e(t, n, u))
                                          return !0;
                              } else
                                  for (; (t = t[r]); )
                                      if (1 === t.nodeType || a)
                                          if (
                                              ((l =
                                                  (f = t[b] || (t[b] = {}))[
                                                      t.uniqueID
                                                  ] || (f[t.uniqueID] = {})),
                                              o &&
                                                  o ===
                                                      t.nodeName.toLowerCase())
                                          )
                                              t = t[r] || t;
                                          else {
                                              if (
                                                  (c = l[i]) &&
                                                  c[0] === C &&
                                                  c[1] === s
                                              )
                                                  return (p[2] = c[2]);
                                              if (
                                                  ((l[i] = p),
                                                  (p[2] = e(t, n, u)))
                                              )
                                                  return !0;
                                          }
                              return !1;
                          };
                }
                function we(e) {
                    return e.length > 1
                        ? function (t, n, r) {
                              for (var o = e.length; o--; )
                                  if (!e[o](t, n, r)) return !1;
                              return !0;
                          }
                        : e[0];
                }
                function Ce(e, t, n, r, o) {
                    for (
                        var i, a = [], s = 0, u = e.length, c = null != t;
                        s < u;
                        s++
                    )
                        (i = e[s]) &&
                            ((n && !n(i, r, o)) || (a.push(i), c && t.push(s)));
                    return a;
                }
                function Te(e, t, n, r, o, i) {
                    return (
                        r && !r[b] && (r = Te(r)),
                        o && !o[b] && (o = Te(o, i)),
                        ce(function (i, a, s, u) {
                            var c,
                                l,
                                f,
                                p = [],
                                d = [],
                                h = a.length,
                                g =
                                    i ||
                                    (function (e, t, n) {
                                        for (
                                            var r = 0, o = t.length;
                                            r < o;
                                            r++
                                        )
                                            se(e, t[r], n);
                                        return n;
                                    })(t || '*', s.nodeType ? [s] : s, []),
                                v = !e || (!i && t) ? g : Ce(g, p, e, s, u),
                                y = n ? (o || (i ? e : h || r) ? [] : a) : v;
                            if ((n && n(v, y, s, u), r))
                                for (
                                    c = Ce(y, d), r(c, [], s, u), l = c.length;
                                    l--;

                                )
                                    (f = c[l]) && (y[d[l]] = !(v[d[l]] = f));
                            if (i) {
                                if (o || e) {
                                    if (o) {
                                        for (c = [], l = y.length; l--; )
                                            (f = y[l]) && c.push((v[l] = f));
                                        o(null, (y = []), c, u);
                                    }
                                    for (l = y.length; l--; )
                                        (f = y[l]) &&
                                            (c = o ? P(i, f) : p[l]) > -1 &&
                                            (i[c] = !(a[c] = f));
                                }
                            } else (y = Ce(y === a ? y.splice(h, y.length) : y)), o ? o(null, a, y, u) : O.apply(a, y);
                        })
                    );
                }
                function ke(e) {
                    for (
                        var t,
                            n,
                            o,
                            i = e.length,
                            a = r.relative[e[0].type],
                            s = a || r.relative[' '],
                            u = a ? 1 : 0,
                            l = be(
                                function (e) {
                                    return e === t;
                                },
                                s,
                                !0
                            ),
                            f = be(
                                function (e) {
                                    return P(t, e) > -1;
                                },
                                s,
                                !0
                            ),
                            p = [
                                function (e, n, r) {
                                    var o =
                                        (!a && (r || n !== c)) ||
                                        ((t = n).nodeType
                                            ? l(e, n, r)
                                            : f(e, n, r));
                                    return (t = null), o;
                                },
                            ];
                        u < i;
                        u++
                    )
                        if ((n = r.relative[e[u].type])) p = [be(we(p), n)];
                        else {
                            if (
                                (n = r.filter[e[u].type].apply(
                                    null,
                                    e[u].matches
                                ))[b]
                            ) {
                                for (
                                    o = ++u;
                                    o < i && !r.relative[e[o].type];
                                    o++
                                );
                                return Te(
                                    u > 1 && we(p),
                                    u > 1 &&
                                        xe(
                                            e
                                                .slice(0, u - 1)
                                                .concat({
                                                    value:
                                                        ' ' === e[u - 2].type
                                                            ? '*'
                                                            : '',
                                                })
                                        ).replace($, '$1'),
                                    n,
                                    u < o && ke(e.slice(u, o)),
                                    o < i && ke((e = e.slice(o))),
                                    o < i && xe(e)
                                );
                            }
                            p.push(n);
                        }
                    return we(p);
                }
                return (
                    (me.prototype = r.filters = r.pseudos),
                    (r.setFilters = new me()),
                    (a = se.tokenize =
                        function (e, t) {
                            var n,
                                o,
                                i,
                                a,
                                s,
                                u,
                                c,
                                l = S[e + ' '];
                            if (l) return t ? 0 : l.slice(0);
                            for (s = e, u = [], c = r.preFilter; s; ) {
                                for (a in ((n && !(o = _.exec(s))) ||
                                    (o && (s = s.slice(o[0].length) || s),
                                    u.push((i = []))),
                                (n = !1),
                                (o = z.exec(s)) &&
                                    ((n = o.shift()),
                                    i.push({
                                        value: n,
                                        type: o[0].replace($, ' '),
                                    }),
                                    (s = s.slice(n.length))),
                                r.filter))
                                    !(o = G[a].exec(s)) ||
                                        (c[a] && !(o = c[a](o))) ||
                                        ((n = o.shift()),
                                        i.push({
                                            value: n,
                                            type: a,
                                            matches: o,
                                        }),
                                        (s = s.slice(n.length)));
                                if (!n) break;
                            }
                            return t
                                ? s.length
                                : s
                                ? se.error(e)
                                : S(e, u).slice(0);
                        }),
                    (s = se.compile =
                        function (e, t) {
                            var n,
                                o = [],
                                i = [],
                                s = A[e + ' '];
                            if (!s) {
                                for (t || (t = a(e)), n = t.length; n--; )
                                    (s = ke(t[n]))[b] ? o.push(s) : i.push(s);
                                (s = A(
                                    e,
                                    (function (e, t) {
                                        var n = t.length > 0,
                                            o = e.length > 0,
                                            i = function (i, a, s, u, l) {
                                                var f,
                                                    h,
                                                    v,
                                                    y = 0,
                                                    m = '0',
                                                    x = i && [],
                                                    b = [],
                                                    w = c,
                                                    T =
                                                        i ||
                                                        (o &&
                                                            r.find.TAG('*', l)),
                                                    k = (C +=
                                                        null == w
                                                            ? 1
                                                            : Math.random() ||
                                                              0.1),
                                                    S = T.length;
                                                for (
                                                    l && (c = a == d || a || l);
                                                    m !== S &&
                                                    null != (f = T[m]);
                                                    m++
                                                ) {
                                                    if (o && f) {
                                                        for (
                                                            h = 0,
                                                                a ||
                                                                    f.ownerDocument ==
                                                                        d ||
                                                                    (p(f),
                                                                    (s = !g));
                                                            (v = e[h++]);

                                                        )
                                                            if (
                                                                v(f, a || d, s)
                                                            ) {
                                                                u.push(f);
                                                                break;
                                                            }
                                                        l && (C = k);
                                                    }
                                                    n &&
                                                        ((f = !v && f) && y--,
                                                        i && x.push(f));
                                                }
                                                if (((y += m), n && m !== y)) {
                                                    for (h = 0; (v = t[h++]); )
                                                        v(x, b, a, s);
                                                    if (i) {
                                                        if (y > 0)
                                                            for (; m--; )
                                                                x[m] ||
                                                                    b[m] ||
                                                                    (b[m] =
                                                                        L.call(
                                                                            u
                                                                        ));
                                                        b = Ce(b);
                                                    }
                                                    O.apply(u, b),
                                                        l &&
                                                            !i &&
                                                            b.length > 0 &&
                                                            y + t.length > 1 &&
                                                            se.uniqueSort(u);
                                                }
                                                return (
                                                    l && ((C = k), (c = w)), x
                                                );
                                            };
                                        return n ? ce(i) : i;
                                    })(i, o)
                                )).selector = e;
                            }
                            return s;
                        }),
                    (u = se.select =
                        function (e, t, n, o) {
                            var i,
                                u,
                                c,
                                l,
                                f,
                                p = 'function' == typeof e && e,
                                d = !o && a((e = p.selector || e));
                            if (((n = n || []), 1 === d.length)) {
                                if (
                                    (u = d[0] = d[0].slice(0)).length > 2 &&
                                    'ID' === (c = u[0]).type &&
                                    9 === t.nodeType &&
                                    g &&
                                    r.relative[u[1].type]
                                ) {
                                    if (
                                        !(t = (r.find.ID(
                                            c.matches[0].replace(te, ne),
                                            t
                                        ) || [])[0])
                                    )
                                        return n;
                                    p && (t = t.parentNode),
                                        (e = e.slice(u.shift().value.length));
                                }
                                for (
                                    i = G.needsContext.test(e) ? 0 : u.length;
                                    i-- &&
                                    ((c = u[i]), !r.relative[(l = c.type)]);

                                )
                                    if (
                                        (f = r.find[l]) &&
                                        (o = f(
                                            c.matches[0].replace(te, ne),
                                            (ee.test(u[0].type) &&
                                                ye(t.parentNode)) ||
                                                t
                                        ))
                                    ) {
                                        if (
                                            (u.splice(i, 1),
                                            !(e = o.length && xe(u)))
                                        )
                                            return O.apply(n, o), n;
                                        break;
                                    }
                            }
                            return (
                                (p || s(e, d))(
                                    o,
                                    t,
                                    !g,
                                    n,
                                    !t || (ee.test(e) && ye(t.parentNode)) || t
                                ),
                                n
                            );
                        }),
                    (n.sortStable = b.split('').sort(N).join('') === b),
                    (n.detectDuplicates = !!f),
                    p(),
                    (n.sortDetached = le(function (e) {
                        return (
                            1 &
                            e.compareDocumentPosition(
                                d.createElement('fieldset')
                            )
                        );
                    })),
                    le(function (e) {
                        return (
                            (e.innerHTML = "<a href='#'></a>"),
                            '#' === e.firstChild.getAttribute('href')
                        );
                    }) ||
                        fe('type|href|height|width', function (e, t, n) {
                            if (!n)
                                return e.getAttribute(
                                    t,
                                    'type' === t.toLowerCase() ? 1 : 2
                                );
                        }),
                    (n.attributes &&
                        le(function (e) {
                            return (
                                (e.innerHTML = '<input/>'),
                                e.firstChild.setAttribute('value', ''),
                                '' === e.firstChild.getAttribute('value')
                            );
                        })) ||
                        fe('value', function (e, t, n) {
                            if (!n && 'input' === e.nodeName.toLowerCase())
                                return e.defaultValue;
                        }),
                    le(function (e) {
                        return null == e.getAttribute('disabled');
                    }) ||
                        fe(M, function (e, t, n) {
                            var r;
                            if (!n)
                                return !0 === e[t]
                                    ? t.toLowerCase()
                                    : (r = e.getAttributeNode(t)) && r.specified
                                    ? r.value
                                    : null;
                        }),
                    se
                );
            })(n);
            (T.find = S),
                (T.expr = S.selectors),
                (T.expr[':'] = T.expr.pseudos),
                (T.uniqueSort = T.unique = S.uniqueSort),
                (T.text = S.getText),
                (T.isXMLDoc = S.isXML),
                (T.contains = S.contains),
                (T.escapeSelector = S.escape);
            var A = function (e, t, n) {
                    for (
                        var r = [], o = void 0 !== n;
                        (e = e[t]) && 9 !== e.nodeType;

                    )
                        if (1 === e.nodeType) {
                            if (o && T(e).is(n)) break;
                            r.push(e);
                        }
                    return r;
                },
                E = function (e, t) {
                    for (var n = []; e; e = e.nextSibling)
                        1 === e.nodeType && e !== t && n.push(e);
                    return n;
                },
                N = T.expr.match.needsContext;
            function j(e, t) {
                return (
                    e.nodeName && e.nodeName.toLowerCase() === t.toLowerCase()
                );
            }
            var D =
                /^<([a-z][^\/\0>:\x20\t\r\n\f]*)[\x20\t\r\n\f]*\/?>(?:<\/\1>|)$/i;
            function L(e, t, n) {
                return y(t)
                    ? T.grep(e, function (e, r) {
                          return !!t.call(e, r, e) !== n;
                      })
                    : t.nodeType
                    ? T.grep(e, function (e) {
                          return (e === t) !== n;
                      })
                    : 'string' != typeof t
                    ? T.grep(e, function (e) {
                          return l.call(t, e) > -1 !== n;
                      })
                    : T.filter(t, e, n);
            }
            (T.filter = function (e, t, n) {
                var r = t[0];
                return (
                    n && (e = ':not(' + e + ')'),
                    1 === t.length && 1 === r.nodeType
                        ? T.find.matchesSelector(r, e)
                            ? [r]
                            : []
                        : T.find.matches(
                              e,
                              T.grep(t, function (e) {
                                  return 1 === e.nodeType;
                              })
                          )
                );
            }),
                T.fn.extend({
                    find: function (e) {
                        var t,
                            n,
                            r = this.length,
                            o = this;
                        if ('string' != typeof e)
                            return this.pushStack(
                                T(e).filter(function () {
                                    for (t = 0; t < r; t++)
                                        if (T.contains(o[t], this)) return !0;
                                })
                            );
                        for (n = this.pushStack([]), t = 0; t < r; t++)
                            T.find(e, o[t], n);
                        return r > 1 ? T.uniqueSort(n) : n;
                    },
                    filter: function (e) {
                        return this.pushStack(L(this, e || [], !1));
                    },
                    not: function (e) {
                        return this.pushStack(L(this, e || [], !0));
                    },
                    is: function (e) {
                        return !!L(
                            this,
                            'string' == typeof e && N.test(e) ? T(e) : e || [],
                            !1
                        ).length;
                    },
                });
            var q,
                O = /^(?:\s*(<[\w\W]+>)[^>]*|#([\w-]+))$/;
            ((T.fn.init = function (e, t, n) {
                var r, o;
                if (!e) return this;
                if (((n = n || q), 'string' == typeof e)) {
                    if (
                        !(r =
                            '<' === e[0] &&
                            '>' === e[e.length - 1] &&
                            e.length >= 3
                                ? [null, e, null]
                                : O.exec(e)) ||
                        (!r[1] && t)
                    )
                        return !t || t.jquery
                            ? (t || n).find(e)
                            : this.constructor(t).find(e);
                    if (r[1]) {
                        if (
                            ((t = t instanceof T ? t[0] : t),
                            T.merge(
                                this,
                                T.parseHTML(
                                    r[1],
                                    t && t.nodeType ? t.ownerDocument || t : x,
                                    !0
                                )
                            ),
                            D.test(r[1]) && T.isPlainObject(t))
                        )
                            for (r in t)
                                y(this[r]) ? this[r](t[r]) : this.attr(r, t[r]);
                        return this;
                    }
                    return (
                        (o = x.getElementById(r[2])) &&
                            ((this[0] = o), (this.length = 1)),
                        this
                    );
                }
                return e.nodeType
                    ? ((this[0] = e), (this.length = 1), this)
                    : y(e)
                    ? void 0 !== n.ready
                        ? n.ready(e)
                        : e(T)
                    : T.makeArray(e, this);
            }).prototype = T.fn),
                (q = T(x));
            var H = /^(?:parents|prev(?:Until|All))/,
                P = { children: !0, contents: !0, next: !0, prev: !0 };
            function M(e, t) {
                for (; (e = e[t]) && 1 !== e.nodeType; );
                return e;
            }
            T.fn.extend({
                has: function (e) {
                    var t = T(e, this),
                        n = t.length;
                    return this.filter(function () {
                        for (var e = 0; e < n; e++)
                            if (T.contains(this, t[e])) return !0;
                    });
                },
                closest: function (e, t) {
                    var n,
                        r = 0,
                        o = this.length,
                        i = [],
                        a = 'string' != typeof e && T(e);
                    if (!N.test(e))
                        for (; r < o; r++)
                            for (n = this[r]; n && n !== t; n = n.parentNode)
                                if (
                                    n.nodeType < 11 &&
                                    (a
                                        ? a.index(n) > -1
                                        : 1 === n.nodeType &&
                                          T.find.matchesSelector(n, e))
                                ) {
                                    i.push(n);
                                    break;
                                }
                    return this.pushStack(i.length > 1 ? T.uniqueSort(i) : i);
                },
                index: function (e) {
                    return e
                        ? 'string' == typeof e
                            ? l.call(T(e), this[0])
                            : l.call(this, e.jquery ? e[0] : e)
                        : this[0] && this[0].parentNode
                        ? this.first().prevAll().length
                        : -1;
                },
                add: function (e, t) {
                    return this.pushStack(
                        T.uniqueSort(T.merge(this.get(), T(e, t)))
                    );
                },
                addBack: function (e) {
                    return this.add(
                        null == e ? this.prevObject : this.prevObject.filter(e)
                    );
                },
            }),
                T.each(
                    {
                        parent: function (e) {
                            var t = e.parentNode;
                            return t && 11 !== t.nodeType ? t : null;
                        },
                        parents: function (e) {
                            return A(e, 'parentNode');
                        },
                        parentsUntil: function (e, t, n) {
                            return A(e, 'parentNode', n);
                        },
                        next: function (e) {
                            return M(e, 'nextSibling');
                        },
                        prev: function (e) {
                            return M(e, 'previousSibling');
                        },
                        nextAll: function (e) {
                            return A(e, 'nextSibling');
                        },
                        prevAll: function (e) {
                            return A(e, 'previousSibling');
                        },
                        nextUntil: function (e, t, n) {
                            return A(e, 'nextSibling', n);
                        },
                        prevUntil: function (e, t, n) {
                            return A(e, 'previousSibling', n);
                        },
                        siblings: function (e) {
                            return E((e.parentNode || {}).firstChild, e);
                        },
                        children: function (e) {
                            return E(e.firstChild);
                        },
                        contents: function (e) {
                            return null != e.contentDocument &&
                                a(e.contentDocument)
                                ? e.contentDocument
                                : (j(e, 'template') && (e = e.content || e),
                                  T.merge([], e.childNodes));
                        },
                    },
                    function (e, t) {
                        T.fn[e] = function (n, r) {
                            var o = T.map(this, t, n);
                            return (
                                'Until' !== e.slice(-5) && (r = n),
                                r &&
                                    'string' == typeof r &&
                                    (o = T.filter(r, o)),
                                this.length > 1 &&
                                    (P[e] || T.uniqueSort(o),
                                    H.test(e) && o.reverse()),
                                this.pushStack(o)
                            );
                        };
                    }
                );
            var R = /[^\x20\t\r\n\f]+/g;
            function B(e) {
                return e;
            }
            function I(e) {
                throw e;
            }
            function F(e, t, n, r) {
                var o;
                try {
                    e && y((o = e.promise))
                        ? o.call(e).done(t).fail(n)
                        : e && y((o = e.then))
                        ? o.call(e, t, n)
                        : t.apply(void 0, [e].slice(r));
                } catch (e) {
                    n.apply(void 0, [e]);
                }
            }
            (T.Callbacks = function (e) {
                e =
                    'string' == typeof e
                        ? (function (e) {
                              var t = {};
                              return (
                                  T.each(e.match(R) || [], function (e, n) {
                                      t[n] = !0;
                                  }),
                                  t
                              );
                          })(e)
                        : T.extend({}, e);
                var t,
                    n,
                    r,
                    o,
                    i = [],
                    a = [],
                    s = -1,
                    u = function () {
                        for (o = o || e.once, r = t = !0; a.length; s = -1)
                            for (n = a.shift(); ++s < i.length; )
                                !1 === i[s].apply(n[0], n[1]) &&
                                    e.stopOnFalse &&
                                    ((s = i.length), (n = !1));
                        e.memory || (n = !1), (t = !1), o && (i = n ? [] : '');
                    },
                    c = {
                        add: function () {
                            return (
                                i &&
                                    (n && !t && ((s = i.length - 1), a.push(n)),
                                    (function t(n) {
                                        T.each(n, function (n, r) {
                                            y(r)
                                                ? (e.unique && c.has(r)) ||
                                                  i.push(r)
                                                : r &&
                                                  r.length &&
                                                  'string' !== C(r) &&
                                                  t(r);
                                        });
                                    })(arguments),
                                    n && !t && u()),
                                this
                            );
                        },
                        remove: function () {
                            return (
                                T.each(arguments, function (e, t) {
                                    for (var n; (n = T.inArray(t, i, n)) > -1; )
                                        i.splice(n, 1), n <= s && s--;
                                }),
                                this
                            );
                        },
                        has: function (e) {
                            return e ? T.inArray(e, i) > -1 : i.length > 0;
                        },
                        empty: function () {
                            return i && (i = []), this;
                        },
                        disable: function () {
                            return (o = a = []), (i = n = ''), this;
                        },
                        disabled: function () {
                            return !i;
                        },
                        lock: function () {
                            return (o = a = []), n || t || (i = n = ''), this;
                        },
                        locked: function () {
                            return !!o;
                        },
                        fireWith: function (e, n) {
                            return (
                                o ||
                                    ((n = [
                                        e,
                                        (n = n || []).slice ? n.slice() : n,
                                    ]),
                                    a.push(n),
                                    t || u()),
                                this
                            );
                        },
                        fire: function () {
                            return c.fireWith(this, arguments), this;
                        },
                        fired: function () {
                            return !!r;
                        },
                    };
                return c;
            }),
                T.extend({
                    Deferred: function (e) {
                        var t = [
                                [
                                    'notify',
                                    'progress',
                                    T.Callbacks('memory'),
                                    T.Callbacks('memory'),
                                    2,
                                ],
                                [
                                    'resolve',
                                    'done',
                                    T.Callbacks('once memory'),
                                    T.Callbacks('once memory'),
                                    0,
                                    'resolved',
                                ],
                                [
                                    'reject',
                                    'fail',
                                    T.Callbacks('once memory'),
                                    T.Callbacks('once memory'),
                                    1,
                                    'rejected',
                                ],
                            ],
                            r = 'pending',
                            o = {
                                state: function () {
                                    return r;
                                },
                                always: function () {
                                    return (
                                        i.done(arguments).fail(arguments), this
                                    );
                                },
                                catch: function (e) {
                                    return o.then(null, e);
                                },
                                pipe: function () {
                                    var e = arguments;
                                    return T.Deferred(function (n) {
                                        T.each(t, function (t, r) {
                                            var o = y(e[r[4]]) && e[r[4]];
                                            i[r[1]](function () {
                                                var e =
                                                    o &&
                                                    o.apply(this, arguments);
                                                e && y(e.promise)
                                                    ? e
                                                          .promise()
                                                          .progress(n.notify)
                                                          .done(n.resolve)
                                                          .fail(n.reject)
                                                    : n[r[0] + 'With'](
                                                          this,
                                                          o ? [e] : arguments
                                                      );
                                            });
                                        }),
                                            (e = null);
                                    }).promise();
                                },
                                then: function (e, r, o) {
                                    var i = 0;
                                    function a(e, t, r, o) {
                                        return function () {
                                            var s = this,
                                                u = arguments,
                                                c = function () {
                                                    var n, c;
                                                    if (!(e < i)) {
                                                        if (
                                                            (n = r.apply(
                                                                s,
                                                                u
                                                            )) === t.promise()
                                                        )
                                                            throw new TypeError(
                                                                'Thenable self-resolution'
                                                            );
                                                        (c =
                                                            n &&
                                                            ('object' ==
                                                                typeof n ||
                                                                'function' ==
                                                                    typeof n) &&
                                                            n.then),
                                                            y(c)
                                                                ? o
                                                                    ? c.call(
                                                                          n,
                                                                          a(
                                                                              i,
                                                                              t,
                                                                              B,
                                                                              o
                                                                          ),
                                                                          a(
                                                                              i,
                                                                              t,
                                                                              I,
                                                                              o
                                                                          )
                                                                      )
                                                                    : (i++,
                                                                      c.call(
                                                                          n,
                                                                          a(
                                                                              i,
                                                                              t,
                                                                              B,
                                                                              o
                                                                          ),
                                                                          a(
                                                                              i,
                                                                              t,
                                                                              I,
                                                                              o
                                                                          ),
                                                                          a(
                                                                              i,
                                                                              t,
                                                                              B,
                                                                              t.notifyWith
                                                                          )
                                                                      ))
                                                                : (r !== B &&
                                                                      ((s =
                                                                          void 0),
                                                                      (u = [
                                                                          n,
                                                                      ])),
                                                                  (
                                                                      o ||
                                                                      t.resolveWith
                                                                  )(s, u));
                                                    }
                                                },
                                                l = o
                                                    ? c
                                                    : function () {
                                                          try {
                                                              c();
                                                          } catch (n) {
                                                              T.Deferred
                                                                  .exceptionHook &&
                                                                  T.Deferred.exceptionHook(
                                                                      n,
                                                                      l.stackTrace
                                                                  ),
                                                                  e + 1 >= i &&
                                                                      (r !==
                                                                          I &&
                                                                          ((s =
                                                                              void 0),
                                                                          (u = [
                                                                              n,
                                                                          ])),
                                                                      t.rejectWith(
                                                                          s,
                                                                          u
                                                                      ));
                                                          }
                                                      };
                                            e
                                                ? l()
                                                : (T.Deferred.getStackHook &&
                                                      (l.stackTrace =
                                                          T.Deferred.getStackHook()),
                                                  n.setTimeout(l));
                                        };
                                    }
                                    return T.Deferred(function (n) {
                                        t[0][3].add(
                                            a(0, n, y(o) ? o : B, n.notifyWith)
                                        ),
                                            t[1][3].add(a(0, n, y(e) ? e : B)),
                                            t[2][3].add(a(0, n, y(r) ? r : I));
                                    }).promise();
                                },
                                promise: function (e) {
                                    return null != e ? T.extend(e, o) : o;
                                },
                            },
                            i = {};
                        return (
                            T.each(t, function (e, n) {
                                var a = n[2],
                                    s = n[5];
                                (o[n[1]] = a.add),
                                    s &&
                                        a.add(
                                            function () {
                                                r = s;
                                            },
                                            t[3 - e][2].disable,
                                            t[3 - e][3].disable,
                                            t[0][2].lock,
                                            t[0][3].lock
                                        ),
                                    a.add(n[3].fire),
                                    (i[n[0]] = function () {
                                        return (
                                            i[n[0] + 'With'](
                                                this === i ? void 0 : this,
                                                arguments
                                            ),
                                            this
                                        );
                                    }),
                                    (i[n[0] + 'With'] = a.fireWith);
                            }),
                            o.promise(i),
                            e && e.call(i, i),
                            i
                        );
                    },
                    when: function (e) {
                        var t = arguments.length,
                            n = t,
                            r = Array(n),
                            o = s.call(arguments),
                            i = T.Deferred(),
                            a = function (e) {
                                return function (n) {
                                    (r[e] = this),
                                        (o[e] =
                                            arguments.length > 1
                                                ? s.call(arguments)
                                                : n),
                                        --t || i.resolveWith(r, o);
                                };
                            };
                        if (
                            t <= 1 &&
                            (F(e, i.done(a(n)).resolve, i.reject, !t),
                            'pending' === i.state() || y(o[n] && o[n].then))
                        )
                            return i.then();
                        for (; n--; ) F(o[n], a(n), i.reject);
                        return i.promise();
                    },
                });
            var W = /^(Eval|Internal|Range|Reference|Syntax|Type|URI)Error$/;
            (T.Deferred.exceptionHook = function (e, t) {
                n.console &&
                    n.console.warn &&
                    e &&
                    W.test(e.name) &&
                    n.console.warn(
                        'jQuery.Deferred exception: ' + e.message,
                        e.stack,
                        t
                    );
            }),
                (T.readyException = function (e) {
                    n.setTimeout(function () {
                        throw e;
                    });
                });
            var $ = T.Deferred();
            function _() {
                x.removeEventListener('DOMContentLoaded', _),
                    n.removeEventListener('load', _),
                    T.ready();
            }
            (T.fn.ready = function (e) {
                return (
                    $.then(e).catch(function (e) {
                        T.readyException(e);
                    }),
                    this
                );
            }),
                T.extend({
                    isReady: !1,
                    readyWait: 1,
                    ready: function (e) {
                        (!0 === e ? --T.readyWait : T.isReady) ||
                            ((T.isReady = !0),
                            (!0 !== e && --T.readyWait > 0) ||
                                $.resolveWith(x, [T]));
                    },
                }),
                (T.ready.then = $.then),
                'complete' === x.readyState ||
                ('loading' !== x.readyState && !x.documentElement.doScroll)
                    ? n.setTimeout(T.ready)
                    : (x.addEventListener('DOMContentLoaded', _),
                      n.addEventListener('load', _));
            var z = function (e, t, n, r, o, i, a) {
                    var s = 0,
                        u = e.length,
                        c = null == n;
                    if ('object' === C(n))
                        for (s in ((o = !0), n)) z(e, t, s, n[s], !0, i, a);
                    else if (
                        void 0 !== r &&
                        ((o = !0),
                        y(r) || (a = !0),
                        c &&
                            (a
                                ? (t.call(e, r), (t = null))
                                : ((c = t),
                                  (t = function (e, t, n) {
                                      return c.call(T(e), n);
                                  }))),
                        t)
                    )
                        for (; s < u; s++)
                            t(e[s], n, a ? r : r.call(e[s], s, t(e[s], n)));
                    return o ? e : c ? t.call(e) : u ? t(e[0], n) : i;
                },
                U = /^-ms-/,
                X = /-([a-z])/g;
            function V(e, t) {
                return t.toUpperCase();
            }
            function G(e) {
                return e.replace(U, 'ms-').replace(X, V);
            }
            var J = function (e) {
                return 1 === e.nodeType || 9 === e.nodeType || !+e.nodeType;
            };
            function Y() {
                this.expando = T.expando + Y.uid++;
            }
            (Y.uid = 1),
                (Y.prototype = {
                    cache: function (e) {
                        var t = e[this.expando];
                        return (
                            t ||
                                ((t = {}),
                                J(e) &&
                                    (e.nodeType
                                        ? (e[this.expando] = t)
                                        : Object.defineProperty(
                                              e,
                                              this.expando,
                                              { value: t, configurable: !0 }
                                          ))),
                            t
                        );
                    },
                    set: function (e, t, n) {
                        var r,
                            o = this.cache(e);
                        if ('string' == typeof t) o[G(t)] = n;
                        else for (r in t) o[G(r)] = t[r];
                        return o;
                    },
                    get: function (e, t) {
                        return void 0 === t
                            ? this.cache(e)
                            : e[this.expando] && e[this.expando][G(t)];
                    },
                    access: function (e, t, n) {
                        return void 0 === t ||
                            (t && 'string' == typeof t && void 0 === n)
                            ? this.get(e, t)
                            : (this.set(e, t, n), void 0 !== n ? n : t);
                    },
                    remove: function (e, t) {
                        var n,
                            r = e[this.expando];
                        if (void 0 !== r) {
                            if (void 0 !== t) {
                                n = (t = Array.isArray(t)
                                    ? t.map(G)
                                    : (t = G(t)) in r
                                    ? [t]
                                    : t.match(R) || []).length;
                                for (; n--; ) delete r[t[n]];
                            }
                            (void 0 === t || T.isEmptyObject(r)) &&
                                (e.nodeType
                                    ? (e[this.expando] = void 0)
                                    : delete e[this.expando]);
                        }
                    },
                    hasData: function (e) {
                        var t = e[this.expando];
                        return void 0 !== t && !T.isEmptyObject(t);
                    },
                });
            var Q = new Y(),
                K = new Y(),
                Z = /^(?:\{[\w\W]*\}|\[[\w\W]*\])$/,
                ee = /[A-Z]/g;
            function te(e, t, n) {
                var r;
                if (void 0 === n && 1 === e.nodeType)
                    if (
                        ((r = 'data-' + t.replace(ee, '-$&').toLowerCase()),
                        'string' == typeof (n = e.getAttribute(r)))
                    ) {
                        try {
                            n = (function (e) {
                                return (
                                    'true' === e ||
                                    ('false' !== e &&
                                        ('null' === e
                                            ? null
                                            : e === +e + ''
                                            ? +e
                                            : Z.test(e)
                                            ? JSON.parse(e)
                                            : e))
                                );
                            })(n);
                        } catch (e) {}
                        K.set(e, t, n);
                    } else n = void 0;
                return n;
            }
            T.extend({
                hasData: function (e) {
                    return K.hasData(e) || Q.hasData(e);
                },
                data: function (e, t, n) {
                    return K.access(e, t, n);
                },
                removeData: function (e, t) {
                    K.remove(e, t);
                },
                _data: function (e, t, n) {
                    return Q.access(e, t, n);
                },
                _removeData: function (e, t) {
                    Q.remove(e, t);
                },
            }),
                T.fn.extend({
                    data: function (e, t) {
                        var n,
                            r,
                            o,
                            i = this[0],
                            a = i && i.attributes;
                        if (void 0 === e) {
                            if (
                                this.length &&
                                ((o = K.get(i)),
                                1 === i.nodeType && !Q.get(i, 'hasDataAttrs'))
                            ) {
                                for (n = a.length; n--; )
                                    a[n] &&
                                        0 ===
                                            (r = a[n].name).indexOf('data-') &&
                                        ((r = G(r.slice(5))), te(i, r, o[r]));
                                Q.set(i, 'hasDataAttrs', !0);
                            }
                            return o;
                        }
                        return 'object' == typeof e
                            ? this.each(function () {
                                  K.set(this, e);
                              })
                            : z(
                                  this,
                                  function (t) {
                                      var n;
                                      if (i && void 0 === t)
                                          return void 0 !== (n = K.get(i, e)) ||
                                              void 0 !== (n = te(i, e))
                                              ? n
                                              : void 0;
                                      this.each(function () {
                                          K.set(this, e, t);
                                      });
                                  },
                                  null,
                                  t,
                                  arguments.length > 1,
                                  null,
                                  !0
                              );
                    },
                    removeData: function (e) {
                        return this.each(function () {
                            K.remove(this, e);
                        });
                    },
                }),
                T.extend({
                    queue: function (e, t, n) {
                        var r;
                        if (e)
                            return (
                                (t = (t || 'fx') + 'queue'),
                                (r = Q.get(e, t)),
                                n &&
                                    (!r || Array.isArray(n)
                                        ? (r = Q.access(e, t, T.makeArray(n)))
                                        : r.push(n)),
                                r || []
                            );
                    },
                    dequeue: function (e, t) {
                        t = t || 'fx';
                        var n = T.queue(e, t),
                            r = n.length,
                            o = n.shift(),
                            i = T._queueHooks(e, t);
                        'inprogress' === o && ((o = n.shift()), r--),
                            o &&
                                ('fx' === t && n.unshift('inprogress'),
                                delete i.stop,
                                o.call(
                                    e,
                                    function () {
                                        T.dequeue(e, t);
                                    },
                                    i
                                )),
                            !r && i && i.empty.fire();
                    },
                    _queueHooks: function (e, t) {
                        var n = t + 'queueHooks';
                        return (
                            Q.get(e, n) ||
                            Q.access(e, n, {
                                empty: T.Callbacks('once memory').add(
                                    function () {
                                        Q.remove(e, [t + 'queue', n]);
                                    }
                                ),
                            })
                        );
                    },
                }),
                T.fn.extend({
                    queue: function (e, t) {
                        var n = 2;
                        return (
                            'string' != typeof e && ((t = e), (e = 'fx'), n--),
                            arguments.length < n
                                ? T.queue(this[0], e)
                                : void 0 === t
                                ? this
                                : this.each(function () {
                                      var n = T.queue(this, e, t);
                                      T._queueHooks(this, e),
                                          'fx' === e &&
                                              'inprogress' !== n[0] &&
                                              T.dequeue(this, e);
                                  })
                        );
                    },
                    dequeue: function (e) {
                        return this.each(function () {
                            T.dequeue(this, e);
                        });
                    },
                    clearQueue: function (e) {
                        return this.queue(e || 'fx', []);
                    },
                    promise: function (e, t) {
                        var n,
                            r = 1,
                            o = T.Deferred(),
                            i = this,
                            a = this.length,
                            s = function () {
                                --r || o.resolveWith(i, [i]);
                            };
                        for (
                            'string' != typeof e && ((t = e), (e = void 0)),
                                e = e || 'fx';
                            a--;

                        )
                            (n = Q.get(i[a], e + 'queueHooks')) &&
                                n.empty &&
                                (r++, n.empty.add(s));
                        return s(), o.promise(t);
                    },
                });
            var ne = /[+-]?(?:\d*\.|)\d+(?:[eE][+-]?\d+|)/.source,
                re = new RegExp('^(?:([+-])=|)(' + ne + ')([a-z%]*)$', 'i'),
                oe = ['Top', 'Right', 'Bottom', 'Left'],
                ie = x.documentElement,
                ae = function (e) {
                    return T.contains(e.ownerDocument, e);
                },
                se = { composed: !0 };
            ie.getRootNode &&
                (ae = function (e) {
                    return (
                        T.contains(e.ownerDocument, e) ||
                        e.getRootNode(se) === e.ownerDocument
                    );
                });
            var ue = function (e, t) {
                return (
                    'none' === (e = t || e).style.display ||
                    ('' === e.style.display &&
                        ae(e) &&
                        'none' === T.css(e, 'display'))
                );
            };
            function ce(e, t, n, r) {
                var o,
                    i,
                    a = 20,
                    s = r
                        ? function () {
                              return r.cur();
                          }
                        : function () {
                              return T.css(e, t, '');
                          },
                    u = s(),
                    c = (n && n[3]) || (T.cssNumber[t] ? '' : 'px'),
                    l =
                        e.nodeType &&
                        (T.cssNumber[t] || ('px' !== c && +u)) &&
                        re.exec(T.css(e, t));
                if (l && l[3] !== c) {
                    for (u /= 2, c = c || l[3], l = +u || 1; a--; )
                        T.style(e, t, l + c),
                            (1 - i) * (1 - (i = s() / u || 0.5)) <= 0 &&
                                (a = 0),
                            (l /= i);
                    (l *= 2), T.style(e, t, l + c), (n = n || []);
                }
                return (
                    n &&
                        ((l = +l || +u || 0),
                        (o = n[1] ? l + (n[1] + 1) * n[2] : +n[2]),
                        r && ((r.unit = c), (r.start = l), (r.end = o))),
                    o
                );
            }
            var le = {};
            function fe(e) {
                var t,
                    n = e.ownerDocument,
                    r = e.nodeName,
                    o = le[r];
                return (
                    o ||
                    ((t = n.body.appendChild(n.createElement(r))),
                    (o = T.css(t, 'display')),
                    t.parentNode.removeChild(t),
                    'none' === o && (o = 'block'),
                    (le[r] = o),
                    o)
                );
            }
            function pe(e, t) {
                for (var n, r, o = [], i = 0, a = e.length; i < a; i++)
                    (r = e[i]).style &&
                        ((n = r.style.display),
                        t
                            ? ('none' === n &&
                                  ((o[i] = Q.get(r, 'display') || null),
                                  o[i] || (r.style.display = '')),
                              '' === r.style.display && ue(r) && (o[i] = fe(r)))
                            : 'none' !== n &&
                              ((o[i] = 'none'), Q.set(r, 'display', n)));
                for (i = 0; i < a; i++)
                    null != o[i] && (e[i].style.display = o[i]);
                return e;
            }
            T.fn.extend({
                show: function () {
                    return pe(this, !0);
                },
                hide: function () {
                    return pe(this);
                },
                toggle: function (e) {
                    return 'boolean' == typeof e
                        ? e
                            ? this.show()
                            : this.hide()
                        : this.each(function () {
                              ue(this) ? T(this).show() : T(this).hide();
                          });
                },
            });
            var de,
                he,
                ge = /^(?:checkbox|radio)$/i,
                ve = /<([a-z][^\/\0>\x20\t\r\n\f]*)/i,
                ye = /^$|^module$|\/(?:java|ecma)script/i;
            (de = x
                .createDocumentFragment()
                .appendChild(x.createElement('div'))),
                (he = x.createElement('input')).setAttribute('type', 'radio'),
                he.setAttribute('checked', 'checked'),
                he.setAttribute('name', 't'),
                de.appendChild(he),
                (v.checkClone = de
                    .cloneNode(!0)
                    .cloneNode(!0).lastChild.checked),
                (de.innerHTML = '<textarea>x</textarea>'),
                (v.noCloneChecked = !!de.cloneNode(!0).lastChild.defaultValue),
                (de.innerHTML = '<option></option>'),
                (v.option = !!de.lastChild);
            var me = {
                thead: [1, '<table>', '</table>'],
                col: [2, '<table><colgroup>', '</colgroup></table>'],
                tr: [2, '<table><tbody>', '</tbody></table>'],
                td: [3, '<table><tbody><tr>', '</tr></tbody></table>'],
                _default: [0, '', ''],
            };
            function xe(e, t) {
                var n;
                return (
                    (n =
                        void 0 !== e.getElementsByTagName
                            ? e.getElementsByTagName(t || '*')
                            : void 0 !== e.querySelectorAll
                            ? e.querySelectorAll(t || '*')
                            : []),
                    void 0 === t || (t && j(e, t)) ? T.merge([e], n) : n
                );
            }
            function be(e, t) {
                for (var n = 0, r = e.length; n < r; n++)
                    Q.set(e[n], 'globalEval', !t || Q.get(t[n], 'globalEval'));
            }
            (me.tbody = me.tfoot = me.colgroup = me.caption = me.thead),
                (me.th = me.td),
                v.option ||
                    (me.optgroup = me.option =
                        [1, "<select multiple='multiple'>", '</select>']);
            var we = /<|&#?\w+;/;
            function Ce(e, t, n, r, o) {
                for (
                    var i,
                        a,
                        s,
                        u,
                        c,
                        l,
                        f = t.createDocumentFragment(),
                        p = [],
                        d = 0,
                        h = e.length;
                    d < h;
                    d++
                )
                    if ((i = e[d]) || 0 === i)
                        if ('object' === C(i)) T.merge(p, i.nodeType ? [i] : i);
                        else if (we.test(i)) {
                            for (
                                a = a || f.appendChild(t.createElement('div')),
                                    s = (ve.exec(i) || [
                                        '',
                                        '',
                                    ])[1].toLowerCase(),
                                    u = me[s] || me._default,
                                    a.innerHTML =
                                        u[1] + T.htmlPrefilter(i) + u[2],
                                    l = u[0];
                                l--;

                            )
                                a = a.lastChild;
                            T.merge(p, a.childNodes),
                                ((a = f.firstChild).textContent = '');
                        } else p.push(t.createTextNode(i));
                for (f.textContent = '', d = 0; (i = p[d++]); )
                    if (r && T.inArray(i, r) > -1) o && o.push(i);
                    else if (
                        ((c = ae(i)),
                        (a = xe(f.appendChild(i), 'script')),
                        c && be(a),
                        n)
                    )
                        for (l = 0; (i = a[l++]); )
                            ye.test(i.type || '') && n.push(i);
                return f;
            }
            var Te = /^([^.]*)(?:\.(.+)|)/;
            function ke() {
                return !0;
            }
            function Se() {
                return !1;
            }
            function Ae(e, t) {
                return (
                    (e ===
                        (function () {
                            try {
                                return x.activeElement;
                            } catch (e) {}
                        })()) ==
                    ('focus' === t)
                );
            }
            function Ee(e, t, n, r, o, i) {
                var a, s;
                if ('object' == typeof t) {
                    for (s in ('string' != typeof n &&
                        ((r = r || n), (n = void 0)),
                    t))
                        Ee(e, s, n, r, t[s], i);
                    return e;
                }
                if (
                    (null == r && null == o
                        ? ((o = n), (r = n = void 0))
                        : null == o &&
                          ('string' == typeof n
                              ? ((o = r), (r = void 0))
                              : ((o = r), (r = n), (n = void 0))),
                    !1 === o)
                )
                    o = Se;
                else if (!o) return e;
                return (
                    1 === i &&
                        ((a = o),
                        ((o = function (e) {
                            return T().off(e), a.apply(this, arguments);
                        }).guid = a.guid || (a.guid = T.guid++))),
                    e.each(function () {
                        T.event.add(this, t, o, r, n);
                    })
                );
            }
            function Ne(e, t, n) {
                n
                    ? (Q.set(e, t, !1),
                      T.event.add(e, t, {
                          namespace: !1,
                          handler: function (e) {
                              var r,
                                  o,
                                  i = Q.get(this, t);
                              if (1 & e.isTrigger && this[t]) {
                                  if (i.length)
                                      (T.event.special[t] || {}).delegateType &&
                                          e.stopPropagation();
                                  else if (
                                      ((i = s.call(arguments)),
                                      Q.set(this, t, i),
                                      (r = n(this, t)),
                                      this[t](),
                                      i !== (o = Q.get(this, t)) || r
                                          ? Q.set(this, t, !1)
                                          : (o = {}),
                                      i !== o)
                                  )
                                      return (
                                          e.stopImmediatePropagation(),
                                          e.preventDefault(),
                                          o && o.value
                                      );
                              } else
                                  i.length &&
                                      (Q.set(this, t, {
                                          value: T.event.trigger(
                                              T.extend(i[0], T.Event.prototype),
                                              i.slice(1),
                                              this
                                          ),
                                      }),
                                      e.stopImmediatePropagation());
                          },
                      }))
                    : void 0 === Q.get(e, t) && T.event.add(e, t, ke);
            }
            (T.event = {
                global: {},
                add: function (e, t, n, r, o) {
                    var i,
                        a,
                        s,
                        u,
                        c,
                        l,
                        f,
                        p,
                        d,
                        h,
                        g,
                        v = Q.get(e);
                    if (J(e))
                        for (
                            n.handler &&
                                ((n = (i = n).handler), (o = i.selector)),
                                o && T.find.matchesSelector(ie, o),
                                n.guid || (n.guid = T.guid++),
                                (u = v.events) ||
                                    (u = v.events = Object.create(null)),
                                (a = v.handle) ||
                                    (a = v.handle =
                                        function (t) {
                                            return void 0 !== T &&
                                                T.event.triggered !== t.type
                                                ? T.event.dispatch.apply(
                                                      e,
                                                      arguments
                                                  )
                                                : void 0;
                                        }),
                                c = (t = (t || '').match(R) || ['']).length;
                            c--;

                        )
                            (d = g = (s = Te.exec(t[c]) || [])[1]),
                                (h = (s[2] || '').split('.').sort()),
                                d &&
                                    ((f = T.event.special[d] || {}),
                                    (d =
                                        (o ? f.delegateType : f.bindType) || d),
                                    (f = T.event.special[d] || {}),
                                    (l = T.extend(
                                        {
                                            type: d,
                                            origType: g,
                                            data: r,
                                            handler: n,
                                            guid: n.guid,
                                            selector: o,
                                            needsContext:
                                                o &&
                                                T.expr.match.needsContext.test(
                                                    o
                                                ),
                                            namespace: h.join('.'),
                                        },
                                        i
                                    )),
                                    (p = u[d]) ||
                                        (((p = u[d] = []).delegateCount = 0),
                                        (f.setup &&
                                            !1 !== f.setup.call(e, r, h, a)) ||
                                            (e.addEventListener &&
                                                e.addEventListener(d, a))),
                                    f.add &&
                                        (f.add.call(e, l),
                                        l.handler.guid ||
                                            (l.handler.guid = n.guid)),
                                    o
                                        ? p.splice(p.delegateCount++, 0, l)
                                        : p.push(l),
                                    (T.event.global[d] = !0));
                },
                remove: function (e, t, n, r, o) {
                    var i,
                        a,
                        s,
                        u,
                        c,
                        l,
                        f,
                        p,
                        d,
                        h,
                        g,
                        v = Q.hasData(e) && Q.get(e);
                    if (v && (u = v.events)) {
                        for (c = (t = (t || '').match(R) || ['']).length; c--; )
                            if (
                                ((d = g = (s = Te.exec(t[c]) || [])[1]),
                                (h = (s[2] || '').split('.').sort()),
                                d)
                            ) {
                                for (
                                    f = T.event.special[d] || {},
                                        p =
                                            u[
                                                (d =
                                                    (r
                                                        ? f.delegateType
                                                        : f.bindType) || d)
                                            ] || [],
                                        s =
                                            s[2] &&
                                            new RegExp(
                                                '(^|\\.)' +
                                                    h.join('\\.(?:.*\\.|)') +
                                                    '(\\.|$)'
                                            ),
                                        a = i = p.length;
                                    i--;

                                )
                                    (l = p[i]),
                                        (!o && g !== l.origType) ||
                                            (n && n.guid !== l.guid) ||
                                            (s && !s.test(l.namespace)) ||
                                            (r &&
                                                r !== l.selector &&
                                                ('**' !== r || !l.selector)) ||
                                            (p.splice(i, 1),
                                            l.selector && p.delegateCount--,
                                            f.remove && f.remove.call(e, l));
                                a &&
                                    !p.length &&
                                    ((f.teardown &&
                                        !1 !==
                                            f.teardown.call(e, h, v.handle)) ||
                                        T.removeEvent(e, d, v.handle),
                                    delete u[d]);
                            } else
                                for (d in u)
                                    T.event.remove(e, d + t[c], n, r, !0);
                        T.isEmptyObject(u) && Q.remove(e, 'handle events');
                    }
                },
                dispatch: function (e) {
                    var t,
                        n,
                        r,
                        o,
                        i,
                        a,
                        s = new Array(arguments.length),
                        u = T.event.fix(e),
                        c =
                            (Q.get(this, 'events') || Object.create(null))[
                                u.type
                            ] || [],
                        l = T.event.special[u.type] || {};
                    for (s[0] = u, t = 1; t < arguments.length; t++)
                        s[t] = arguments[t];
                    if (
                        ((u.delegateTarget = this),
                        !l.preDispatch || !1 !== l.preDispatch.call(this, u))
                    ) {
                        for (
                            a = T.event.handlers.call(this, u, c), t = 0;
                            (o = a[t++]) && !u.isPropagationStopped();

                        )
                            for (
                                u.currentTarget = o.elem, n = 0;
                                (i = o.handlers[n++]) &&
                                !u.isImmediatePropagationStopped();

                            )
                                (u.rnamespace &&
                                    !1 !== i.namespace &&
                                    !u.rnamespace.test(i.namespace)) ||
                                    ((u.handleObj = i),
                                    (u.data = i.data),
                                    void 0 !==
                                        (r = (
                                            (T.event.special[i.origType] || {})
                                                .handle || i.handler
                                        ).apply(o.elem, s)) &&
                                        !1 === (u.result = r) &&
                                        (u.preventDefault(),
                                        u.stopPropagation()));
                        return (
                            l.postDispatch && l.postDispatch.call(this, u),
                            u.result
                        );
                    }
                },
                handlers: function (e, t) {
                    var n,
                        r,
                        o,
                        i,
                        a,
                        s = [],
                        u = t.delegateCount,
                        c = e.target;
                    if (
                        u &&
                        c.nodeType &&
                        !('click' === e.type && e.button >= 1)
                    )
                        for (; c !== this; c = c.parentNode || this)
                            if (
                                1 === c.nodeType &&
                                ('click' !== e.type || !0 !== c.disabled)
                            ) {
                                for (i = [], a = {}, n = 0; n < u; n++)
                                    void 0 ===
                                        a[(o = (r = t[n]).selector + ' ')] &&
                                        (a[o] = r.needsContext
                                            ? T(o, this).index(c) > -1
                                            : T.find(o, this, null, [c])
                                                  .length),
                                        a[o] && i.push(r);
                                i.length && s.push({ elem: c, handlers: i });
                            }
                    return (
                        (c = this),
                        u < t.length &&
                            s.push({ elem: c, handlers: t.slice(u) }),
                        s
                    );
                },
                addProp: function (e, t) {
                    Object.defineProperty(T.Event.prototype, e, {
                        enumerable: !0,
                        configurable: !0,
                        get: y(t)
                            ? function () {
                                  if (this.originalEvent)
                                      return t(this.originalEvent);
                              }
                            : function () {
                                  if (this.originalEvent)
                                      return this.originalEvent[e];
                              },
                        set: function (t) {
                            Object.defineProperty(this, e, {
                                enumerable: !0,
                                configurable: !0,
                                writable: !0,
                                value: t,
                            });
                        },
                    });
                },
                fix: function (e) {
                    return e[T.expando] ? e : new T.Event(e);
                },
                special: {
                    load: { noBubble: !0 },
                    click: {
                        setup: function (e) {
                            var t = this || e;
                            return (
                                ge.test(t.type) &&
                                    t.click &&
                                    j(t, 'input') &&
                                    Ne(t, 'click', ke),
                                !1
                            );
                        },
                        trigger: function (e) {
                            var t = this || e;
                            return (
                                ge.test(t.type) &&
                                    t.click &&
                                    j(t, 'input') &&
                                    Ne(t, 'click'),
                                !0
                            );
                        },
                        _default: function (e) {
                            var t = e.target;
                            return (
                                (ge.test(t.type) &&
                                    t.click &&
                                    j(t, 'input') &&
                                    Q.get(t, 'click')) ||
                                j(t, 'a')
                            );
                        },
                    },
                    beforeunload: {
                        postDispatch: function (e) {
                            void 0 !== e.result &&
                                e.originalEvent &&
                                (e.originalEvent.returnValue = e.result);
                        },
                    },
                },
            }),
                (T.removeEvent = function (e, t, n) {
                    e.removeEventListener && e.removeEventListener(t, n);
                }),
                (T.Event = function (e, t) {
                    if (!(this instanceof T.Event)) return new T.Event(e, t);
                    e && e.type
                        ? ((this.originalEvent = e),
                          (this.type = e.type),
                          (this.isDefaultPrevented =
                              e.defaultPrevented ||
                              (void 0 === e.defaultPrevented &&
                                  !1 === e.returnValue)
                                  ? ke
                                  : Se),
                          (this.target =
                              e.target && 3 === e.target.nodeType
                                  ? e.target.parentNode
                                  : e.target),
                          (this.currentTarget = e.currentTarget),
                          (this.relatedTarget = e.relatedTarget))
                        : (this.type = e),
                        t && T.extend(this, t),
                        (this.timeStamp = (e && e.timeStamp) || Date.now()),
                        (this[T.expando] = !0);
                }),
                (T.Event.prototype = {
                    constructor: T.Event,
                    isDefaultPrevented: Se,
                    isPropagationStopped: Se,
                    isImmediatePropagationStopped: Se,
                    isSimulated: !1,
                    preventDefault: function () {
                        var e = this.originalEvent;
                        (this.isDefaultPrevented = ke),
                            e && !this.isSimulated && e.preventDefault();
                    },
                    stopPropagation: function () {
                        var e = this.originalEvent;
                        (this.isPropagationStopped = ke),
                            e && !this.isSimulated && e.stopPropagation();
                    },
                    stopImmediatePropagation: function () {
                        var e = this.originalEvent;
                        (this.isImmediatePropagationStopped = ke),
                            e &&
                                !this.isSimulated &&
                                e.stopImmediatePropagation(),
                            this.stopPropagation();
                    },
                }),
                T.each(
                    {
                        altKey: !0,
                        bubbles: !0,
                        cancelable: !0,
                        changedTouches: !0,
                        ctrlKey: !0,
                        detail: !0,
                        eventPhase: !0,
                        metaKey: !0,
                        pageX: !0,
                        pageY: !0,
                        shiftKey: !0,
                        view: !0,
                        char: !0,
                        code: !0,
                        charCode: !0,
                        key: !0,
                        keyCode: !0,
                        button: !0,
                        buttons: !0,
                        clientX: !0,
                        clientY: !0,
                        offsetX: !0,
                        offsetY: !0,
                        pointerId: !0,
                        pointerType: !0,
                        screenX: !0,
                        screenY: !0,
                        targetTouches: !0,
                        toElement: !0,
                        touches: !0,
                        which: !0,
                    },
                    T.event.addProp
                ),
                T.each({ focus: 'focusin', blur: 'focusout' }, function (e, t) {
                    T.event.special[e] = {
                        setup: function () {
                            return Ne(this, e, Ae), !1;
                        },
                        trigger: function () {
                            return Ne(this, e), !0;
                        },
                        _default: function (t) {
                            return Q.get(t.target, e);
                        },
                        delegateType: t,
                    };
                }),
                T.each(
                    {
                        mouseenter: 'mouseover',
                        mouseleave: 'mouseout',
                        pointerenter: 'pointerover',
                        pointerleave: 'pointerout',
                    },
                    function (e, t) {
                        T.event.special[e] = {
                            delegateType: t,
                            bindType: t,
                            handle: function (e) {
                                var n,
                                    r = this,
                                    o = e.relatedTarget,
                                    i = e.handleObj;
                                return (
                                    (o && (o === r || T.contains(r, o))) ||
                                        ((e.type = i.origType),
                                        (n = i.handler.apply(this, arguments)),
                                        (e.type = t)),
                                    n
                                );
                            },
                        };
                    }
                ),
                T.fn.extend({
                    on: function (e, t, n, r) {
                        return Ee(this, e, t, n, r);
                    },
                    one: function (e, t, n, r) {
                        return Ee(this, e, t, n, r, 1);
                    },
                    off: function (e, t, n) {
                        var r, o;
                        if (e && e.preventDefault && e.handleObj)
                            return (
                                (r = e.handleObj),
                                T(e.delegateTarget).off(
                                    r.namespace
                                        ? r.origType + '.' + r.namespace
                                        : r.origType,
                                    r.selector,
                                    r.handler
                                ),
                                this
                            );
                        if ('object' == typeof e) {
                            for (o in e) this.off(o, t, e[o]);
                            return this;
                        }
                        return (
                            (!1 !== t && 'function' != typeof t) ||
                                ((n = t), (t = void 0)),
                            !1 === n && (n = Se),
                            this.each(function () {
                                T.event.remove(this, e, n, t);
                            })
                        );
                    },
                });
            var je = /<script|<style|<link/i,
                De = /checked\s*(?:[^=]|=\s*.checked.)/i,
                Le = /^\s*<!\[CDATA\[|\]\]>\s*$/g;
            function qe(e, t) {
                return (
                    (j(e, 'table') &&
                        j(11 !== t.nodeType ? t : t.firstChild, 'tr') &&
                        T(e).children('tbody')[0]) ||
                    e
                );
            }
            function Oe(e) {
                return (
                    (e.type = (null !== e.getAttribute('type')) + '/' + e.type),
                    e
                );
            }
            function He(e) {
                return (
                    'true/' === (e.type || '').slice(0, 5)
                        ? (e.type = e.type.slice(5))
                        : e.removeAttribute('type'),
                    e
                );
            }
            function Pe(e, t) {
                var n, r, o, i, a, s;
                if (1 === t.nodeType) {
                    if (Q.hasData(e) && (s = Q.get(e).events))
                        for (o in (Q.remove(t, 'handle events'), s))
                            for (n = 0, r = s[o].length; n < r; n++)
                                T.event.add(t, o, s[o][n]);
                    K.hasData(e) &&
                        ((i = K.access(e)), (a = T.extend({}, i)), K.set(t, a));
                }
            }
            function Me(e, t) {
                var n = t.nodeName.toLowerCase();
                'input' === n && ge.test(e.type)
                    ? (t.checked = e.checked)
                    : ('input' !== n && 'textarea' !== n) ||
                      (t.defaultValue = e.defaultValue);
            }
            function Re(e, t, n, r) {
                t = u(t);
                var o,
                    i,
                    a,
                    s,
                    c,
                    l,
                    f = 0,
                    p = e.length,
                    d = p - 1,
                    h = t[0],
                    g = y(h);
                if (
                    g ||
                    (p > 1 &&
                        'string' == typeof h &&
                        !v.checkClone &&
                        De.test(h))
                )
                    return e.each(function (o) {
                        var i = e.eq(o);
                        g && (t[0] = h.call(this, o, i.html())), Re(i, t, n, r);
                    });
                if (
                    p &&
                    ((i = (o = Ce(t, e[0].ownerDocument, !1, e, r)).firstChild),
                    1 === o.childNodes.length && (o = i),
                    i || r)
                ) {
                    for (
                        s = (a = T.map(xe(o, 'script'), Oe)).length;
                        f < p;
                        f++
                    )
                        (c = o),
                            f !== d &&
                                ((c = T.clone(c, !0, !0)),
                                s && T.merge(a, xe(c, 'script'))),
                            n.call(e[f], c, f);
                    if (s)
                        for (
                            l = a[a.length - 1].ownerDocument,
                                T.map(a, He),
                                f = 0;
                            f < s;
                            f++
                        )
                            (c = a[f]),
                                ye.test(c.type || '') &&
                                    !Q.access(c, 'globalEval') &&
                                    T.contains(l, c) &&
                                    (c.src &&
                                    'module' !== (c.type || '').toLowerCase()
                                        ? T._evalUrl &&
                                          !c.noModule &&
                                          T._evalUrl(
                                              c.src,
                                              {
                                                  nonce:
                                                      c.nonce ||
                                                      c.getAttribute('nonce'),
                                              },
                                              l
                                          )
                                        : w(
                                              c.textContent.replace(Le, ''),
                                              c,
                                              l
                                          ));
                }
                return e;
            }
            function Be(e, t, n) {
                for (
                    var r, o = t ? T.filter(t, e) : e, i = 0;
                    null != (r = o[i]);
                    i++
                )
                    n || 1 !== r.nodeType || T.cleanData(xe(r)),
                        r.parentNode &&
                            (n && ae(r) && be(xe(r, 'script')),
                            r.parentNode.removeChild(r));
                return e;
            }
            T.extend({
                htmlPrefilter: function (e) {
                    return e;
                },
                clone: function (e, t, n) {
                    var r,
                        o,
                        i,
                        a,
                        s = e.cloneNode(!0),
                        u = ae(e);
                    if (
                        !(
                            v.noCloneChecked ||
                            (1 !== e.nodeType && 11 !== e.nodeType) ||
                            T.isXMLDoc(e)
                        )
                    )
                        for (
                            a = xe(s), r = 0, o = (i = xe(e)).length;
                            r < o;
                            r++
                        )
                            Me(i[r], a[r]);
                    if (t)
                        if (n)
                            for (
                                i = i || xe(e),
                                    a = a || xe(s),
                                    r = 0,
                                    o = i.length;
                                r < o;
                                r++
                            )
                                Pe(i[r], a[r]);
                        else Pe(e, s);
                    return (
                        (a = xe(s, 'script')).length > 0 &&
                            be(a, !u && xe(e, 'script')),
                        s
                    );
                },
                cleanData: function (e) {
                    for (
                        var t, n, r, o = T.event.special, i = 0;
                        void 0 !== (n = e[i]);
                        i++
                    )
                        if (J(n)) {
                            if ((t = n[Q.expando])) {
                                if (t.events)
                                    for (r in t.events)
                                        o[r]
                                            ? T.event.remove(n, r)
                                            : T.removeEvent(n, r, t.handle);
                                n[Q.expando] = void 0;
                            }
                            n[K.expando] && (n[K.expando] = void 0);
                        }
                },
            }),
                T.fn.extend({
                    detach: function (e) {
                        return Be(this, e, !0);
                    },
                    remove: function (e) {
                        return Be(this, e);
                    },
                    text: function (e) {
                        return z(
                            this,
                            function (e) {
                                return void 0 === e
                                    ? T.text(this)
                                    : this.empty().each(function () {
                                          (1 !== this.nodeType &&
                                              11 !== this.nodeType &&
                                              9 !== this.nodeType) ||
                                              (this.textContent = e);
                                      });
                            },
                            null,
                            e,
                            arguments.length
                        );
                    },
                    append: function () {
                        return Re(this, arguments, function (e) {
                            (1 !== this.nodeType &&
                                11 !== this.nodeType &&
                                9 !== this.nodeType) ||
                                qe(this, e).appendChild(e);
                        });
                    },
                    prepend: function () {
                        return Re(this, arguments, function (e) {
                            if (
                                1 === this.nodeType ||
                                11 === this.nodeType ||
                                9 === this.nodeType
                            ) {
                                var t = qe(this, e);
                                t.insertBefore(e, t.firstChild);
                            }
                        });
                    },
                    before: function () {
                        return Re(this, arguments, function (e) {
                            this.parentNode &&
                                this.parentNode.insertBefore(e, this);
                        });
                    },
                    after: function () {
                        return Re(this, arguments, function (e) {
                            this.parentNode &&
                                this.parentNode.insertBefore(
                                    e,
                                    this.nextSibling
                                );
                        });
                    },
                    empty: function () {
                        for (var e, t = 0; null != (e = this[t]); t++)
                            1 === e.nodeType &&
                                (T.cleanData(xe(e, !1)), (e.textContent = ''));
                        return this;
                    },
                    clone: function (e, t) {
                        return (
                            (e = null != e && e),
                            (t = null == t ? e : t),
                            this.map(function () {
                                return T.clone(this, e, t);
                            })
                        );
                    },
                    html: function (e) {
                        return z(
                            this,
                            function (e) {
                                var t = this[0] || {},
                                    n = 0,
                                    r = this.length;
                                if (void 0 === e && 1 === t.nodeType)
                                    return t.innerHTML;
                                if (
                                    'string' == typeof e &&
                                    !je.test(e) &&
                                    !me[
                                        (ve.exec(e) || [
                                            '',
                                            '',
                                        ])[1].toLowerCase()
                                    ]
                                ) {
                                    e = T.htmlPrefilter(e);
                                    try {
                                        for (; n < r; n++)
                                            1 ===
                                                (t = this[n] || {}).nodeType &&
                                                (T.cleanData(xe(t, !1)),
                                                (t.innerHTML = e));
                                        t = 0;
                                    } catch (e) {}
                                }
                                t && this.empty().append(e);
                            },
                            null,
                            e,
                            arguments.length
                        );
                    },
                    replaceWith: function () {
                        var e = [];
                        return Re(
                            this,
                            arguments,
                            function (t) {
                                var n = this.parentNode;
                                T.inArray(this, e) < 0 &&
                                    (T.cleanData(xe(this)),
                                    n && n.replaceChild(t, this));
                            },
                            e
                        );
                    },
                }),
                T.each(
                    {
                        appendTo: 'append',
                        prependTo: 'prepend',
                        insertBefore: 'before',
                        insertAfter: 'after',
                        replaceAll: 'replaceWith',
                    },
                    function (e, t) {
                        T.fn[e] = function (e) {
                            for (
                                var n,
                                    r = [],
                                    o = T(e),
                                    i = o.length - 1,
                                    a = 0;
                                a <= i;
                                a++
                            )
                                (n = a === i ? this : this.clone(!0)),
                                    T(o[a])[t](n),
                                    c.apply(r, n.get());
                            return this.pushStack(r);
                        };
                    }
                );
            var Ie = new RegExp('^(' + ne + ')(?!px)[a-z%]+$', 'i'),
                Fe = /^--/,
                We = function (e) {
                    var t = e.ownerDocument.defaultView;
                    return (t && t.opener) || (t = n), t.getComputedStyle(e);
                },
                $e = function (e, t, n) {
                    var r,
                        o,
                        i = {};
                    for (o in t) (i[o] = e.style[o]), (e.style[o] = t[o]);
                    for (o in ((r = n.call(e)), t)) e.style[o] = i[o];
                    return r;
                },
                _e = new RegExp(oe.join('|'), 'i'),
                ze = new RegExp(
                    '^[\\x20\\t\\r\\n\\f]+|((?:^|[^\\\\])(?:\\\\.)*)[\\x20\\t\\r\\n\\f]+$',
                    'g'
                );
            function Ue(e, t, n) {
                var r,
                    o,
                    i,
                    a,
                    s = Fe.test(t),
                    u = e.style;
                return (
                    (n = n || We(e)) &&
                        ((a = n.getPropertyValue(t) || n[t]),
                        s && (a = a.replace(ze, '$1')),
                        '' !== a || ae(e) || (a = T.style(e, t)),
                        !v.pixelBoxStyles() &&
                            Ie.test(a) &&
                            _e.test(t) &&
                            ((r = u.width),
                            (o = u.minWidth),
                            (i = u.maxWidth),
                            (u.minWidth = u.maxWidth = u.width = a),
                            (a = n.width),
                            (u.width = r),
                            (u.minWidth = o),
                            (u.maxWidth = i))),
                    void 0 !== a ? a + '' : a
                );
            }
            function Xe(e, t) {
                return {
                    get: function () {
                        if (!e()) return (this.get = t).apply(this, arguments);
                        delete this.get;
                    },
                };
            }
            !(function () {
                function e() {
                    if (l) {
                        (c.style.cssText =
                            'position:absolute;left:-11111px;width:60px;margin-top:1px;padding:0;border:0'),
                            (l.style.cssText =
                                'position:relative;display:block;box-sizing:border-box;overflow:scroll;margin:auto;border:1px;padding:1px;width:60%;top:1%'),
                            ie.appendChild(c).appendChild(l);
                        var e = n.getComputedStyle(l);
                        (r = '1%' !== e.top),
                            (u = 12 === t(e.marginLeft)),
                            (l.style.right = '60%'),
                            (a = 36 === t(e.right)),
                            (o = 36 === t(e.width)),
                            (l.style.position = 'absolute'),
                            (i = 12 === t(l.offsetWidth / 3)),
                            ie.removeChild(c),
                            (l = null);
                    }
                }
                function t(e) {
                    return Math.round(parseFloat(e));
                }
                var r,
                    o,
                    i,
                    a,
                    s,
                    u,
                    c = x.createElement('div'),
                    l = x.createElement('div');
                l.style &&
                    ((l.style.backgroundClip = 'content-box'),
                    (l.cloneNode(!0).style.backgroundClip = ''),
                    (v.clearCloneStyle =
                        'content-box' === l.style.backgroundClip),
                    T.extend(v, {
                        boxSizingReliable: function () {
                            return e(), o;
                        },
                        pixelBoxStyles: function () {
                            return e(), a;
                        },
                        pixelPosition: function () {
                            return e(), r;
                        },
                        reliableMarginLeft: function () {
                            return e(), u;
                        },
                        scrollboxSize: function () {
                            return e(), i;
                        },
                        reliableTrDimensions: function () {
                            var e, t, r, o;
                            return (
                                null == s &&
                                    ((e = x.createElement('table')),
                                    (t = x.createElement('tr')),
                                    (r = x.createElement('div')),
                                    (e.style.cssText =
                                        'position:absolute;left:-11111px;border-collapse:separate'),
                                    (t.style.cssText = 'border:1px solid'),
                                    (t.style.height = '1px'),
                                    (r.style.height = '9px'),
                                    (r.style.display = 'block'),
                                    ie
                                        .appendChild(e)
                                        .appendChild(t)
                                        .appendChild(r),
                                    (o = n.getComputedStyle(t)),
                                    (s =
                                        parseInt(o.height, 10) +
                                            parseInt(o.borderTopWidth, 10) +
                                            parseInt(
                                                o.borderBottomWidth,
                                                10
                                            ) ===
                                        t.offsetHeight),
                                    ie.removeChild(e)),
                                s
                            );
                        },
                    }));
            })();
            var Ve = ['Webkit', 'Moz', 'ms'],
                Ge = x.createElement('div').style,
                Je = {};
            function Ye(e) {
                var t = T.cssProps[e] || Je[e];
                return (
                    t ||
                    (e in Ge
                        ? e
                        : (Je[e] =
                              (function (e) {
                                  for (
                                      var t = e[0].toUpperCase() + e.slice(1),
                                          n = Ve.length;
                                      n--;

                                  )
                                      if ((e = Ve[n] + t) in Ge) return e;
                              })(e) || e))
                );
            }
            var Qe = /^(none|table(?!-c[ea]).+)/,
                Ke = {
                    position: 'absolute',
                    visibility: 'hidden',
                    display: 'block',
                },
                Ze = { letterSpacing: '0', fontWeight: '400' };
            function et(e, t, n) {
                var r = re.exec(t);
                return r ? Math.max(0, r[2] - (n || 0)) + (r[3] || 'px') : t;
            }
            function tt(e, t, n, r, o, i) {
                var a = 'width' === t ? 1 : 0,
                    s = 0,
                    u = 0;
                if (n === (r ? 'border' : 'content')) return 0;
                for (; a < 4; a += 2)
                    'margin' === n && (u += T.css(e, n + oe[a], !0, o)),
                        r
                            ? ('content' === n &&
                                  (u -= T.css(e, 'padding' + oe[a], !0, o)),
                              'margin' !== n &&
                                  (u -= T.css(
                                      e,
                                      'border' + oe[a] + 'Width',
                                      !0,
                                      o
                                  )))
                            : ((u += T.css(e, 'padding' + oe[a], !0, o)),
                              'padding' !== n
                                  ? (u += T.css(
                                        e,
                                        'border' + oe[a] + 'Width',
                                        !0,
                                        o
                                    ))
                                  : (s += T.css(
                                        e,
                                        'border' + oe[a] + 'Width',
                                        !0,
                                        o
                                    )));
                return (
                    !r &&
                        i >= 0 &&
                        (u +=
                            Math.max(
                                0,
                                Math.ceil(
                                    e[
                                        'offset' +
                                            t[0].toUpperCase() +
                                            t.slice(1)
                                    ] -
                                        i -
                                        u -
                                        s -
                                        0.5
                                )
                            ) || 0),
                    u
                );
            }
            function nt(e, t, n) {
                var r = We(e),
                    o =
                        (!v.boxSizingReliable() || n) &&
                        'border-box' === T.css(e, 'boxSizing', !1, r),
                    i = o,
                    a = Ue(e, t, r),
                    s = 'offset' + t[0].toUpperCase() + t.slice(1);
                if (Ie.test(a)) {
                    if (!n) return a;
                    a = 'auto';
                }
                return (
                    ((!v.boxSizingReliable() && o) ||
                        (!v.reliableTrDimensions() && j(e, 'tr')) ||
                        'auto' === a ||
                        (!parseFloat(a) &&
                            'inline' === T.css(e, 'display', !1, r))) &&
                        e.getClientRects().length &&
                        ((o = 'border-box' === T.css(e, 'boxSizing', !1, r)),
                        (i = s in e) && (a = e[s])),
                    (a = parseFloat(a) || 0) +
                        tt(e, t, n || (o ? 'border' : 'content'), i, r, a) +
                        'px'
                );
            }
            function rt(e, t, n, r, o) {
                return new rt.prototype.init(e, t, n, r, o);
            }
            T.extend({
                cssHooks: {
                    opacity: {
                        get: function (e, t) {
                            if (t) {
                                var n = Ue(e, 'opacity');
                                return '' === n ? '1' : n;
                            }
                        },
                    },
                },
                cssNumber: {
                    animationIterationCount: !0,
                    columnCount: !0,
                    fillOpacity: !0,
                    flexGrow: !0,
                    flexShrink: !0,
                    fontWeight: !0,
                    gridArea: !0,
                    gridColumn: !0,
                    gridColumnEnd: !0,
                    gridColumnStart: !0,
                    gridRow: !0,
                    gridRowEnd: !0,
                    gridRowStart: !0,
                    lineHeight: !0,
                    opacity: !0,
                    order: !0,
                    orphans: !0,
                    widows: !0,
                    zIndex: !0,
                    zoom: !0,
                },
                cssProps: {},
                style: function (e, t, n, r) {
                    if (e && 3 !== e.nodeType && 8 !== e.nodeType && e.style) {
                        var o,
                            i,
                            a,
                            s = G(t),
                            u = Fe.test(t),
                            c = e.style;
                        if (
                            (u || (t = Ye(s)),
                            (a = T.cssHooks[t] || T.cssHooks[s]),
                            void 0 === n)
                        )
                            return a &&
                                'get' in a &&
                                void 0 !== (o = a.get(e, !1, r))
                                ? o
                                : c[t];
                        'string' === (i = typeof n) &&
                            (o = re.exec(n)) &&
                            o[1] &&
                            ((n = ce(e, t, o)), (i = 'number')),
                            null != n &&
                                n == n &&
                                ('number' !== i ||
                                    u ||
                                    (n +=
                                        (o && o[3]) ||
                                        (T.cssNumber[s] ? '' : 'px')),
                                v.clearCloneStyle ||
                                    '' !== n ||
                                    0 !== t.indexOf('background') ||
                                    (c[t] = 'inherit'),
                                (a &&
                                    'set' in a &&
                                    void 0 === (n = a.set(e, n, r))) ||
                                    (u ? c.setProperty(t, n) : (c[t] = n)));
                    }
                },
                css: function (e, t, n, r) {
                    var o,
                        i,
                        a,
                        s = G(t);
                    return (
                        Fe.test(t) || (t = Ye(s)),
                        (a = T.cssHooks[t] || T.cssHooks[s]) &&
                            'get' in a &&
                            (o = a.get(e, !0, n)),
                        void 0 === o && (o = Ue(e, t, r)),
                        'normal' === o && t in Ze && (o = Ze[t]),
                        '' === n || n
                            ? ((i = parseFloat(o)),
                              !0 === n || isFinite(i) ? i || 0 : o)
                            : o
                    );
                },
            }),
                T.each(['height', 'width'], function (e, t) {
                    T.cssHooks[t] = {
                        get: function (e, n, r) {
                            if (n)
                                return !Qe.test(T.css(e, 'display')) ||
                                    (e.getClientRects().length &&
                                        e.getBoundingClientRect().width)
                                    ? nt(e, t, r)
                                    : $e(e, Ke, function () {
                                          return nt(e, t, r);
                                      });
                        },
                        set: function (e, n, r) {
                            var o,
                                i = We(e),
                                a =
                                    !v.scrollboxSize() &&
                                    'absolute' === i.position,
                                s =
                                    (a || r) &&
                                    'border-box' ===
                                        T.css(e, 'boxSizing', !1, i),
                                u = r ? tt(e, t, r, s, i) : 0;
                            return (
                                s &&
                                    a &&
                                    (u -= Math.ceil(
                                        e[
                                            'offset' +
                                                t[0].toUpperCase() +
                                                t.slice(1)
                                        ] -
                                            parseFloat(i[t]) -
                                            tt(e, t, 'border', !1, i) -
                                            0.5
                                    )),
                                u &&
                                    (o = re.exec(n)) &&
                                    'px' !== (o[3] || 'px') &&
                                    ((e.style[t] = n), (n = T.css(e, t))),
                                et(0, n, u)
                            );
                        },
                    };
                }),
                (T.cssHooks.marginLeft = Xe(
                    v.reliableMarginLeft,
                    function (e, t) {
                        if (t)
                            return (
                                (parseFloat(Ue(e, 'marginLeft')) ||
                                    e.getBoundingClientRect().left -
                                        $e(e, { marginLeft: 0 }, function () {
                                            return e.getBoundingClientRect().left;
                                        })) + 'px'
                            );
                    }
                )),
                T.each(
                    { margin: '', padding: '', border: 'Width' },
                    function (e, t) {
                        (T.cssHooks[e + t] = {
                            expand: function (n) {
                                for (
                                    var r = 0,
                                        o = {},
                                        i =
                                            'string' == typeof n
                                                ? n.split(' ')
                                                : [n];
                                    r < 4;
                                    r++
                                )
                                    o[e + oe[r] + t] = i[r] || i[r - 2] || i[0];
                                return o;
                            },
                        }),
                            'margin' !== e && (T.cssHooks[e + t].set = et);
                    }
                ),
                T.fn.extend({
                    css: function (e, t) {
                        return z(
                            this,
                            function (e, t, n) {
                                var r,
                                    o,
                                    i = {},
                                    a = 0;
                                if (Array.isArray(t)) {
                                    for (r = We(e), o = t.length; a < o; a++)
                                        i[t[a]] = T.css(e, t[a], !1, r);
                                    return i;
                                }
                                return void 0 !== n
                                    ? T.style(e, t, n)
                                    : T.css(e, t);
                            },
                            e,
                            t,
                            arguments.length > 1
                        );
                    },
                }),
                (T.Tween = rt),
                (rt.prototype = {
                    constructor: rt,
                    init: function (e, t, n, r, o, i) {
                        (this.elem = e),
                            (this.prop = n),
                            (this.easing = o || T.easing._default),
                            (this.options = t),
                            (this.start = this.now = this.cur()),
                            (this.end = r),
                            (this.unit = i || (T.cssNumber[n] ? '' : 'px'));
                    },
                    cur: function () {
                        var e = rt.propHooks[this.prop];
                        return e && e.get
                            ? e.get(this)
                            : rt.propHooks._default.get(this);
                    },
                    run: function (e) {
                        var t,
                            n = rt.propHooks[this.prop];
                        return (
                            this.options.duration
                                ? (this.pos = t =
                                      T.easing[this.easing](
                                          e,
                                          this.options.duration * e,
                                          0,
                                          1,
                                          this.options.duration
                                      ))
                                : (this.pos = t = e),
                            (this.now =
                                (this.end - this.start) * t + this.start),
                            this.options.step &&
                                this.options.step.call(
                                    this.elem,
                                    this.now,
                                    this
                                ),
                            n && n.set
                                ? n.set(this)
                                : rt.propHooks._default.set(this),
                            this
                        );
                    },
                }),
                (rt.prototype.init.prototype = rt.prototype),
                (rt.propHooks = {
                    _default: {
                        get: function (e) {
                            var t;
                            return 1 !== e.elem.nodeType ||
                                (null != e.elem[e.prop] &&
                                    null == e.elem.style[e.prop])
                                ? e.elem[e.prop]
                                : (t = T.css(e.elem, e.prop, '')) &&
                                  'auto' !== t
                                ? t
                                : 0;
                        },
                        set: function (e) {
                            T.fx.step[e.prop]
                                ? T.fx.step[e.prop](e)
                                : 1 !== e.elem.nodeType ||
                                  (!T.cssHooks[e.prop] &&
                                      null == e.elem.style[Ye(e.prop)])
                                ? (e.elem[e.prop] = e.now)
                                : T.style(e.elem, e.prop, e.now + e.unit);
                        },
                    },
                }),
                (rt.propHooks.scrollTop = rt.propHooks.scrollLeft =
                    {
                        set: function (e) {
                            e.elem.nodeType &&
                                e.elem.parentNode &&
                                (e.elem[e.prop] = e.now);
                        },
                    }),
                (T.easing = {
                    linear: function (e) {
                        return e;
                    },
                    swing: function (e) {
                        return 0.5 - Math.cos(e * Math.PI) / 2;
                    },
                    _default: 'swing',
                }),
                (T.fx = rt.prototype.init),
                (T.fx.step = {});
            var ot,
                it,
                at = /^(?:toggle|show|hide)$/,
                st = /queueHooks$/;
            function ut() {
                it &&
                    (!1 === x.hidden && n.requestAnimationFrame
                        ? n.requestAnimationFrame(ut)
                        : n.setTimeout(ut, T.fx.interval),
                    T.fx.tick());
            }
            function ct() {
                return (
                    n.setTimeout(function () {
                        ot = void 0;
                    }),
                    (ot = Date.now())
                );
            }
            function lt(e, t) {
                var n,
                    r = 0,
                    o = { height: e };
                for (t = t ? 1 : 0; r < 4; r += 2 - t)
                    o['margin' + (n = oe[r])] = o['padding' + n] = e;
                return t && (o.opacity = o.width = e), o;
            }
            function ft(e, t, n) {
                for (
                    var r,
                        o = (pt.tweeners[t] || []).concat(pt.tweeners['*']),
                        i = 0,
                        a = o.length;
                    i < a;
                    i++
                )
                    if ((r = o[i].call(n, t, e))) return r;
            }
            function pt(e, t, n) {
                var r,
                    o,
                    i = 0,
                    a = pt.prefilters.length,
                    s = T.Deferred().always(function () {
                        delete u.elem;
                    }),
                    u = function () {
                        if (o) return !1;
                        for (
                            var t = ot || ct(),
                                n = Math.max(0, c.startTime + c.duration - t),
                                r = 1 - (n / c.duration || 0),
                                i = 0,
                                a = c.tweens.length;
                            i < a;
                            i++
                        )
                            c.tweens[i].run(r);
                        return (
                            s.notifyWith(e, [c, r, n]),
                            r < 1 && a
                                ? n
                                : (a || s.notifyWith(e, [c, 1, 0]),
                                  s.resolveWith(e, [c]),
                                  !1)
                        );
                    },
                    c = s.promise({
                        elem: e,
                        props: T.extend({}, t),
                        opts: T.extend(
                            !0,
                            { specialEasing: {}, easing: T.easing._default },
                            n
                        ),
                        originalProperties: t,
                        originalOptions: n,
                        startTime: ot || ct(),
                        duration: n.duration,
                        tweens: [],
                        createTween: function (t, n) {
                            var r = T.Tween(
                                e,
                                c.opts,
                                t,
                                n,
                                c.opts.specialEasing[t] || c.opts.easing
                            );
                            return c.tweens.push(r), r;
                        },
                        stop: function (t) {
                            var n = 0,
                                r = t ? c.tweens.length : 0;
                            if (o) return this;
                            for (o = !0; n < r; n++) c.tweens[n].run(1);
                            return (
                                t
                                    ? (s.notifyWith(e, [c, 1, 0]),
                                      s.resolveWith(e, [c, t]))
                                    : s.rejectWith(e, [c, t]),
                                this
                            );
                        },
                    }),
                    l = c.props;
                for (
                    !(function (e, t) {
                        var n, r, o, i, a;
                        for (n in e)
                            if (
                                ((o = t[(r = G(n))]),
                                (i = e[n]),
                                Array.isArray(i) &&
                                    ((o = i[1]), (i = e[n] = i[0])),
                                n !== r && ((e[r] = i), delete e[n]),
                                (a = T.cssHooks[r]) && ('expand' in a))
                            )
                                for (n in ((i = a.expand(i)), delete e[r], i))
                                    (n in e) || ((e[n] = i[n]), (t[n] = o));
                            else t[r] = o;
                    })(l, c.opts.specialEasing);
                    i < a;
                    i++
                )
                    if ((r = pt.prefilters[i].call(c, e, l, c.opts)))
                        return (
                            y(r.stop) &&
                                (T._queueHooks(c.elem, c.opts.queue).stop =
                                    r.stop.bind(r)),
                            r
                        );
                return (
                    T.map(l, ft, c),
                    y(c.opts.start) && c.opts.start.call(e, c),
                    c
                        .progress(c.opts.progress)
                        .done(c.opts.done, c.opts.complete)
                        .fail(c.opts.fail)
                        .always(c.opts.always),
                    T.fx.timer(
                        T.extend(u, { elem: e, anim: c, queue: c.opts.queue })
                    ),
                    c
                );
            }
            (T.Animation = T.extend(pt, {
                tweeners: {
                    '*': [
                        function (e, t) {
                            var n = this.createTween(e, t);
                            return ce(n.elem, e, re.exec(t), n), n;
                        },
                    ],
                },
                tweener: function (e, t) {
                    y(e) ? ((t = e), (e = ['*'])) : (e = e.match(R));
                    for (var n, r = 0, o = e.length; r < o; r++)
                        (n = e[r]),
                            (pt.tweeners[n] = pt.tweeners[n] || []),
                            pt.tweeners[n].unshift(t);
                },
                prefilters: [
                    function (e, t, n) {
                        var r,
                            o,
                            i,
                            a,
                            s,
                            u,
                            c,
                            l,
                            f = 'width' in t || 'height' in t,
                            p = this,
                            d = {},
                            h = e.style,
                            g = e.nodeType && ue(e),
                            v = Q.get(e, 'fxshow');
                        for (r in (n.queue ||
                            (null == (a = T._queueHooks(e, 'fx')).unqueued &&
                                ((a.unqueued = 0),
                                (s = a.empty.fire),
                                (a.empty.fire = function () {
                                    a.unqueued || s();
                                })),
                            a.unqueued++,
                            p.always(function () {
                                p.always(function () {
                                    a.unqueued--,
                                        T.queue(e, 'fx').length ||
                                            a.empty.fire();
                                });
                            })),
                        t))
                            if (((o = t[r]), at.test(o))) {
                                if (
                                    (delete t[r],
                                    (i = i || 'toggle' === o),
                                    o === (g ? 'hide' : 'show'))
                                ) {
                                    if ('show' !== o || !v || void 0 === v[r])
                                        continue;
                                    g = !0;
                                }
                                d[r] = (v && v[r]) || T.style(e, r);
                            }
                        if ((u = !T.isEmptyObject(t)) || !T.isEmptyObject(d))
                            for (r in (f &&
                                1 === e.nodeType &&
                                ((n.overflow = [
                                    h.overflow,
                                    h.overflowX,
                                    h.overflowY,
                                ]),
                                null == (c = v && v.display) &&
                                    (c = Q.get(e, 'display')),
                                'none' === (l = T.css(e, 'display')) &&
                                    (c
                                        ? (l = c)
                                        : (pe([e], !0),
                                          (c = e.style.display || c),
                                          (l = T.css(e, 'display')),
                                          pe([e]))),
                                ('inline' === l ||
                                    ('inline-block' === l && null != c)) &&
                                    'none' === T.css(e, 'float') &&
                                    (u ||
                                        (p.done(function () {
                                            h.display = c;
                                        }),
                                        null == c &&
                                            ((l = h.display),
                                            (c = 'none' === l ? '' : l))),
                                    (h.display = 'inline-block'))),
                            n.overflow &&
                                ((h.overflow = 'hidden'),
                                p.always(function () {
                                    (h.overflow = n.overflow[0]),
                                        (h.overflowX = n.overflow[1]),
                                        (h.overflowY = n.overflow[2]);
                                })),
                            (u = !1),
                            d))
                                u ||
                                    (v
                                        ? 'hidden' in v && (g = v.hidden)
                                        : (v = Q.access(e, 'fxshow', {
                                              display: c,
                                          })),
                                    i && (v.hidden = !g),
                                    g && pe([e], !0),
                                    p.done(function () {
                                        for (r in (g || pe([e]),
                                        Q.remove(e, 'fxshow'),
                                        d))
                                            T.style(e, r, d[r]);
                                    })),
                                    (u = ft(g ? v[r] : 0, r, p)),
                                    r in v ||
                                        ((v[r] = u.start),
                                        g &&
                                            ((u.end = u.start), (u.start = 0)));
                    },
                ],
                prefilter: function (e, t) {
                    t ? pt.prefilters.unshift(e) : pt.prefilters.push(e);
                },
            })),
                (T.speed = function (e, t, n) {
                    var r =
                        e && 'object' == typeof e
                            ? T.extend({}, e)
                            : {
                                  complete: n || (!n && t) || (y(e) && e),
                                  duration: e,
                                  easing: (n && t) || (t && !y(t) && t),
                              };
                    return (
                        T.fx.off
                            ? (r.duration = 0)
                            : 'number' != typeof r.duration &&
                              (r.duration in T.fx.speeds
                                  ? (r.duration = T.fx.speeds[r.duration])
                                  : (r.duration = T.fx.speeds._default)),
                        (null != r.queue && !0 !== r.queue) || (r.queue = 'fx'),
                        (r.old = r.complete),
                        (r.complete = function () {
                            y(r.old) && r.old.call(this),
                                r.queue && T.dequeue(this, r.queue);
                        }),
                        r
                    );
                }),
                T.fn.extend({
                    fadeTo: function (e, t, n, r) {
                        return this.filter(ue)
                            .css('opacity', 0)
                            .show()
                            .end()
                            .animate({ opacity: t }, e, n, r);
                    },
                    animate: function (e, t, n, r) {
                        var o = T.isEmptyObject(e),
                            i = T.speed(t, n, r),
                            a = function () {
                                var t = pt(this, T.extend({}, e), i);
                                (o || Q.get(this, 'finish')) && t.stop(!0);
                            };
                        return (
                            (a.finish = a),
                            o || !1 === i.queue
                                ? this.each(a)
                                : this.queue(i.queue, a)
                        );
                    },
                    stop: function (e, t, n) {
                        var r = function (e) {
                            var t = e.stop;
                            delete e.stop, t(n);
                        };
                        return (
                            'string' != typeof e &&
                                ((n = t), (t = e), (e = void 0)),
                            t && this.queue(e || 'fx', []),
                            this.each(function () {
                                var t = !0,
                                    o = null != e && e + 'queueHooks',
                                    i = T.timers,
                                    a = Q.get(this);
                                if (o) a[o] && a[o].stop && r(a[o]);
                                else
                                    for (o in a)
                                        a[o] &&
                                            a[o].stop &&
                                            st.test(o) &&
                                            r(a[o]);
                                for (o = i.length; o--; )
                                    i[o].elem !== this ||
                                        (null != e && i[o].queue !== e) ||
                                        (i[o].anim.stop(n),
                                        (t = !1),
                                        i.splice(o, 1));
                                (!t && n) || T.dequeue(this, e);
                            })
                        );
                    },
                    finish: function (e) {
                        return (
                            !1 !== e && (e = e || 'fx'),
                            this.each(function () {
                                var t,
                                    n = Q.get(this),
                                    r = n[e + 'queue'],
                                    o = n[e + 'queueHooks'],
                                    i = T.timers,
                                    a = r ? r.length : 0;
                                for (
                                    n.finish = !0,
                                        T.queue(this, e, []),
                                        o && o.stop && o.stop.call(this, !0),
                                        t = i.length;
                                    t--;

                                )
                                    i[t].elem === this &&
                                        i[t].queue === e &&
                                        (i[t].anim.stop(!0), i.splice(t, 1));
                                for (t = 0; t < a; t++)
                                    r[t] &&
                                        r[t].finish &&
                                        r[t].finish.call(this);
                                delete n.finish;
                            })
                        );
                    },
                }),
                T.each(['toggle', 'show', 'hide'], function (e, t) {
                    var n = T.fn[t];
                    T.fn[t] = function (e, r, o) {
                        return null == e || 'boolean' == typeof e
                            ? n.apply(this, arguments)
                            : this.animate(lt(t, !0), e, r, o);
                    };
                }),
                T.each(
                    {
                        slideDown: lt('show'),
                        slideUp: lt('hide'),
                        slideToggle: lt('toggle'),
                        fadeIn: { opacity: 'show' },
                        fadeOut: { opacity: 'hide' },
                        fadeToggle: { opacity: 'toggle' },
                    },
                    function (e, t) {
                        T.fn[e] = function (e, n, r) {
                            return this.animate(t, e, n, r);
                        };
                    }
                ),
                (T.timers = []),
                (T.fx.tick = function () {
                    var e,
                        t = 0,
                        n = T.timers;
                    for (ot = Date.now(); t < n.length; t++)
                        (e = n[t])() || n[t] !== e || n.splice(t--, 1);
                    n.length || T.fx.stop(), (ot = void 0);
                }),
                (T.fx.timer = function (e) {
                    T.timers.push(e), T.fx.start();
                }),
                (T.fx.interval = 13),
                (T.fx.start = function () {
                    it || ((it = !0), ut());
                }),
                (T.fx.stop = function () {
                    it = null;
                }),
                (T.fx.speeds = { slow: 600, fast: 200, _default: 400 }),
                (T.fn.delay = function (e, t) {
                    return (
                        (e = (T.fx && T.fx.speeds[e]) || e),
                        (t = t || 'fx'),
                        this.queue(t, function (t, r) {
                            var o = n.setTimeout(t, e);
                            r.stop = function () {
                                n.clearTimeout(o);
                            };
                        })
                    );
                }),
                (function () {
                    var e = x.createElement('input'),
                        t = x
                            .createElement('select')
                            .appendChild(x.createElement('option'));
                    (e.type = 'checkbox'),
                        (v.checkOn = '' !== e.value),
                        (v.optSelected = t.selected),
                        ((e = x.createElement('input')).value = 't'),
                        (e.type = 'radio'),
                        (v.radioValue = 't' === e.value);
                })();
            var dt,
                ht = T.expr.attrHandle;
            T.fn.extend({
                attr: function (e, t) {
                    return z(this, T.attr, e, t, arguments.length > 1);
                },
                removeAttr: function (e) {
                    return this.each(function () {
                        T.removeAttr(this, e);
                    });
                },
            }),
                T.extend({
                    attr: function (e, t, n) {
                        var r,
                            o,
                            i = e.nodeType;
                        if (3 !== i && 8 !== i && 2 !== i)
                            return void 0 === e.getAttribute
                                ? T.prop(e, t, n)
                                : ((1 === i && T.isXMLDoc(e)) ||
                                      (o =
                                          T.attrHooks[t.toLowerCase()] ||
                                          (T.expr.match.bool.test(t)
                                              ? dt
                                              : void 0)),
                                  void 0 !== n
                                      ? null === n
                                          ? void T.removeAttr(e, t)
                                          : o &&
                                            'set' in o &&
                                            void 0 !== (r = o.set(e, n, t))
                                          ? r
                                          : (e.setAttribute(t, n + ''), n)
                                      : o &&
                                        'get' in o &&
                                        null !== (r = o.get(e, t))
                                      ? r
                                      : null == (r = T.find.attr(e, t))
                                      ? void 0
                                      : r);
                    },
                    attrHooks: {
                        type: {
                            set: function (e, t) {
                                if (
                                    !v.radioValue &&
                                    'radio' === t &&
                                    j(e, 'input')
                                ) {
                                    var n = e.value;
                                    return (
                                        e.setAttribute('type', t),
                                        n && (e.value = n),
                                        t
                                    );
                                }
                            },
                        },
                    },
                    removeAttr: function (e, t) {
                        var n,
                            r = 0,
                            o = t && t.match(R);
                        if (o && 1 === e.nodeType)
                            for (; (n = o[r++]); ) e.removeAttribute(n);
                    },
                }),
                (dt = {
                    set: function (e, t, n) {
                        return (
                            !1 === t
                                ? T.removeAttr(e, n)
                                : e.setAttribute(n, n),
                            n
                        );
                    },
                }),
                T.each(T.expr.match.bool.source.match(/\w+/g), function (e, t) {
                    var n = ht[t] || T.find.attr;
                    ht[t] = function (e, t, r) {
                        var o,
                            i,
                            a = t.toLowerCase();
                        return (
                            r ||
                                ((i = ht[a]),
                                (ht[a] = o),
                                (o = null != n(e, t, r) ? a : null),
                                (ht[a] = i)),
                            o
                        );
                    };
                });
            var gt = /^(?:input|select|textarea|button)$/i,
                vt = /^(?:a|area)$/i;
            function yt(e) {
                return (e.match(R) || []).join(' ');
            }
            function mt(e) {
                return (e.getAttribute && e.getAttribute('class')) || '';
            }
            function xt(e) {
                return Array.isArray(e)
                    ? e
                    : ('string' == typeof e && e.match(R)) || [];
            }
            T.fn.extend({
                prop: function (e, t) {
                    return z(this, T.prop, e, t, arguments.length > 1);
                },
                removeProp: function (e) {
                    return this.each(function () {
                        delete this[T.propFix[e] || e];
                    });
                },
            }),
                T.extend({
                    prop: function (e, t, n) {
                        var r,
                            o,
                            i = e.nodeType;
                        if (3 !== i && 8 !== i && 2 !== i)
                            return (
                                (1 === i && T.isXMLDoc(e)) ||
                                    ((t = T.propFix[t] || t),
                                    (o = T.propHooks[t])),
                                void 0 !== n
                                    ? o &&
                                      'set' in o &&
                                      void 0 !== (r = o.set(e, n, t))
                                        ? r
                                        : (e[t] = n)
                                    : o &&
                                      'get' in o &&
                                      null !== (r = o.get(e, t))
                                    ? r
                                    : e[t]
                            );
                    },
                    propHooks: {
                        tabIndex: {
                            get: function (e) {
                                var t = T.find.attr(e, 'tabindex');
                                return t
                                    ? parseInt(t, 10)
                                    : gt.test(e.nodeName) ||
                                      (vt.test(e.nodeName) && e.href)
                                    ? 0
                                    : -1;
                            },
                        },
                    },
                    propFix: { for: 'htmlFor', class: 'className' },
                }),
                v.optSelected ||
                    (T.propHooks.selected = {
                        get: function (e) {
                            var t = e.parentNode;
                            return (
                                t && t.parentNode && t.parentNode.selectedIndex,
                                null
                            );
                        },
                        set: function (e) {
                            var t = e.parentNode;
                            t &&
                                (t.selectedIndex,
                                t.parentNode && t.parentNode.selectedIndex);
                        },
                    }),
                T.each(
                    [
                        'tabIndex',
                        'readOnly',
                        'maxLength',
                        'cellSpacing',
                        'cellPadding',
                        'rowSpan',
                        'colSpan',
                        'useMap',
                        'frameBorder',
                        'contentEditable',
                    ],
                    function () {
                        T.propFix[this.toLowerCase()] = this;
                    }
                ),
                T.fn.extend({
                    addClass: function (e) {
                        var t, n, r, o, i, a;
                        return y(e)
                            ? this.each(function (t) {
                                  T(this).addClass(e.call(this, t, mt(this)));
                              })
                            : (t = xt(e)).length
                            ? this.each(function () {
                                  if (
                                      ((r = mt(this)),
                                      (n =
                                          1 === this.nodeType &&
                                          ' ' + yt(r) + ' '))
                                  ) {
                                      for (i = 0; i < t.length; i++)
                                          (o = t[i]),
                                              n.indexOf(' ' + o + ' ') < 0 &&
                                                  (n += o + ' ');
                                      (a = yt(n)),
                                          r !== a &&
                                              this.setAttribute('class', a);
                                  }
                              })
                            : this;
                    },
                    removeClass: function (e) {
                        var t, n, r, o, i, a;
                        return y(e)
                            ? this.each(function (t) {
                                  T(this).removeClass(
                                      e.call(this, t, mt(this))
                                  );
                              })
                            : arguments.length
                            ? (t = xt(e)).length
                                ? this.each(function () {
                                      if (
                                          ((r = mt(this)),
                                          (n =
                                              1 === this.nodeType &&
                                              ' ' + yt(r) + ' '))
                                      ) {
                                          for (i = 0; i < t.length; i++)
                                              for (
                                                  o = t[i];
                                                  n.indexOf(' ' + o + ' ') > -1;

                                              )
                                                  n = n.replace(
                                                      ' ' + o + ' ',
                                                      ' '
                                                  );
                                          (a = yt(n)),
                                              r !== a &&
                                                  this.setAttribute('class', a);
                                      }
                                  })
                                : this
                            : this.attr('class', '');
                    },
                    toggleClass: function (e, t) {
                        var n,
                            r,
                            o,
                            i,
                            a = typeof e,
                            s = 'string' === a || Array.isArray(e);
                        return y(e)
                            ? this.each(function (n) {
                                  T(this).toggleClass(
                                      e.call(this, n, mt(this), t),
                                      t
                                  );
                              })
                            : 'boolean' == typeof t && s
                            ? t
                                ? this.addClass(e)
                                : this.removeClass(e)
                            : ((n = xt(e)),
                              this.each(function () {
                                  if (s)
                                      for (
                                          i = T(this), o = 0;
                                          o < n.length;
                                          o++
                                      )
                                          (r = n[o]),
                                              i.hasClass(r)
                                                  ? i.removeClass(r)
                                                  : i.addClass(r);
                                  else
                                      (void 0 !== e && 'boolean' !== a) ||
                                          ((r = mt(this)) &&
                                              Q.set(this, '__className__', r),
                                          this.setAttribute &&
                                              this.setAttribute(
                                                  'class',
                                                  r || !1 === e
                                                      ? ''
                                                      : Q.get(
                                                            this,
                                                            '__className__'
                                                        ) || ''
                                              ));
                              }));
                    },
                    hasClass: function (e) {
                        var t,
                            n,
                            r = 0;
                        for (t = ' ' + e + ' '; (n = this[r++]); )
                            if (
                                1 === n.nodeType &&
                                (' ' + yt(mt(n)) + ' ').indexOf(t) > -1
                            )
                                return !0;
                        return !1;
                    },
                });
            var bt = /\r/g;
            T.fn.extend({
                val: function (e) {
                    var t,
                        n,
                        r,
                        o = this[0];
                    return arguments.length
                        ? ((r = y(e)),
                          this.each(function (n) {
                              var o;
                              1 === this.nodeType &&
                                  (null ==
                                  (o = r ? e.call(this, n, T(this).val()) : e)
                                      ? (o = '')
                                      : 'number' == typeof o
                                      ? (o += '')
                                      : Array.isArray(o) &&
                                        (o = T.map(o, function (e) {
                                            return null == e ? '' : e + '';
                                        })),
                                  ((t =
                                      T.valHooks[this.type] ||
                                      T.valHooks[
                                          this.nodeName.toLowerCase()
                                      ]) &&
                                      'set' in t &&
                                      void 0 !== t.set(this, o, 'value')) ||
                                      (this.value = o));
                          }))
                        : o
                        ? (t =
                              T.valHooks[o.type] ||
                              T.valHooks[o.nodeName.toLowerCase()]) &&
                          'get' in t &&
                          void 0 !== (n = t.get(o, 'value'))
                            ? n
                            : 'string' == typeof (n = o.value)
                            ? n.replace(bt, '')
                            : null == n
                            ? ''
                            : n
                        : void 0;
                },
            }),
                T.extend({
                    valHooks: {
                        option: {
                            get: function (e) {
                                var t = T.find.attr(e, 'value');
                                return null != t ? t : yt(T.text(e));
                            },
                        },
                        select: {
                            get: function (e) {
                                var t,
                                    n,
                                    r,
                                    o = e.options,
                                    i = e.selectedIndex,
                                    a = 'select-one' === e.type,
                                    s = a ? null : [],
                                    u = a ? i + 1 : o.length;
                                for (r = i < 0 ? u : a ? i : 0; r < u; r++)
                                    if (
                                        ((n = o[r]).selected || r === i) &&
                                        !n.disabled &&
                                        (!n.parentNode.disabled ||
                                            !j(n.parentNode, 'optgroup'))
                                    ) {
                                        if (((t = T(n).val()), a)) return t;
                                        s.push(t);
                                    }
                                return s;
                            },
                            set: function (e, t) {
                                for (
                                    var n,
                                        r,
                                        o = e.options,
                                        i = T.makeArray(t),
                                        a = o.length;
                                    a--;

                                )
                                    ((r = o[a]).selected =
                                        T.inArray(T.valHooks.option.get(r), i) >
                                        -1) && (n = !0);
                                return n || (e.selectedIndex = -1), i;
                            },
                        },
                    },
                }),
                T.each(['radio', 'checkbox'], function () {
                    (T.valHooks[this] = {
                        set: function (e, t) {
                            if (Array.isArray(t))
                                return (e.checked =
                                    T.inArray(T(e).val(), t) > -1);
                        },
                    }),
                        v.checkOn ||
                            (T.valHooks[this].get = function (e) {
                                return null === e.getAttribute('value')
                                    ? 'on'
                                    : e.value;
                            });
                }),
                (v.focusin = 'onfocusin' in n);
            var wt = /^(?:focusinfocus|focusoutblur)$/,
                Ct = function (e) {
                    e.stopPropagation();
                };
            T.extend(T.event, {
                trigger: function (e, t, r, o) {
                    var i,
                        a,
                        s,
                        u,
                        c,
                        l,
                        f,
                        p,
                        h = [r || x],
                        g = d.call(e, 'type') ? e.type : e,
                        v = d.call(e, 'namespace')
                            ? e.namespace.split('.')
                            : [];
                    if (
                        ((a = p = s = r = r || x),
                        3 !== r.nodeType &&
                            8 !== r.nodeType &&
                            !wt.test(g + T.event.triggered) &&
                            (g.indexOf('.') > -1 &&
                                ((v = g.split('.')), (g = v.shift()), v.sort()),
                            (c = g.indexOf(':') < 0 && 'on' + g),
                            ((e = e[T.expando]
                                ? e
                                : new T.Event(
                                      g,
                                      'object' == typeof e && e
                                  )).isTrigger = o ? 2 : 3),
                            (e.namespace = v.join('.')),
                            (e.rnamespace = e.namespace
                                ? new RegExp(
                                      '(^|\\.)' +
                                          v.join('\\.(?:.*\\.|)') +
                                          '(\\.|$)'
                                  )
                                : null),
                            (e.result = void 0),
                            e.target || (e.target = r),
                            (t = null == t ? [e] : T.makeArray(t, [e])),
                            (f = T.event.special[g] || {}),
                            o || !f.trigger || !1 !== f.trigger.apply(r, t)))
                    ) {
                        if (!o && !f.noBubble && !m(r)) {
                            for (
                                u = f.delegateType || g,
                                    wt.test(u + g) || (a = a.parentNode);
                                a;
                                a = a.parentNode
                            )
                                h.push(a), (s = a);
                            s === (r.ownerDocument || x) &&
                                h.push(s.defaultView || s.parentWindow || n);
                        }
                        for (i = 0; (a = h[i++]) && !e.isPropagationStopped(); )
                            (p = a),
                                (e.type = i > 1 ? u : f.bindType || g),
                                (l =
                                    (Q.get(a, 'events') || Object.create(null))[
                                        e.type
                                    ] && Q.get(a, 'handle')) && l.apply(a, t),
                                (l = c && a[c]) &&
                                    l.apply &&
                                    J(a) &&
                                    ((e.result = l.apply(a, t)),
                                    !1 === e.result && e.preventDefault());
                        return (
                            (e.type = g),
                            o ||
                                e.isDefaultPrevented() ||
                                (f._default &&
                                    !1 !== f._default.apply(h.pop(), t)) ||
                                !J(r) ||
                                (c &&
                                    y(r[g]) &&
                                    !m(r) &&
                                    ((s = r[c]) && (r[c] = null),
                                    (T.event.triggered = g),
                                    e.isPropagationStopped() &&
                                        p.addEventListener(g, Ct),
                                    r[g](),
                                    e.isPropagationStopped() &&
                                        p.removeEventListener(g, Ct),
                                    (T.event.triggered = void 0),
                                    s && (r[c] = s))),
                            e.result
                        );
                    }
                },
                simulate: function (e, t, n) {
                    var r = T.extend(new T.Event(), n, {
                        type: e,
                        isSimulated: !0,
                    });
                    T.event.trigger(r, null, t);
                },
            }),
                T.fn.extend({
                    trigger: function (e, t) {
                        return this.each(function () {
                            T.event.trigger(e, t, this);
                        });
                    },
                    triggerHandler: function (e, t) {
                        var n = this[0];
                        if (n) return T.event.trigger(e, t, n, !0);
                    },
                }),
                v.focusin ||
                    T.each(
                        { focus: 'focusin', blur: 'focusout' },
                        function (e, t) {
                            var n = function (e) {
                                T.event.simulate(t, e.target, T.event.fix(e));
                            };
                            T.event.special[t] = {
                                setup: function () {
                                    var r =
                                            this.ownerDocument ||
                                            this.document ||
                                            this,
                                        o = Q.access(r, t);
                                    o || r.addEventListener(e, n, !0),
                                        Q.access(r, t, (o || 0) + 1);
                                },
                                teardown: function () {
                                    var r =
                                            this.ownerDocument ||
                                            this.document ||
                                            this,
                                        o = Q.access(r, t) - 1;
                                    o
                                        ? Q.access(r, t, o)
                                        : (r.removeEventListener(e, n, !0),
                                          Q.remove(r, t));
                                },
                            };
                        }
                    );
            var Tt = n.location,
                kt = { guid: Date.now() },
                St = /\?/;
            T.parseXML = function (e) {
                var t, r;
                if (!e || 'string' != typeof e) return null;
                try {
                    t = new n.DOMParser().parseFromString(e, 'text/xml');
                } catch (e) {}
                return (
                    (r = t && t.getElementsByTagName('parsererror')[0]),
                    (t && !r) ||
                        T.error(
                            'Invalid XML: ' +
                                (r
                                    ? T.map(r.childNodes, function (e) {
                                          return e.textContent;
                                      }).join('\n')
                                    : e)
                        ),
                    t
                );
            };
            var At = /\[\]$/,
                Et = /\r?\n/g,
                Nt = /^(?:submit|button|image|reset|file)$/i,
                jt = /^(?:input|select|textarea|keygen)/i;
            function Dt(e, t, n, r) {
                var o;
                if (Array.isArray(t))
                    T.each(t, function (t, o) {
                        n || At.test(e)
                            ? r(e, o)
                            : Dt(
                                  e +
                                      '[' +
                                      ('object' == typeof o && null != o
                                          ? t
                                          : '') +
                                      ']',
                                  o,
                                  n,
                                  r
                              );
                    });
                else if (n || 'object' !== C(t)) r(e, t);
                else for (o in t) Dt(e + '[' + o + ']', t[o], n, r);
            }
            (T.param = function (e, t) {
                var n,
                    r = [],
                    o = function (e, t) {
                        var n = y(t) ? t() : t;
                        r[r.length] =
                            encodeURIComponent(e) +
                            '=' +
                            encodeURIComponent(null == n ? '' : n);
                    };
                if (null == e) return '';
                if (Array.isArray(e) || (e.jquery && !T.isPlainObject(e)))
                    T.each(e, function () {
                        o(this.name, this.value);
                    });
                else for (n in e) Dt(n, e[n], t, o);
                return r.join('&');
            }),
                T.fn.extend({
                    serialize: function () {
                        return T.param(this.serializeArray());
                    },
                    serializeArray: function () {
                        return this.map(function () {
                            var e = T.prop(this, 'elements');
                            return e ? T.makeArray(e) : this;
                        })
                            .filter(function () {
                                var e = this.type;
                                return (
                                    this.name &&
                                    !T(this).is(':disabled') &&
                                    jt.test(this.nodeName) &&
                                    !Nt.test(e) &&
                                    (this.checked || !ge.test(e))
                                );
                            })
                            .map(function (e, t) {
                                var n = T(this).val();
                                return null == n
                                    ? null
                                    : Array.isArray(n)
                                    ? T.map(n, function (e) {
                                          return {
                                              name: t.name,
                                              value: e.replace(Et, '\r\n'),
                                          };
                                      })
                                    : {
                                          name: t.name,
                                          value: n.replace(Et, '\r\n'),
                                      };
                            })
                            .get();
                    },
                });
            var Lt = /%20/g,
                qt = /#.*$/,
                Ot = /([?&])_=[^&]*/,
                Ht = /^(.*?):[ \t]*([^\r\n]*)$/gm,
                Pt = /^(?:GET|HEAD)$/,
                Mt = /^\/\//,
                Rt = {},
                Bt = {},
                It = '*/'.concat('*'),
                Ft = x.createElement('a');
            function Wt(e) {
                return function (t, n) {
                    'string' != typeof t && ((n = t), (t = '*'));
                    var r,
                        o = 0,
                        i = t.toLowerCase().match(R) || [];
                    if (y(n))
                        for (; (r = i[o++]); )
                            '+' === r[0]
                                ? ((r = r.slice(1) || '*'),
                                  (e[r] = e[r] || []).unshift(n))
                                : (e[r] = e[r] || []).push(n);
                };
            }
            function $t(e, t, n, r) {
                var o = {},
                    i = e === Bt;
                function a(s) {
                    var u;
                    return (
                        (o[s] = !0),
                        T.each(e[s] || [], function (e, s) {
                            var c = s(t, n, r);
                            return 'string' != typeof c || i || o[c]
                                ? i
                                    ? !(u = c)
                                    : void 0
                                : (t.dataTypes.unshift(c), a(c), !1);
                        }),
                        u
                    );
                }
                return a(t.dataTypes[0]) || (!o['*'] && a('*'));
            }
            function _t(e, t) {
                var n,
                    r,
                    o = T.ajaxSettings.flatOptions || {};
                for (n in t)
                    void 0 !== t[n] && ((o[n] ? e : r || (r = {}))[n] = t[n]);
                return r && T.extend(!0, e, r), e;
            }
            (Ft.href = Tt.href),
                T.extend({
                    active: 0,
                    lastModified: {},
                    etag: {},
                    ajaxSettings: {
                        url: Tt.href,
                        type: 'GET',
                        isLocal:
                            /^(?:about|app|app-storage|.+-extension|file|res|widget):$/.test(
                                Tt.protocol
                            ),
                        global: !0,
                        processData: !0,
                        async: !0,
                        contentType:
                            'application/x-www-form-urlencoded; charset=UTF-8',
                        accepts: {
                            '*': It,
                            text: 'text/plain',
                            html: 'text/html',
                            xml: 'application/xml, text/xml',
                            json: 'application/json, text/javascript',
                        },
                        contents: {
                            xml: /\bxml\b/,
                            html: /\bhtml/,
                            json: /\bjson\b/,
                        },
                        responseFields: {
                            xml: 'responseXML',
                            text: 'responseText',
                            json: 'responseJSON',
                        },
                        converters: {
                            '* text': String,
                            'text html': !0,
                            'text json': JSON.parse,
                            'text xml': T.parseXML,
                        },
                        flatOptions: { url: !0, context: !0 },
                    },
                    ajaxSetup: function (e, t) {
                        return t
                            ? _t(_t(e, T.ajaxSettings), t)
                            : _t(T.ajaxSettings, e);
                    },
                    ajaxPrefilter: Wt(Rt),
                    ajaxTransport: Wt(Bt),
                    ajax: function (e, t) {
                        'object' == typeof e && ((t = e), (e = void 0)),
                            (t = t || {});
                        var r,
                            o,
                            i,
                            a,
                            s,
                            u,
                            c,
                            l,
                            f,
                            p,
                            d = T.ajaxSetup({}, t),
                            h = d.context || d,
                            g =
                                d.context && (h.nodeType || h.jquery)
                                    ? T(h)
                                    : T.event,
                            v = T.Deferred(),
                            y = T.Callbacks('once memory'),
                            m = d.statusCode || {},
                            b = {},
                            w = {},
                            C = 'canceled',
                            k = {
                                readyState: 0,
                                getResponseHeader: function (e) {
                                    var t;
                                    if (c) {
                                        if (!a)
                                            for (a = {}; (t = Ht.exec(i)); )
                                                a[t[1].toLowerCase() + ' '] = (
                                                    a[
                                                        t[1].toLowerCase() + ' '
                                                    ] || []
                                                ).concat(t[2]);
                                        t = a[e.toLowerCase() + ' '];
                                    }
                                    return null == t ? null : t.join(', ');
                                },
                                getAllResponseHeaders: function () {
                                    return c ? i : null;
                                },
                                setRequestHeader: function (e, t) {
                                    return (
                                        null == c &&
                                            ((e = w[e.toLowerCase()] =
                                                w[e.toLowerCase()] || e),
                                            (b[e] = t)),
                                        this
                                    );
                                },
                                overrideMimeType: function (e) {
                                    return null == c && (d.mimeType = e), this;
                                },
                                statusCode: function (e) {
                                    var t;
                                    if (e)
                                        if (c) k.always(e[k.status]);
                                        else for (t in e) m[t] = [m[t], e[t]];
                                    return this;
                                },
                                abort: function (e) {
                                    var t = e || C;
                                    return r && r.abort(t), S(0, t), this;
                                },
                            };
                        if (
                            (v.promise(k),
                            (d.url = ((e || d.url || Tt.href) + '').replace(
                                Mt,
                                Tt.protocol + '//'
                            )),
                            (d.type = t.method || t.type || d.method || d.type),
                            (d.dataTypes = (d.dataType || '*')
                                .toLowerCase()
                                .match(R) || ['']),
                            null == d.crossDomain)
                        ) {
                            u = x.createElement('a');
                            try {
                                (u.href = d.url),
                                    (u.href = u.href),
                                    (d.crossDomain =
                                        Ft.protocol + '//' + Ft.host !=
                                        u.protocol + '//' + u.host);
                            } catch (e) {
                                d.crossDomain = !0;
                            }
                        }
                        if (
                            (d.data &&
                                d.processData &&
                                'string' != typeof d.data &&
                                (d.data = T.param(d.data, d.traditional)),
                            $t(Rt, d, t, k),
                            c)
                        )
                            return k;
                        for (f in ((l = T.event && d.global) &&
                            0 == T.active++ &&
                            T.event.trigger('ajaxStart'),
                        (d.type = d.type.toUpperCase()),
                        (d.hasContent = !Pt.test(d.type)),
                        (o = d.url.replace(qt, '')),
                        d.hasContent
                            ? d.data &&
                              d.processData &&
                              0 ===
                                  (d.contentType || '').indexOf(
                                      'application/x-www-form-urlencoded'
                                  ) &&
                              (d.data = d.data.replace(Lt, '+'))
                            : ((p = d.url.slice(o.length)),
                              d.data &&
                                  (d.processData ||
                                      'string' == typeof d.data) &&
                                  ((o += (St.test(o) ? '&' : '?') + d.data),
                                  delete d.data),
                              !1 === d.cache &&
                                  ((o = o.replace(Ot, '$1')),
                                  (p =
                                      (St.test(o) ? '&' : '?') +
                                      '_=' +
                                      kt.guid++ +
                                      p)),
                              (d.url = o + p)),
                        d.ifModified &&
                            (T.lastModified[o] &&
                                k.setRequestHeader(
                                    'If-Modified-Since',
                                    T.lastModified[o]
                                ),
                            T.etag[o] &&
                                k.setRequestHeader('If-None-Match', T.etag[o])),
                        ((d.data && d.hasContent && !1 !== d.contentType) ||
                            t.contentType) &&
                            k.setRequestHeader('Content-Type', d.contentType),
                        k.setRequestHeader(
                            'Accept',
                            d.dataTypes[0] && d.accepts[d.dataTypes[0]]
                                ? d.accepts[d.dataTypes[0]] +
                                      ('*' !== d.dataTypes[0]
                                          ? ', ' + It + '; q=0.01'
                                          : '')
                                : d.accepts['*']
                        ),
                        d.headers))
                            k.setRequestHeader(f, d.headers[f]);
                        if (
                            d.beforeSend &&
                            (!1 === d.beforeSend.call(h, k, d) || c)
                        )
                            return k.abort();
                        if (
                            ((C = 'abort'),
                            y.add(d.complete),
                            k.done(d.success),
                            k.fail(d.error),
                            (r = $t(Bt, d, t, k)))
                        ) {
                            if (
                                ((k.readyState = 1),
                                l && g.trigger('ajaxSend', [k, d]),
                                c)
                            )
                                return k;
                            d.async &&
                                d.timeout > 0 &&
                                (s = n.setTimeout(function () {
                                    k.abort('timeout');
                                }, d.timeout));
                            try {
                                (c = !1), r.send(b, S);
                            } catch (e) {
                                if (c) throw e;
                                S(-1, e);
                            }
                        } else S(-1, 'No Transport');
                        function S(e, t, a, u) {
                            var f,
                                p,
                                x,
                                b,
                                w,
                                C = t;
                            c ||
                                ((c = !0),
                                s && n.clearTimeout(s),
                                (r = void 0),
                                (i = u || ''),
                                (k.readyState = e > 0 ? 4 : 0),
                                (f = (e >= 200 && e < 300) || 304 === e),
                                a &&
                                    (b = (function (e, t, n) {
                                        for (
                                            var r,
                                                o,
                                                i,
                                                a,
                                                s = e.contents,
                                                u = e.dataTypes;
                                            '*' === u[0];

                                        )
                                            u.shift(),
                                                void 0 === r &&
                                                    (r =
                                                        e.mimeType ||
                                                        t.getResponseHeader(
                                                            'Content-Type'
                                                        ));
                                        if (r)
                                            for (o in s)
                                                if (s[o] && s[o].test(r)) {
                                                    u.unshift(o);
                                                    break;
                                                }
                                        if (u[0] in n) i = u[0];
                                        else {
                                            for (o in n) {
                                                if (
                                                    !u[0] ||
                                                    e.converters[o + ' ' + u[0]]
                                                ) {
                                                    i = o;
                                                    break;
                                                }
                                                a || (a = o);
                                            }
                                            i = i || a;
                                        }
                                        if (i)
                                            return (
                                                i !== u[0] && u.unshift(i), n[i]
                                            );
                                    })(d, k, a)),
                                !f &&
                                    T.inArray('script', d.dataTypes) > -1 &&
                                    T.inArray('json', d.dataTypes) < 0 &&
                                    (d.converters['text script'] =
                                        function () {}),
                                (b = (function (e, t, n, r) {
                                    var o,
                                        i,
                                        a,
                                        s,
                                        u,
                                        c = {},
                                        l = e.dataTypes.slice();
                                    if (l[1])
                                        for (a in e.converters)
                                            c[a.toLowerCase()] =
                                                e.converters[a];
                                    for (i = l.shift(); i; )
                                        if (
                                            (e.responseFields[i] &&
                                                (n[e.responseFields[i]] = t),
                                            !u &&
                                                r &&
                                                e.dataFilter &&
                                                (t = e.dataFilter(
                                                    t,
                                                    e.dataType
                                                )),
                                            (u = i),
                                            (i = l.shift()))
                                        )
                                            if ('*' === i) i = u;
                                            else if ('*' !== u && u !== i) {
                                                if (
                                                    !(a =
                                                        c[u + ' ' + i] ||
                                                        c['* ' + i])
                                                )
                                                    for (o in c)
                                                        if (
                                                            (s =
                                                                o.split(
                                                                    ' '
                                                                ))[1] === i &&
                                                            (a =
                                                                c[
                                                                    u +
                                                                        ' ' +
                                                                        s[0]
                                                                ] ||
                                                                c['* ' + s[0]])
                                                        ) {
                                                            !0 === a
                                                                ? (a = c[o])
                                                                : !0 !== c[o] &&
                                                                  ((i = s[0]),
                                                                  l.unshift(
                                                                      s[1]
                                                                  ));
                                                            break;
                                                        }
                                                if (!0 !== a)
                                                    if (a && e.throws) t = a(t);
                                                    else
                                                        try {
                                                            t = a(t);
                                                        } catch (e) {
                                                            return {
                                                                state: 'parsererror',
                                                                error: a
                                                                    ? e
                                                                    : 'No conversion from ' +
                                                                      u +
                                                                      ' to ' +
                                                                      i,
                                                            };
                                                        }
                                            }
                                    return { state: 'success', data: t };
                                })(d, b, k, f)),
                                f
                                    ? (d.ifModified &&
                                          ((w =
                                              k.getResponseHeader(
                                                  'Last-Modified'
                                              )) && (T.lastModified[o] = w),
                                          (w = k.getResponseHeader('etag')) &&
                                              (T.etag[o] = w)),
                                      204 === e || 'HEAD' === d.type
                                          ? (C = 'nocontent')
                                          : 304 === e
                                          ? (C = 'notmodified')
                                          : ((C = b.state),
                                            (p = b.data),
                                            (f = !(x = b.error))))
                                    : ((x = C),
                                      (!e && C) ||
                                          ((C = 'error'), e < 0 && (e = 0))),
                                (k.status = e),
                                (k.statusText = (t || C) + ''),
                                f
                                    ? v.resolveWith(h, [p, C, k])
                                    : v.rejectWith(h, [k, C, x]),
                                k.statusCode(m),
                                (m = void 0),
                                l &&
                                    g.trigger(f ? 'ajaxSuccess' : 'ajaxError', [
                                        k,
                                        d,
                                        f ? p : x,
                                    ]),
                                y.fireWith(h, [k, C]),
                                l &&
                                    (g.trigger('ajaxComplete', [k, d]),
                                    --T.active || T.event.trigger('ajaxStop')));
                        }
                        return k;
                    },
                    getJSON: function (e, t, n) {
                        return T.get(e, t, n, 'json');
                    },
                    getScript: function (e, t) {
                        return T.get(e, void 0, t, 'script');
                    },
                }),
                T.each(['get', 'post'], function (e, t) {
                    T[t] = function (e, n, r, o) {
                        return (
                            y(n) && ((o = o || r), (r = n), (n = void 0)),
                            T.ajax(
                                T.extend(
                                    {
                                        url: e,
                                        type: t,
                                        dataType: o,
                                        data: n,
                                        success: r,
                                    },
                                    T.isPlainObject(e) && e
                                )
                            )
                        );
                    };
                }),
                T.ajaxPrefilter(function (e) {
                    var t;
                    for (t in e.headers)
                        'content-type' === t.toLowerCase() &&
                            (e.contentType = e.headers[t] || '');
                }),
                (T._evalUrl = function (e, t, n) {
                    return T.ajax({
                        url: e,
                        type: 'GET',
                        dataType: 'script',
                        cache: !0,
                        async: !1,
                        global: !1,
                        converters: { 'text script': function () {} },
                        dataFilter: function (e) {
                            T.globalEval(e, t, n);
                        },
                    });
                }),
                T.fn.extend({
                    wrapAll: function (e) {
                        var t;
                        return (
                            this[0] &&
                                (y(e) && (e = e.call(this[0])),
                                (t = T(e, this[0].ownerDocument)
                                    .eq(0)
                                    .clone(!0)),
                                this[0].parentNode && t.insertBefore(this[0]),
                                t
                                    .map(function () {
                                        for (
                                            var e = this;
                                            e.firstElementChild;

                                        )
                                            e = e.firstElementChild;
                                        return e;
                                    })
                                    .append(this)),
                            this
                        );
                    },
                    wrapInner: function (e) {
                        return y(e)
                            ? this.each(function (t) {
                                  T(this).wrapInner(e.call(this, t));
                              })
                            : this.each(function () {
                                  var t = T(this),
                                      n = t.contents();
                                  n.length ? n.wrapAll(e) : t.append(e);
                              });
                    },
                    wrap: function (e) {
                        var t = y(e);
                        return this.each(function (n) {
                            T(this).wrapAll(t ? e.call(this, n) : e);
                        });
                    },
                    unwrap: function (e) {
                        return (
                            this.parent(e)
                                .not('body')
                                .each(function () {
                                    T(this).replaceWith(this.childNodes);
                                }),
                            this
                        );
                    },
                }),
                (T.expr.pseudos.hidden = function (e) {
                    return !T.expr.pseudos.visible(e);
                }),
                (T.expr.pseudos.visible = function (e) {
                    return !!(
                        e.offsetWidth ||
                        e.offsetHeight ||
                        e.getClientRects().length
                    );
                }),
                (T.ajaxSettings.xhr = function () {
                    try {
                        return new n.XMLHttpRequest();
                    } catch (e) {}
                });
            var zt = { 0: 200, 1223: 204 },
                Ut = T.ajaxSettings.xhr();
            (v.cors = !!Ut && 'withCredentials' in Ut),
                (v.ajax = Ut = !!Ut),
                T.ajaxTransport(function (e) {
                    var t, r;
                    if (v.cors || (Ut && !e.crossDomain))
                        return {
                            send: function (o, i) {
                                var a,
                                    s = e.xhr();
                                if (
                                    (s.open(
                                        e.type,
                                        e.url,
                                        e.async,
                                        e.username,
                                        e.password
                                    ),
                                    e.xhrFields)
                                )
                                    for (a in e.xhrFields)
                                        s[a] = e.xhrFields[a];
                                for (a in (e.mimeType &&
                                    s.overrideMimeType &&
                                    s.overrideMimeType(e.mimeType),
                                e.crossDomain ||
                                    o['X-Requested-With'] ||
                                    (o['X-Requested-With'] = 'XMLHttpRequest'),
                                o))
                                    s.setRequestHeader(a, o[a]);
                                (t = function (e) {
                                    return function () {
                                        t &&
                                            ((t =
                                                r =
                                                s.onload =
                                                s.onerror =
                                                s.onabort =
                                                s.ontimeout =
                                                s.onreadystatechange =
                                                    null),
                                            'abort' === e
                                                ? s.abort()
                                                : 'error' === e
                                                ? 'number' != typeof s.status
                                                    ? i(0, 'error')
                                                    : i(s.status, s.statusText)
                                                : i(
                                                      zt[s.status] || s.status,
                                                      s.statusText,
                                                      'text' !==
                                                          (s.responseType ||
                                                              'text') ||
                                                          'string' !=
                                                              typeof s.responseText
                                                          ? {
                                                                binary: s.response,
                                                            }
                                                          : {
                                                                text: s.responseText,
                                                            },
                                                      s.getAllResponseHeaders()
                                                  ));
                                    };
                                }),
                                    (s.onload = t()),
                                    (r = s.onerror = s.ontimeout = t('error')),
                                    void 0 !== s.onabort
                                        ? (s.onabort = r)
                                        : (s.onreadystatechange = function () {
                                              4 === s.readyState &&
                                                  n.setTimeout(function () {
                                                      t && r();
                                                  });
                                          }),
                                    (t = t('abort'));
                                try {
                                    s.send((e.hasContent && e.data) || null);
                                } catch (e) {
                                    if (t) throw e;
                                }
                            },
                            abort: function () {
                                t && t();
                            },
                        };
                }),
                T.ajaxPrefilter(function (e) {
                    e.crossDomain && (e.contents.script = !1);
                }),
                T.ajaxSetup({
                    accepts: {
                        script: 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript',
                    },
                    contents: { script: /\b(?:java|ecma)script\b/ },
                    converters: {
                        'text script': function (e) {
                            return T.globalEval(e), e;
                        },
                    },
                }),
                T.ajaxPrefilter('script', function (e) {
                    void 0 === e.cache && (e.cache = !1),
                        e.crossDomain && (e.type = 'GET');
                }),
                T.ajaxTransport('script', function (e) {
                    var t, n;
                    if (e.crossDomain || e.scriptAttrs)
                        return {
                            send: function (r, o) {
                                (t = T('<script>')
                                    .attr(e.scriptAttrs || {})
                                    .prop({
                                        charset: e.scriptCharset,
                                        src: e.url,
                                    })
                                    .on(
                                        'load error',
                                        (n = function (e) {
                                            t.remove(),
                                                (n = null),
                                                e &&
                                                    o(
                                                        'error' === e.type
                                                            ? 404
                                                            : 200,
                                                        e.type
                                                    );
                                        })
                                    )),
                                    x.head.appendChild(t[0]);
                            },
                            abort: function () {
                                n && n();
                            },
                        };
                });
            var Xt,
                Vt = [],
                Gt = /(=)\?(?=&|$)|\?\?/;
            T.ajaxSetup({
                jsonp: 'callback',
                jsonpCallback: function () {
                    var e = Vt.pop() || T.expando + '_' + kt.guid++;
                    return (this[e] = !0), e;
                },
            }),
                T.ajaxPrefilter('json jsonp', function (e, t, r) {
                    var o,
                        i,
                        a,
                        s =
                            !1 !== e.jsonp &&
                            (Gt.test(e.url)
                                ? 'url'
                                : 'string' == typeof e.data &&
                                  0 ===
                                      (e.contentType || '').indexOf(
                                          'application/x-www-form-urlencoded'
                                      ) &&
                                  Gt.test(e.data) &&
                                  'data');
                    if (s || 'jsonp' === e.dataTypes[0])
                        return (
                            (o = e.jsonpCallback =
                                y(e.jsonpCallback)
                                    ? e.jsonpCallback()
                                    : e.jsonpCallback),
                            s
                                ? (e[s] = e[s].replace(Gt, '$1' + o))
                                : !1 !== e.jsonp &&
                                  (e.url +=
                                      (St.test(e.url) ? '&' : '?') +
                                      e.jsonp +
                                      '=' +
                                      o),
                            (e.converters['script json'] = function () {
                                return (
                                    a || T.error(o + ' was not called'), a[0]
                                );
                            }),
                            (e.dataTypes[0] = 'json'),
                            (i = n[o]),
                            (n[o] = function () {
                                a = arguments;
                            }),
                            r.always(function () {
                                void 0 === i ? T(n).removeProp(o) : (n[o] = i),
                                    e[o] &&
                                        ((e.jsonpCallback = t.jsonpCallback),
                                        Vt.push(o)),
                                    a && y(i) && i(a[0]),
                                    (a = i = void 0);
                            }),
                            'script'
                        );
                }),
                (v.createHTMLDocument =
                    (((Xt =
                        x.implementation.createHTMLDocument(
                            ''
                        ).body).innerHTML = '<form></form><form></form>'),
                    2 === Xt.childNodes.length)),
                (T.parseHTML = function (e, t, n) {
                    return 'string' != typeof e
                        ? []
                        : ('boolean' == typeof t && ((n = t), (t = !1)),
                          t ||
                              (v.createHTMLDocument
                                  ? (((r = (t =
                                        x.implementation.createHTMLDocument(
                                            ''
                                        )).createElement('base')).href =
                                        x.location.href),
                                    t.head.appendChild(r))
                                  : (t = x)),
                          (i = !n && []),
                          (o = D.exec(e))
                              ? [t.createElement(o[1])]
                              : ((o = Ce([e], t, i)),
                                i && i.length && T(i).remove(),
                                T.merge([], o.childNodes)));
                    var r, o, i;
                }),
                (T.fn.load = function (e, t, n) {
                    var r,
                        o,
                        i,
                        a = this,
                        s = e.indexOf(' ');
                    return (
                        s > -1 && ((r = yt(e.slice(s))), (e = e.slice(0, s))),
                        y(t)
                            ? ((n = t), (t = void 0))
                            : t && 'object' == typeof t && (o = 'POST'),
                        a.length > 0 &&
                            T.ajax({
                                url: e,
                                type: o || 'GET',
                                dataType: 'html',
                                data: t,
                            })
                                .done(function (e) {
                                    (i = arguments),
                                        a.html(
                                            r
                                                ? T('<div>')
                                                      .append(T.parseHTML(e))
                                                      .find(r)
                                                : e
                                        );
                                })
                                .always(
                                    n &&
                                        function (e, t) {
                                            a.each(function () {
                                                n.apply(
                                                    this,
                                                    i || [e.responseText, t, e]
                                                );
                                            });
                                        }
                                ),
                        this
                    );
                }),
                (T.expr.pseudos.animated = function (e) {
                    return T.grep(T.timers, function (t) {
                        return e === t.elem;
                    }).length;
                }),
                (T.offset = {
                    setOffset: function (e, t, n) {
                        var r,
                            o,
                            i,
                            a,
                            s,
                            u,
                            c = T.css(e, 'position'),
                            l = T(e),
                            f = {};
                        'static' === c && (e.style.position = 'relative'),
                            (s = l.offset()),
                            (i = T.css(e, 'top')),
                            (u = T.css(e, 'left')),
                            ('absolute' === c || 'fixed' === c) &&
                            (i + u).indexOf('auto') > -1
                                ? ((a = (r = l.position()).top), (o = r.left))
                                : ((a = parseFloat(i) || 0),
                                  (o = parseFloat(u) || 0)),
                            y(t) && (t = t.call(e, n, T.extend({}, s))),
                            null != t.top && (f.top = t.top - s.top + a),
                            null != t.left && (f.left = t.left - s.left + o),
                            'using' in t ? t.using.call(e, f) : l.css(f);
                    },
                }),
                T.fn.extend({
                    offset: function (e) {
                        if (arguments.length)
                            return void 0 === e
                                ? this
                                : this.each(function (t) {
                                      T.offset.setOffset(this, e, t);
                                  });
                        var t,
                            n,
                            r = this[0];
                        return r
                            ? r.getClientRects().length
                                ? ((t = r.getBoundingClientRect()),
                                  (n = r.ownerDocument.defaultView),
                                  {
                                      top: t.top + n.pageYOffset,
                                      left: t.left + n.pageXOffset,
                                  })
                                : { top: 0, left: 0 }
                            : void 0;
                    },
                    position: function () {
                        if (this[0]) {
                            var e,
                                t,
                                n,
                                r = this[0],
                                o = { top: 0, left: 0 };
                            if ('fixed' === T.css(r, 'position'))
                                t = r.getBoundingClientRect();
                            else {
                                for (
                                    t = this.offset(),
                                        n = r.ownerDocument,
                                        e = r.offsetParent || n.documentElement;
                                    e &&
                                    (e === n.body || e === n.documentElement) &&
                                    'static' === T.css(e, 'position');

                                )
                                    e = e.parentNode;
                                e &&
                                    e !== r &&
                                    1 === e.nodeType &&
                                    (((o = T(e).offset()).top += T.css(
                                        e,
                                        'borderTopWidth',
                                        !0
                                    )),
                                    (o.left += T.css(
                                        e,
                                        'borderLeftWidth',
                                        !0
                                    )));
                            }
                            return {
                                top: t.top - o.top - T.css(r, 'marginTop', !0),
                                left:
                                    t.left -
                                    o.left -
                                    T.css(r, 'marginLeft', !0),
                            };
                        }
                    },
                    offsetParent: function () {
                        return this.map(function () {
                            for (
                                var e = this.offsetParent;
                                e && 'static' === T.css(e, 'position');

                            )
                                e = e.offsetParent;
                            return e || ie;
                        });
                    },
                }),
                T.each(
                    { scrollLeft: 'pageXOffset', scrollTop: 'pageYOffset' },
                    function (e, t) {
                        var n = 'pageYOffset' === t;
                        T.fn[e] = function (r) {
                            return z(
                                this,
                                function (e, r, o) {
                                    var i;
                                    if (
                                        (m(e)
                                            ? (i = e)
                                            : 9 === e.nodeType &&
                                              (i = e.defaultView),
                                        void 0 === o)
                                    )
                                        return i ? i[t] : e[r];
                                    i
                                        ? i.scrollTo(
                                              n ? i.pageXOffset : o,
                                              n ? o : i.pageYOffset
                                          )
                                        : (e[r] = o);
                                },
                                e,
                                r,
                                arguments.length
                            );
                        };
                    }
                ),
                T.each(['top', 'left'], function (e, t) {
                    T.cssHooks[t] = Xe(v.pixelPosition, function (e, n) {
                        if (n)
                            return (
                                (n = Ue(e, t)),
                                Ie.test(n) ? T(e).position()[t] + 'px' : n
                            );
                    });
                }),
                T.each({ Height: 'height', Width: 'width' }, function (e, t) {
                    T.each(
                        { padding: 'inner' + e, content: t, '': 'outer' + e },
                        function (n, r) {
                            T.fn[r] = function (o, i) {
                                var a =
                                        arguments.length &&
                                        (n || 'boolean' != typeof o),
                                    s =
                                        n ||
                                        (!0 === o || !0 === i
                                            ? 'margin'
                                            : 'border');
                                return z(
                                    this,
                                    function (t, n, o) {
                                        var i;
                                        return m(t)
                                            ? 0 === r.indexOf('outer')
                                                ? t['inner' + e]
                                                : t.document.documentElement[
                                                      'client' + e
                                                  ]
                                            : 9 === t.nodeType
                                            ? ((i = t.documentElement),
                                              Math.max(
                                                  t.body['scroll' + e],
                                                  i['scroll' + e],
                                                  t.body['offset' + e],
                                                  i['offset' + e],
                                                  i['client' + e]
                                              ))
                                            : void 0 === o
                                            ? T.css(t, n, s)
                                            : T.style(t, n, o, s);
                                    },
                                    t,
                                    a ? o : void 0,
                                    a
                                );
                            };
                        }
                    );
                }),
                T.each(
                    [
                        'ajaxStart',
                        'ajaxStop',
                        'ajaxComplete',
                        'ajaxError',
                        'ajaxSuccess',
                        'ajaxSend',
                    ],
                    function (e, t) {
                        T.fn[t] = function (e) {
                            return this.on(t, e);
                        };
                    }
                ),
                T.fn.extend({
                    bind: function (e, t, n) {
                        return this.on(e, null, t, n);
                    },
                    unbind: function (e, t) {
                        return this.off(e, null, t);
                    },
                    delegate: function (e, t, n, r) {
                        return this.on(t, e, n, r);
                    },
                    undelegate: function (e, t, n) {
                        return 1 === arguments.length
                            ? this.off(e, '**')
                            : this.off(t, e || '**', n);
                    },
                    hover: function (e, t) {
                        return this.mouseenter(e).mouseleave(t || e);
                    },
                }),
                T.each(
                    'blur focus focusin focusout resize scroll click dblclick mousedown mouseup mousemove mouseover mouseout mouseenter mouseleave change select submit keydown keypress keyup contextmenu'.split(
                        ' '
                    ),
                    function (e, t) {
                        T.fn[t] = function (e, n) {
                            return arguments.length > 0
                                ? this.on(t, null, e, n)
                                : this.trigger(t);
                        };
                    }
                );
            var Jt = /^[\s\uFEFF\xA0]+|([^\s\uFEFF\xA0])[\s\uFEFF\xA0]+$/g;
            (T.proxy = function (e, t) {
                var n, r, o;
                if (
                    ('string' == typeof t && ((n = e[t]), (t = e), (e = n)),
                    y(e))
                )
                    return (
                        (r = s.call(arguments, 2)),
                        ((o = function () {
                            return e.apply(
                                t || this,
                                r.concat(s.call(arguments))
                            );
                        }).guid = e.guid =
                            e.guid || T.guid++),
                        o
                    );
            }),
                (T.holdReady = function (e) {
                    e ? T.readyWait++ : T.ready(!0);
                }),
                (T.isArray = Array.isArray),
                (T.parseJSON = JSON.parse),
                (T.nodeName = j),
                (T.isFunction = y),
                (T.isWindow = m),
                (T.camelCase = G),
                (T.type = C),
                (T.now = Date.now),
                (T.isNumeric = function (e) {
                    var t = T.type(e);
                    return (
                        ('number' === t || 'string' === t) &&
                        !isNaN(e - parseFloat(e))
                    );
                }),
                (T.trim = function (e) {
                    return null == e ? '' : (e + '').replace(Jt, '$1');
                }),
                void 0 ===
                    (r = function () {
                        return T;
                    }.apply(t, [])) || (e.exports = r);
            var Yt = n.jQuery,
                Qt = n.$;
            return (
                (T.noConflict = function (e) {
                    return (
                        n.$ === T && (n.$ = Qt),
                        e && n.jQuery === T && (n.jQuery = Yt),
                        T
                    );
                }),
                void 0 === o && (n.jQuery = n.$ = T),
                T
            );
        });
    },
    function (e, t, n) {
        n(3), (e.exports = n(5));
    },
    function (e, t, n) {
        'use strict';
        n.r(t);
        n(4);
        var r = n(0),
            o = n.n(r);
        (window.$ = n(1)),
            $(document).ready(function () {
                $(document.body).on('gdpr:accept', function () {
                    o.a.set('cookies-accepted', 'true');
                }),
                    'true' != o.a.get('cookies-accepted') &&
                        $.gdprcookie.init({
                            title: 'Polityka prywatności i cookies',
                            subtitle: '',
                            message:
                                'Ta strona przetwarza Twoje dane osobowe. Aby dowiedzieć się więcej kliknij w link <a href="https://motomar.com.pl/polityka-prywatnosci" style="text-decoration: underline;">Polityka prywatności</a>',
                            acceptBtnLabel: 'Zgadzam się',
                            advancedBtnLabel: 'Zobacz szczegóły',
                            unadvancedBtnLabel: 'Wstecz',
                            allowUnadvanced: !0,
                            cookieTypes: [
                                {
                                    type: 'Przetwarzanie danych osobowych',
                                    value: 'privacy',
                                    checked: !0,
                                },
                            ],
                        });
            });
    },
    function (e, t, n) {
        var r, o, i;
        (o = [n(1)]),
            void 0 ===
                (i =
                    'function' ==
                    typeof (r = function (e) {
                        'use strict';
                        var t,
                            n,
                            r = !1,
                            o = !1,
                            i = !1,
                            a = function (e) {
                                var t = decodeURIComponent(
                                    document.cookie
                                ).split(';');
                                e += '=';
                                for (var n = 0; n < t.length; n++) {
                                    for (var r = t[n]; ' ' == r.charAt(0); )
                                        r = r.substring(1);
                                    if (0 === r.indexOf(e))
                                        return r.substring(e.length, r.length);
                                }
                            },
                            s = function () {
                                var e = a(t.cookieName);
                                try {
                                    e = JSON.parse(e);
                                } catch (e) {
                                    return;
                                }
                                if (Array.isArray(e) && e.length) {
                                    var n = t.cookieTypes.map(function (e) {
                                        return e.value;
                                    });
                                    return (e = e.filter(function (e) {
                                        return n.indexOf(e) >= 0;
                                    }));
                                }
                            };
                        (e.gdprcookie = {}),
                            (e.gdprcookie.init = function (r) {
                                var o = {
                                    cookieTypes: [
                                        {
                                            type: 'Essential',
                                            value: 'essential',
                                            description:
                                                'These are cookies that are essential for the website to work correctly.',
                                            checked: !0,
                                        },
                                        {
                                            type: 'Site Preferences',
                                            value: 'preferences',
                                            description:
                                                'These are cookies that are related to your site preferences, e.g. remembering your username, site colours, etc.',
                                            checked: !0,
                                        },
                                        {
                                            type: 'Analytics',
                                            value: 'analytics',
                                            description:
                                                'Cookies related to site visits, browser types, etc.',
                                            checked: !0,
                                        },
                                        {
                                            type: 'Marketing',
                                            value: 'marketing',
                                            description:
                                                'Cookies related to marketing, e.g. newsletters, social media, etc',
                                            checked: !0,
                                        },
                                    ],
                                    title: 'Cookies & privacy',
                                    subtitle: 'Select cookies to accept',
                                    message:
                                        'Cookies enable you to use shopping carts and to personalize your experience on our sites, tell us which parts of our websites people have visited, help us measure the effectiveness of ads and web searches, and give us insights into user behaviour so we can improve our communications and products.',
                                    submessage: '',
                                    delay: 2e3,
                                    expires: 30,
                                    cookieName: 'cookieControlPrefs',
                                    acceptReload: !1,
                                    acceptBeforeAdvanced: ['essential'],
                                    acceptAfterAdvanced: ['essential'],
                                    allowUnadvanced: !1,
                                    switchAdvanced: !1,
                                    acceptBtnLabel: 'Accept cookies',
                                    advancedBtnLabel: 'Customize cookies',
                                    unadvancedBtnLabel: 'Back',
                                    customShowMessage: void 0,
                                    customHideMessage: void 0,
                                    customShowChecks: void 0,
                                    customHideChecks: void 0,
                                };
                                ((t = e.extend(
                                    o,
                                    window.GdprCookieSettings,
                                    r
                                )).message = String(t.message)),
                                    (t.delay = Math.max(0, +t.delay) || 0),
                                    (t.expires =
                                        Math.round(
                                            Math.min(
                                                Math.max(0, +t.expires),
                                                730
                                            )
                                        ) || 0),
                                    Array.isArray(t.cookieTypes)
                                        ? ((t.cookieTypes =
                                              t.cookieTypes.filter(function (
                                                  t
                                              ) {
                                                  return e.isPlainObject(t);
                                              })),
                                          t.cookieTypes.length ||
                                              (t.cookieTypes = o.cookieTypes))
                                        : (t.cookieTypes = o.cookieTypes),
                                    (t.cookieName =
                                        String(t.cookieName || '').replace(
                                            /[^\u0021-\u007e]|[,;=\s]/g,
                                            ''
                                        ) || 'cookieControlPrefs');
                                var i,
                                    a,
                                    s =
                                        ((i = t.cookieTypes.map(function (e) {
                                            return e.value;
                                        })),
                                        (a = function (e) {
                                            return i.indexOf(e) >= 0;
                                        }),
                                        function (e) {
                                            return (
                                                (e =
                                                    'string' == typeof e
                                                        ? [e]
                                                        : e),
                                                Array.isArray(e)
                                                    ? e.map(String).filter(a)
                                                    : void 0
                                            );
                                        });
                                (t.acceptBeforeAdvanced = s(
                                    t.acceptBeforeAdvanced
                                )),
                                    (t.acceptAfterAdvanced = s(
                                        t.acceptAfterAdvanced
                                    )),
                                    e(function () {
                                        n();
                                    });
                            }),
                            (n = function (n) {
                                if (!r) {
                                    var u = e('body'),
                                        c = s(),
                                        l = {
                                            container: void 0,
                                            introContainer: void 0,
                                            types: void 0,
                                            typesContainer: void 0,
                                            buttons: {
                                                accept: void 0,
                                                advanced: void 0,
                                            },
                                            allChecks: [],
                                            nonessentialChecks: [],
                                        },
                                        f = function (n) {
                                            l.container &&
                                                (e.isFunction(
                                                    t.customHideMessage
                                                )
                                                    ? (t.customHideMessage.call(
                                                          l.container,
                                                          l.container
                                                      ),
                                                      (r = !1))
                                                    : l.container.fadeOut(
                                                          'fast',
                                                          function () {
                                                              e(this).remove(),
                                                                  (r = !1);
                                                          }
                                                      )),
                                                n &&
                                                    t.acceptReload &&
                                                    document.location.reload();
                                        };
                                    if (
                                        ((Array.isArray(c) && c.length) ||
                                            (c = void 0),
                                        n || !c)
                                    ) {
                                        (l.types = e('<ul/>').append(
                                            e.map(
                                                t.cookieTypes,
                                                function (t, n) {
                                                    if (t.type && t.value) {
                                                        var r,
                                                            o =
                                                                'essential' ===
                                                                t.value;
                                                        r = Array.isArray(c)
                                                            ? c.indexOf(
                                                                  t.value
                                                              ) >= 0
                                                            : !0 === t.checked;
                                                        var i = e('<input/>', {
                                                            type: 'checkbox',
                                                            id:
                                                                'gdpr-cookietype-' +
                                                                n,
                                                            name: 'gdpr[]',
                                                            value: t.value,
                                                            checked: o || r,
                                                            disabled: o,
                                                        });
                                                        l.allChecks.push(
                                                            i.get(0)
                                                        ),
                                                            o ||
                                                                l.nonessentialChecks.push(
                                                                    i.get(0)
                                                                );
                                                        var a = e('<label/>', {
                                                            for:
                                                                'gdpr-cookietype-' +
                                                                n,
                                                            text: t.type,
                                                            title: t.description,
                                                        });
                                                        return e('<li/>')
                                                            .append([
                                                                i.get(0),
                                                                a.get(0),
                                                            ])
                                                            .get(0);
                                                    }
                                                }
                                            )
                                        )),
                                            (l.allChecks = e(l.allChecks)),
                                            (l.nonessentialChecks = e(
                                                l.nonessentialChecks
                                            ));
                                        var p = (l.container = e(
                                                '<div class=gdprcookie>'
                                            )).append([
                                                (l.introContainer = e(
                                                    '<div class=gdprcookie-intro/>'
                                                ))
                                                    .append([
                                                        t.title
                                                            ? e('<h1/>', {
                                                                  text: t.title,
                                                              }).get(0)
                                                            : void 0,
                                                        t.message
                                                            ? e('<p/>', {
                                                                  html: t.message,
                                                              }).get(0)
                                                            : void 0,
                                                    ])
                                                    .get(0),
                                                (l.typesContainer = e(
                                                    '<div class=gdprcookie-types/>'
                                                ))
                                                    .hide()
                                                    .append([
                                                        t.subtitle
                                                            ? e('<h2/>', {
                                                                  text: t.subtitle,
                                                              }).get(0)
                                                            : void 0,
                                                        t.submessage
                                                            ? e('<p/>', {
                                                                  html: t.submessage,
                                                              }).get(0)
                                                            : void 0,
                                                        l.types.get(0),
                                                    ])
                                                    .get(0),
                                                e(
                                                    '<div class=gdprcookie-buttons/>'
                                                )
                                                    .append([
                                                        (l.buttons.accept = e(
                                                            '<button/>',
                                                            {
                                                                type: 'button',
                                                                text: t.acceptBtnLabel,
                                                                click: function () {
                                                                    var e =
                                                                        function () {
                                                                            return l.allChecks
                                                                                .filter(
                                                                                    function () {
                                                                                        return (
                                                                                            this
                                                                                                .checked ||
                                                                                            this
                                                                                                .disabled
                                                                                        );
                                                                                    }
                                                                                )
                                                                                .map(
                                                                                    function () {
                                                                                        return this
                                                                                            .value;
                                                                                    }
                                                                                )
                                                                                .get();
                                                                        };
                                                                    f(!0);
                                                                    var n,
                                                                        r,
                                                                        s,
                                                                        c,
                                                                        p,
                                                                        d = o
                                                                            ? e()
                                                                            : ((n =
                                                                                  'accept' +
                                                                                  (i
                                                                                      ? 'After'
                                                                                      : 'Before') +
                                                                                  'Advanced'),
                                                                              Array.isArray(
                                                                                  t[
                                                                                      n
                                                                                  ]
                                                                              )
                                                                                  ? t[
                                                                                        n
                                                                                    ]
                                                                                  : e());
                                                                    (r =
                                                                        t.cookieName),
                                                                        (s =
                                                                            JSON.stringify(
                                                                                d
                                                                            )),
                                                                        (c =
                                                                            t.expires),
                                                                        (p =
                                                                            new Date()).setTime(
                                                                            p.getTime() +
                                                                                24 *
                                                                                    c *
                                                                                    60 *
                                                                                    60 *
                                                                                    1e3
                                                                        ),
                                                                        (document.cookie =
                                                                            r +
                                                                            '=' +
                                                                            s +
                                                                            ';expires=' +
                                                                            p.toUTCString() +
                                                                            ';path=/'),
                                                                        a(r),
                                                                        u.trigger(
                                                                            'gdpr:accept'
                                                                        );
                                                                },
                                                            }
                                                        )).get(0),
                                                        (l.buttons.advanced = e(
                                                            '<button/>',
                                                            {
                                                                type: 'button',
                                                                text: t.advancedBtnLabel,
                                                                click: function () {
                                                                    o
                                                                        ? t.allowUnadvanced &&
                                                                          (e.isFunction(
                                                                              t.customHideChecks
                                                                          )
                                                                              ? t.customHideChecks.call(
                                                                                    l.typesContainer,
                                                                                    l.typesContainer,
                                                                                    l.introContainer
                                                                                )
                                                                              : (t.switchAdvanced &&
                                                                                    l.introContainer.slideDown(
                                                                                        'fast'
                                                                                    ),
                                                                                l.typesContainer.slideUp(
                                                                                    'fast'
                                                                                )),
                                                                          (o =
                                                                              !1),
                                                                          t.allowUnadvanced &&
                                                                              t.unadvancedBtnLabel &&
                                                                              t.advancedBtnLabel !==
                                                                                  t.unadvancedBtnLabel &&
                                                                              l.buttons.advanced.text(
                                                                                  t.advancedBtnLabel
                                                                              ),
                                                                          u.trigger(
                                                                              'gdpr:unadvanced'
                                                                          ))
                                                                        : (t.allowUnadvanced ||
                                                                              l.buttons.advanced.prop(
                                                                                  'disabled',
                                                                                  !0
                                                                              ),
                                                                          e.isFunction(
                                                                              t.customShowChecks
                                                                          )
                                                                              ? t.customShowChecks.call(
                                                                                    l.typesContainer,
                                                                                    l.typesContainer,
                                                                                    l.introContainer
                                                                                )
                                                                              : (t.switchAdvanced &&
                                                                                    l.introContainer.slideUp(
                                                                                        'fast'
                                                                                    ),
                                                                                l.typesContainer.slideDown(
                                                                                    'fast'
                                                                                )),
                                                                          (o =
                                                                              i =
                                                                                  !0),
                                                                          t.allowUnadvanced &&
                                                                              t.unadvancedBtnLabel &&
                                                                              t.advancedBtnLabel !==
                                                                                  t.unadvancedBtnLabel &&
                                                                              l.buttons.advanced.text(
                                                                                  t.unadvancedBtnLabel
                                                                              ),
                                                                          u.trigger(
                                                                              'gdpr:advanced'
                                                                          ));
                                                                },
                                                            }
                                                        )).get(0),
                                                    ])
                                                    .get(0),
                                            ]),
                                            d = function () {
                                                u.append(p),
                                                    (r = !0),
                                                    (o = i = !1),
                                                    e.isFunction(
                                                        t.customShowMessage
                                                    )
                                                        ? t.customShowMessage.call(
                                                              l.container,
                                                              l.container
                                                          )
                                                        : l.container
                                                              .hide()
                                                              .fadeIn('slow'),
                                                    u.trigger('gdpr:show');
                                            };
                                        !t.delay || n
                                            ? d()
                                            : window.setTimeout(d, t.delay);
                                    } else f(!1);
                                }
                            }),
                            (e.gdprcookie.display = function () {
                                n(!0);
                            }),
                            (e.gdprcookie.preference = function (e) {
                                var t = s();
                                return (
                                    'essential' === e ||
                                    (!!t &&
                                        (void 0 !== e ? t.indexOf(e) >= 0 : t))
                                );
                            }),
                            (e.gdprcookie = Object.freeze(e.gdprcookie));
                    })
                        ? r.apply(t, o)
                        : r) || (e.exports = i);
    },
    function (e, t) {},
]);
